import DataLayer from "./components/DataLayer";
import "./App.scss";


const App = () => {
    return (
        <DataLayer />
    );
};

export default App;
