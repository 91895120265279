export const ToggleMenuArrowWithCircleSvg = () => (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_d_33454_2473)">
            <circle cx="16" cy="16" r="16" fill="#fff" />
        </g>
        <path style={{ transform: "translateY(4px)" }} d="M14.2899 11.9999L17.8299 8.45995C18.0162 8.27259 18.1207 8.01913 18.1207 7.75495C18.1207 7.49076 18.0162 7.23731 17.8299 7.04995C17.737 6.95622 17.6264 6.88183 17.5045 6.83106C17.3827 6.78029 17.252 6.75415 17.1199 6.75415C16.9879 6.75415 16.8572 6.78029 16.7354 6.83106C16.6135 6.88183 16.5029 6.95622 16.4099 7.04995L12.1699 11.2899C12.0762 11.3829 12.0018 11.4935 11.9511 11.6154C11.9003 11.7372 11.8741 11.8679 11.8741 11.9999C11.8741 12.132 11.9003 12.2627 11.9511 12.3845C12.0018 12.5064 12.0762 12.617 12.1699 12.7099L16.4099 16.9999C16.5034 17.0926 16.6142 17.166 16.736 17.2157C16.8579 17.2655 16.9883 17.2907 17.1199 17.2899C17.2516 17.2907 17.382 17.2655 17.5038 17.2157C17.6257 17.166 17.7365 17.0926 17.8299 16.9999C18.0162 16.8126 18.1207 16.5591 18.1207 16.2949C18.1207 16.0308 18.0162 15.7773 17.8299 15.5899L14.2899 11.9999Z" fill="#2F2F2F" />
        <defs>
            <filter id="filter0_d_33454_2473" x="0" y="0" width="32" height="32" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_33454_2473" result="shape" />
            </filter>
        </defs>
    </svg>
);