type Props = {
    handleClick: () => void;
};

const dataTestIdPrefix = "CloseButtonComponent";

const CloseButton = ({ handleClick }: Props) => {
    return (
        <div className="g-close-btn" onClick={handleClick} data-testid={`${dataTestIdPrefix}-Close-Button`}>
            <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M3.24689 2.62815C3.07603 2.45728 2.79901 2.45728 2.62815 2.62815C2.45728 2.79901 2.45728 3.07603 2.62815 3.24689L4.38126 5L2.62815 6.75311C2.45728 6.92397 2.45728 7.20099 2.62815 7.37185C2.79901 7.54272 3.07603 7.54272 3.24689 7.37185L5 5.61874L6.75311 7.37185C6.92397 7.54272 7.20099 7.54272 7.37185 7.37185C7.54272 7.20099 7.54272 6.92397 7.37185 6.75311L5.61874 5L7.37185 3.24689C7.54272 3.07603 7.54272 2.79901 7.37185 2.62815C7.20099 2.45728 6.92397 2.45728 6.75311 2.62815L5 4.38126L3.24689 2.62815Z" fill="white" />
            </svg>
        </div>
    );
};

export default CloseButton;