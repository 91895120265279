type Props = {
  handleClick: () => void;
};

const dataTestIdPrefix = "ArrowButtonWithOverlayComponent";

const ArrowButtonWithOverlay = ({ handleClick }: Props) => {
  return (
    <div className="g-arrow-btn-with-overlay" onClick={handleClick} data-testid={`${dataTestIdPrefix}-Arrow-Button-With-Overlay`}>
      <svg width="56" height="57" viewBox="0 0 56 57" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_b_2110_7531)">
          <rect className="gander-button-overlay" x="56" y="56.5" width="56" height="56" rx="28" transform="rotate(-180 56 56.5)" fill="black" fillOpacity="0.16" />
        </g>
        <path fillRule="evenodd" clipRule="evenodd" d="M24.3595 35.1763C24.8387 35.6079 25.6158 35.6079 26.0951 35.1763L32.6405 29.2815C33.1198 28.8499 33.1198 28.1501 32.6405 27.7185L26.0951 21.8237C25.6158 21.3921 24.8387 21.3921 24.3595 21.8237C23.8802 22.2554 23.8802 22.9552 24.3595 23.3868L30.0371 28.5L24.3595 33.6132C23.8802 34.0448 23.8802 34.7446 24.3595 35.1763Z" fill="white" />
        <defs>
          <filter id="filter0_b_2110_7531" x="-20" y="-19.5" width="96" height="96" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feGaussianBlur in="BackgroundImageFix" stdDeviation="10" />
            <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_2110_7531" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_2110_7531" result="shape" />
          </filter>
        </defs>
      </svg>
    </div>
  );
};

export default ArrowButtonWithOverlay;