export default function logger(...arg:any) {
  const debug = localStorage.getItem('debug');
  if (debug !== null && arguments?.length !== 0) {
    if (debug === 'trace') {
        console.trace('🔥🔥 ', ...arg);
    } else {
        console.log('🔥🔥 ', ...arg);
    }
  }
}
