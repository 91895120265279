import { CSSProperties, useEffect } from 'react';
import { IVideo } from '../../../interfaces';
import PlayButton from '../../video-player/video-controls/buttons/PlayButton';
import { onVideoThumbnailLoadError } from 'utils/common-functions';
import { DEFAULT_BORDER_PADDING_FOR_PREVIEW_EMBED_AND_CLOSE_MODE } from 'constants/constants';
import { IS_CLOSE_MODE_ACTIVE } from 'config';
import { setItemToSessionStorage } from 'utils/get-set-local-session';

import './index.scss';

interface IProps {
    closeBorderColor: string;
    closeCornerRadius: string;
    closeIsBorderPadding: boolean;
    closeIsShowBorder: boolean;
    selectedVideoObj: IVideo;
    playBtnClick: () => void;
}

const dataTestIdPrefix = "ClosedModeComponent";

const ClosedMode = ({ closeBorderColor, closeCornerRadius, closeIsBorderPadding, closeIsShowBorder, selectedVideoObj, playBtnClick }: IProps) => {

    useEffect(() => {
        setItemToSessionStorage(IS_CLOSE_MODE_ACTIVE, true);
    }, []);

    const handlePlayBtnClick = () => {
        sessionStorage.removeItem(IS_CLOSE_MODE_ACTIVE);
        playBtnClick();
    }

    const containerStyles: CSSProperties = {
        border: (closeIsShowBorder && closeBorderColor) ? `3px solid ${closeBorderColor}` : 'unset',
        borderRadius: closeCornerRadius || 0,
        padding: closeIsBorderPadding ? DEFAULT_BORDER_PADDING_FOR_PREVIEW_EMBED_AND_CLOSE_MODE : 0,
    };

    const contentStyles = {};

    const imgStyles = { borderRadius: closeCornerRadius || 0 };

    return (
        <div className="g-close-mode-container" style={containerStyles} data-testid={`${dataTestIdPrefix}-ContainerDiv`} >
            <div className="g-close-mode-content" style={contentStyles} data-testid={`${dataTestIdPrefix}-ContentDiv`}>
                <img
                    src={selectedVideoObj?.hdThumbnail}
                    alt={selectedVideoObj?.displayName}
                    style={imgStyles}
                    onError={(e) => onVideoThumbnailLoadError(e, selectedVideoObj)} data-testid={`${dataTestIdPrefix}-Image`}
                />
                <PlayButton handleClick={handlePlayBtnClick} dataTestId={`${dataTestIdPrefix}-PlayButton`} />
            </div>
        </div>
    )
}

export default ClosedMode;