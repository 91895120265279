import { forwardRef } from 'react';
import { IVideoProgress } from '../../../../interfaces';
import logger from '../../../../utils/logger';

import './index.scss';

type Props = {
    videoProgress: IVideoProgress;
    setVideoProgress: (value: IVideoProgress) => void;
    styles?: { [key: string]: string };
    dataTestId?: string;
};

const TimeBar = ({ setVideoProgress, videoProgress, styles, dataTestId }: Props, playerRef: any) => {
    const handleProgressClick = (evt: any) => {
        evt.stopPropagation();

        const totalDuration = videoProgress.loadedSeconds / videoProgress.loaded;
        if (totalDuration) {
            const { x, width } = evt.currentTarget.getBoundingClientRect();
            const clickPointInProgress = evt.clientX - x;
            const percentInTimeLine = clickPointInProgress / width;
            const toSeek = Math.round(totalDuration * percentInTimeLine);
            logger('toSeek =>', toSeek);
            if (playerRef?.current) {
                (playerRef?.current as any).seekTo(toSeek);
            }
            setVideoProgress({ ...videoProgress, playedSeconds: toSeek, played: percentInTimeLine });
        }
    }

    return (
        <div className={`gander-react-video-player-time-bar`} style={styles}>
            <div className="gander-time-bar-controls">
                <div className="gander-time-bar-progressbar-container" onClick={handleProgressClick} data-testid={`${dataTestId}-Container`}>
                    <div
                        className="gander-time-bar-progressbar"
                        style={{ transform: `translateX(calc(-100% + ${videoProgress.played * 100}%)` }}
                        data-testid={dataTestId}
                    />
                </div>
            </div>
        </div>
    );
};

export default forwardRef(TimeBar);