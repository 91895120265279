import { useEffect, useContext, lazy } from "react";
import { generateGanderSessionID, getProductIdFromElement } from "../utils/common-functions";
import { AppContext } from "../context/app-context";
import OverlayModeWidgetComponent from "./overlay-mode-widget-component";
import { EWidgetType } from "interfaces/widget-setting-v2";
import "./index.scss";

const { REACT_APP_GWR_ENV } = process.env;
let WidgetCustomizationPreviewScreen = lazy(() => import("./widget-customization-preview-screen"));
let ProductPageWidget = lazy(() => import("./product-page-widget"));
let GlobalPagesWidget = lazy(() => import("./global-pages-widget"));

// FOR LOCAL DEVELOPMENT
if (REACT_APP_GWR_ENV === 'LOCAL') {
    WidgetCustomizationPreviewScreen = require("./widget-customization-preview-screen").default;
    ProductPageWidget = require("./product-page-widget").default;
    GlobalPagesWidget = require("./global-pages-widget").default;
}


const DataLayer = () => {
    const { isPreviewMode, previewModeSettings, abTestData, widgetSettings, isEmbedProductPage } = useContext(AppContext);
    const isInitWidget = abTestData.widgetShown;

    useEffect(() => {
        getProductIdFromElement();
        generateGanderSessionID();
    }, []);

    return (
        <>
            {isPreviewMode && previewModeSettings ? (
                <WidgetCustomizationPreviewScreen />
            ) : (
                <>
                    {isInitWidget && (
                        <>
                            {
                                isEmbedProductPage && widgetSettings?.widgetType === EWidgetType.EMBEDDED ?
                                    (<><ProductPageWidget /><OverlayModeWidgetComponent /></>) : (<GlobalPagesWidget />)
                            }
                        </>
                    )}
                </>
            )}
        </>
    );
};

export default DataLayer;
