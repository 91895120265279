import { UAParser } from "ua-parser-js";
import { getItemFromLocalStorage } from "../get-set-local-storage";
import { GANDER_USER_DETAILS } from "../../config";
import { getUserInformationSchema, getGeoInfoSchema } from "./schema"
import logger from "./../logger";
let globalQueryFlag = false;
let userInformation = getUserInformationSchema();

export const getUserAgent = () => {
  return navigator.userAgent;
};

export const parseUserAgent = () => {
  const userAgent = getUserAgent();
  const parser = new UAParser(userAgent);
  const result = parser.getResult();
  return result;
};

export const getPlatform = (parsedUA: any) => {
  let platform = navigator?.platform;
  if (parsedUA && parsedUA.device?.model) {
    platform = parsedUA.device.model;
  }
  return platform;
};

export const getBrand = (parsedUA: any) => {
  return parsedUA.device.vendor;
};

export const getScreen = () => {
  return {
    height: window.screen.height,
    width: window.screen.width,
  };
};

export const isTouchScreen = () => {
  if ("ontouchstart" in window || "ontouchstart" in document.documentElement || navigator.maxTouchPoints !== 0) {
    return true;
  }
  return false;
};

export const getDeviceType = (parsedUA: any, userInformation: Record<string, any>) => {
  const isTouchScreen = userInformation.device.isTouchScreen;
  const platform = userInformation.device.platform;
  let deviceType;
  if (!isTouchScreen) {
    deviceType = "desktop";
  } else if (parsedUA && parsedUA.device?.type) {
    deviceType = (parsedUA.device.type === "phone") ? "mobile" : parsedUA.device.type;
  } else if (isTouchScreen && platform === "MacIntel") {
    deviceType = "tablet";
  } else {
    deviceType = "desktop";
  }
  return deviceType;
};

export const getBrowser = (parsedUA: any) => {
  const browserInfo = {
    name: parsedUA.browser.name,
    version: parsedUA.browser.version,
  };
  return browserInfo;
};

export const getOS = (parsedUA: any) => {
  return parsedUA.os;
};

export const getLanguages = () => {
  return navigator.language;
};

export const getIPAddressViaThirdPartyService = async () => {
  try {
    let data = await fetch(`https://ifconfig.me/all.json`, {
      method: "GET",
    }).then((res) => res.json());
    return data.ip_addr;
  } catch (err) {
    logger("🚀 ~ file: get-device-info.ts ~ getIPAddressViaThirdPartyService ~ err", err);
    return "";
  }
};


// TODO : shift this call to backend as we do not want to expose credentials to anyone 
export const getLocation = async (userIP: string): Promise<Record<string, string | number | boolean>> => {
  const url = `https://pro.ip-api.com/json/${userIP}?fields=414310911&key=2GZlOugr5bx9BDH`;

  try {
    if (!userIP) {
      const error = new TypeError("IP Address Should Not Be Empty");
      throw error;
    }

    const fetchIPLocation = await fetch(url, {
      headers: {
        "accept": "text/html,application/xhtml+xml,application/xml;q=0.9,image/avif,image/webp,image/apng,*/*;q=0.8",
        "accept-language": "en-GB,en;q=0.7",
        "cache-control": "max-age=0",
        "sec-fetch-dest": "document",
        "sec-fetch-mode": "navigate",
        "sec-fetch-site": "none",
        "sec-fetch-user": "?1",
        "sec-gpc": "1",
        "upgrade-insecure-requests": "1",
        "host": "pro.ip-api.com",
      },
      referrerPolicy: "strict-origin-when-cross-origin",
      body: null,
      method: "GET",
    }).then((res) => res.json());
    return fetchIPLocation;
  } catch (e) {
    logger("🚀 ~ file: get-device-info.ts ~ getLocation ~ e", e);
    return {};
  }
};

const getLocationHelper = async (userInformation: Record<string, any>): Promise<Record<string, any>> => {
  const userIP = userInformation.ipAddress;
  try {
    const userLoc = await getLocation(userIP);
    if (userLoc) {
      const geoInfo = {
        countryCode: userLoc["countryCode"],
        country: userLoc["country"],
        regionCode: userLoc["region"],
        region: userLoc["regionName"],
        city: userLoc["city"],
        lat: Number(userLoc["lat"]),
        long: Number(userLoc["lon"]),
      };
      return geoInfo
    } 
  } catch (err) {
    return getGeoInfoSchema()
  }
  return getGeoInfoSchema()
};



export const getUserDetails = async () => {
  try {
    if (!globalQueryFlag) {
      globalQueryFlag = true;
      const parsedUA = parseUserAgent();
      userInformation.device.screen = getScreen();
      userInformation.device.browser = getBrowser(parsedUA);
      userInformation.device.os = getOS(parsedUA);
      userInformation.device.isTouchScreen = isTouchScreen();
      userInformation.device.platform = getPlatform(parsedUA);
      userInformation.device.type = getDeviceType(parsedUA, userInformation);
      userInformation.language = getLanguages();
      userInformation.useragent = getUserAgent();
      const brand = getBrand(parsedUA);
      if (!brand && (userInformation.device.platform === "MacIntel" ||
          userInformation.device.os.name === "Mac OS")) {
        userInformation.device.brand = "Apple";
      } else {
        userInformation.device.brand = brand;
      }
      userInformation.ipAddress = await getIPAddressViaThirdPartyService();
      userInformation.geo = await getLocationHelper(userInformation);
    } else {
      userInformation = getItemFromLocalStorage(GANDER_USER_DETAILS) || userInformation;
    }
  } catch (err) {
    logger("🚀 ~ file: get-device-info.ts ~ getUserDetails ~ err", err);
    return userInformation;
  }
  return userInformation;
};
