import logger from "./logger";
import {
  LAST_SESSION_USED_TIMESTAMP,
  LOCAL_SESSION_ID_KEY,
  LOCATION_PATH_NAME,
  GANDER_CLIENT_ID,
  GANDER_SESSION_ID,
  GANDER_USER_DETAILS,
  SESSION_LAST_SEEN_PRODUCT,
  SESSION_WIDGET_SHOWN_KEY,
} from "../config";
import { IWidgetSettings, IVideo, IShopifyGIDdecode } from "interfaces";
import { getItemFromLocalStorage, setItemToLocalStorage } from "./get-set-local-storage";
import { getUserDetails } from "../utils/client-metrics/get-device-info";
import { mutexLock } from "./mutex-lock";
import ganderFEWidgetPreviewData from "./gander-FE-widget-preview-data";
import { EEmbeddedLayout, EOverlayOpenState, EWidgetType } from "interfaces/widget-setting-v2";

const resolveElasticEventData = () => {
  const path = LOCATION_PATH_NAME();
  let category = "video";
  let eventPath = undefined;
  let collection_name = undefined;

  if (path === "/") {
    category = "page";
    eventPath = "home";
  } else if (path.includes("/products/")) {
    category = "product";
  } else if (path.includes("collections/all")) {
    category = "page";
    eventPath = "collections/all";
  } else if (path.includes("collections") && !path.includes("collections/all")) {
    if (!path.endsWith("collections") && !path.endsWith("collections/")) {
      category = "collection";
      const collectionName = path.split("collections/").pop();
      collection_name = collectionName;
    } else {
      category = "page";
      eventPath = "collections";
    }
  } else if (path.includes("cart")) {
    category = "page";
    eventPath = "cart";
  } else if (path.includes("pages/")) {
    const customPageName = path?.split("/")?.slice(-2)?.join("/");
    if (customPageName) {
      category = "page";
      eventPath = "/" + customPageName;
    }
  } else if (path.includes("blogs/")) {
    category = "blog";
    eventPath = path;
  }

  return { path, category, eventPath, collection_name };
};

const resolveElasticVisitImpressionData = () => {
  const path = LOCATION_PATH_NAME();
  let browserPath = path;
  let browserCategory = "";
  let entityName = undefined;
  let category = "video";
  let eventPath = undefined;

  if (path === "/") {
    browserCategory = "page";
    browserPath = "home";
  } else if (path.includes("/products/") || path.includes("/product/")) {
    browserCategory = "product";
    browserPath = path;
    entityName = window?.location?.pathname?.split("/")?.pop();
  } else if (path.includes("collections/all")) {
    browserCategory = "page";
    browserPath = "collections/all";
  } else if (path.includes("collections") && !path.includes("collections/all")) {
    if (!path.endsWith("collections") && !path.endsWith("collections/")) {
      browserCategory = "collection";
      entityName = path.split("collections/").pop()!;
      browserPath = "collections/" + entityName;
    } else {
      browserCategory = "page";
      browserPath = "collections";
    }
  } else if (path.includes("cart")) {
    browserCategory = "page";
    browserPath = "cart";
  } else if (path.includes("pages/")) {
    const customPageName = path?.split("/")?.slice(-2)?.join("/");
    if (customPageName) {
      browserCategory = "page";
      browserPath = "/" + customPageName;
    }
  } else if (path.includes("blogs/")) {
    category = "blog";
    eventPath = path;
  } else {
    entityName = window?.location?.pathname?.split("/")?.pop();
  }
  // giving priority if its declared globally
  if (window?.shopifyProductName) {
    entityName = window?.shopifyProductName;
  }

  return { browserPath, browserCategory, entityName, category, eventPath };
};

export const generateSHACryptoHashKey = async (message: string, algo = "SHA-256") => {
  try {
    const msgUint8 = new TextEncoder().encode(message); // encode as (utf-8) Uint8Array
    const hashBuffer = await crypto.subtle.digest(algo, msgUint8); // hash the message
    const hashArray = Array.from(new Uint8Array(hashBuffer)); // convert buffer to byte array
    const hashHex = hashArray.map((b) => b.toString(16).padStart(2, "0")).join(""); // convert bytes to hex string
    return hashHex;
  } catch (err) {
    logger("🚀 ~ file: common-functions.ts ~ generateSHACryptoHashKey ~ err", err);
    return "";
  }
};

//EXPORT NOT NEEDED FOR THIS FUNCTION
const generateUniqueId = async (length = 20) => {
  const chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let id = "";
  for (let i = 0; i < length; i++) {
    id += chars.charAt(Math.floor(Math.random() * chars.length));
  }
  return id;
};

const getSessionId = async () => {
  const sessionId = await getItemFromLocalStorage(LOCAL_SESSION_ID_KEY);
  if (sessionId) {
    return sessionId;
  }

  const id = await generateUniqueId();
  setItemToLocalStorage(LOCAL_SESSION_ID_KEY, id);
  return id;
};

export const generateGanderSessionID = async () => {
  let sessionID = await getItemFromLocalStorage(GANDER_SESSION_ID);
  let lastUsedTimeStamp = await getItemFromLocalStorage(LAST_SESSION_USED_TIMESTAMP);
  const thirtyMin = 1000 * 60 * 30;
  const currentTimeStamp = Date.now();
  try {
    if (!lastUsedTimeStamp || !sessionID ||
      (lastUsedTimeStamp && typeof lastUsedTimeStamp != "object" &&
        Number(currentTimeStamp) - Number(lastUsedTimeStamp) >= thirtyMin)) {
      sessionID = await generateSHACryptoHashKey(String(currentTimeStamp));
      setItemToLocalStorage(GANDER_SESSION_ID, sessionID);
    }
    lastUsedTimeStamp = String(currentTimeStamp);
    setItemToLocalStorage(LAST_SESSION_USED_TIMESTAMP, currentTimeStamp);
  } catch (err) {
    logger("🚀 ~ file: common-functions.ts ~ generateGanderSessionID ~ err", err);
  }
  return {
    ganderSessionID: sessionID,
    sessionUsedTimeStamp: lastUsedTimeStamp
  };
};


export const getUserInfoWithClientID = async (klaraSessionID: string) => {
  let clientID = await getItemFromLocalStorage(GANDER_CLIENT_ID);
  let userDetails = await getItemFromLocalStorage(GANDER_USER_DETAILS);
  let wasClientIDPresent = true;
  try {
    if (!clientID) {
      userDetails = await generateClientIDAndGetUserDetails();
      if (userDetails && userDetails.clientID) {
        clientID = userDetails.clientID;
        userDetails.klaraID = klaraSessionID;
        wasClientIDPresent = false;
        setItemToLocalStorage(GANDER_CLIENT_ID, clientID);
        setItemToLocalStorage(GANDER_USER_DETAILS, userDetails);
      }
    }
  } catch (err) {
    logger("🚀 ~ file: common-functions.ts ~ getUserInfoWithClientID ~ err", err);
  }
  return {
    userDetails: userDetails || {},
    clientID: clientID || "",
    wasClientIDPresent
  };
};

export const generateClientIDAndGetUserDetails = async () => {
  try {
    const userDetails = await mutexLock(getUserDetails());
    if (userDetails) {
      const clientIDParams =
        String(userDetails.device.screen.height) +
        String(userDetails.device.screen.width) +
        String(userDetails.device.platform) +
        String(userDetails.device.type) +
        String(userDetails.geo.lat) +
        String(userDetails.geo.long) +
        String(userDetails.ipAddress);

      const clientID = await generateSHACryptoHashKey(clientIDParams);
      userDetails.clientID = clientID;
      return userDetails;
    } else {
      throw new Error(`There is issue in gathering User Information check getUserDetails fn : ${userDetails}`);
    }
  } catch (err) {
    logger("🚀 ~ file: common-functions.ts ~ generateClientIDAndGetUserDetails ~ err", err);
    return {};
  }
};

const getDevice = () => {
  let deviceType = "desktop";
  try {
    document.createEvent("TouchEvent");
    deviceType = "mobile";
  } finally {
    return deviceType;
  }
};

const preloadImage = (imageUrl: string) => {
  const img = new Image();
  img.src = imageUrl;
  return img;
};

const updateWidgetShownAndLastSeenProduct = (metaId: string) => {
  const productId = getProductIdFromHtmlElement();
  sessionStorage.setItem(SESSION_LAST_SEEN_PRODUCT, productId ? productId : "");
  const stored = sessionStorage.getItem(SESSION_WIDGET_SHOWN_KEY)!;
  const json = JSON.parse(stored);
  let shownData = { ...json };
  shownData = { ...shownData, [`${productId}`]: true, videoShown: metaId };
  sessionStorage.setItem(SESSION_WIDGET_SHOWN_KEY, JSON.stringify(shownData));
};

const getInstanceElement = (searchClassName = "gander-widget"): Element | null => {
  return document?.querySelector("." + searchClassName);
};

const getProductIdFromElement = () => {
  const element = getInstanceElement();
  if (element) {
    const productId = element.getAttribute("data-product-id");
    // if product id is not found OR product id is found but it's empty or contain `product.id` as string then sending entity name which is last protion of url
    if (!(!productId || (productId && (productId.includes("product.id") || productId === "")))) {
      logger("product id found from element", productId);
      return productId;
    }
  }
  return;
};

const getProductIdFromHtmlElement = (): string | undefined => {
  let productId = window?.shopifyProductId || getProductIdFromElement();
  if (!productId) {
    logger("Fallback `productId` to page last name from url as no actual product id was found");
    const { entityName } = resolveElasticVisitImpressionData();
    productId = entityName ? entityName : undefined;
  }
  return productId;
};

const formatTime = (timeInSeconds: number): string => {
  const time = Math.round(timeInSeconds);
  const seconds = time >= 60 ? time % 60 : time;
  const minutes = time >= 60 ? Math.floor(time / 60) : 0;
  const hours = minutes >= 60 ? Math.floor(minutes / 60) : 0;
  const formattedSeconds = seconds >= 10 ? `${seconds}` : `0${seconds}`;
  const formattedMinutes = minutes >= 10 ? `${minutes}` : `0${minutes}`;
  const formattedHours = hours >= 10 ? `${hours}` : `0${hours}`;
  return `${formattedHours !== "00" ? `${formattedHours}:` : ""}${formattedMinutes}:${formattedSeconds}`;
};

const getQueryStringParams = () => {
  const query = window.location.search;
  return query
    ? (/^[?#]/.test(query) ? query.slice(1) : query).split("&").reduce((params: any, param) => {
      let [key, value] = param.split("=");
      params[key] = value ? decodeURIComponent(value.replace(/\+/g, " ")) : "";
      return params;
    }, {})
    : {};
};

const getValidUrl = (url = "") => {
  let newUrl = window.decodeURIComponent(url);
  newUrl = newUrl.trim().replace(/\s/g, "");

  if (/^(:\/\/)/.test(newUrl)) {
    return `http${newUrl}`;
  }
  if (!/^(f|ht)tps?:\/\//i.test(newUrl)) {
    return `http://${newUrl}`;
  }

  return newUrl;
};

const applyPreviewSettingsToInteraction = (video: any, previewModeSettings: IWidgetSettings) => {
  video.interaction.questions[0].choices[0].buttonColor = previewModeSettings.buttonColor;
  video.interaction.questions[0].choices[0].buttonTextColor = previewModeSettings.textColor;
};

const onVideoThumbnailLoadError = (e: any, videoObj: Partial<IVideo>) => {
  const newSrc = videoObj?.thumbnail;
  if (e.target.currentSrc !== newSrc) {
    e.target.src = newSrc; //replacement image with low quality thumbnail
    return;
  }
};

const getVideoUrlBasedOnScreenSize = (url: string) => {
  if (window.matchMedia("screen and (max-width: 1920px)").matches && url.includes(".m3u8")) {
    const newUrl = url.replace(".m3u8", "_mid.m3u8");
    logger("🚀 ~ file: common-functions.ts ~ getVideoUrlBasedOnScreenSize ~ newUrl", newUrl);
    return newUrl;
  }
  return url;
};

const getPreviewVideoUrlBasedOnScreenSize = (widgetSettings: IWidgetSettings, videoObj: IVideo): string => {
  let possiblePixelHeight = undefined;
  if (widgetSettings?.previewPlayerSize.includes('px')) {
    possiblePixelHeight = parseInt(widgetSettings?.previewPlayerSize);
  } else {
    const previewViewHeightNumber = parseInt(widgetSettings?.previewPlayerSize);
    possiblePixelHeight = window.innerHeight * previewViewHeightNumber / 100;
  }
  const isLoadHighDefinationPreview = (possiblePixelHeight > 320 || (widgetSettings?.openStateType === EOverlayOpenState.IN_FRAME && widgetSettings?.widgetType === EWidgetType.EMBEDDED && widgetSettings?.embedType === EEmbeddedLayout.GRID));
  return isLoadHighDefinationPreview ? videoObj.hdPreviewUrl : videoObj.previewUrl;
};

const getGanderFEWidgetPreviewData = () => {
  return ganderFEWidgetPreviewData;
}


const shopifyGIDdecode = (str: string): IShopifyGIDdecode => {
  const raw = str?.split("shopify/")[1];
  // eslint-disable-next-line no-unsafe-optional-chaining
  const [type, id] = raw?.split("/");

  const params = (id.split("?").slice(1)[0] || "").split("&").reduce((p: any, q: string) => {
    const [key, value] = q.split("=");
    p[key] = value;
    return p;
  }, {});
  const returnData = {
    type,
    id: id?.split("?")[0],
    params,
    raw: str,
  } as IShopifyGIDdecode;
  return returnData;
};

const getURLObj = (url: string) => {
  try {
    return {
      url: new URL(url),
      isValid: true,
    }
  } catch (e) {
    return {
      url: null,
      isValid: false,
    }
  }
};

const getURLQueryParamsAsObject = (search: string) => {
  try {
    const obj = JSON.parse('{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}')
    return obj;
  } catch (error) {
    logger("🚀 ~ file: common-functions.ts ~ getURLQueryParamsAsObject ~ error", error);
    return;
  }
}

export {
  getDevice,
  getSessionId,
  resolveElasticEventData,
  resolveElasticVisitImpressionData,
  preloadImage,
  updateWidgetShownAndLastSeenProduct,
  getInstanceElement,
  getProductIdFromElement,
  getProductIdFromHtmlElement,
  formatTime,
  getQueryStringParams,
  getValidUrl,
  applyPreviewSettingsToInteraction,
  onVideoThumbnailLoadError,
  getVideoUrlBasedOnScreenSize,
  getGanderFEWidgetPreviewData,
  getPreviewVideoUrlBasedOnScreenSize,
  shopifyGIDdecode,
  getURLObj,
  getURLQueryParamsAsObject
};
