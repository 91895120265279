import { useContext } from "react";
import MaximizeButtonWithOverlay from "components/video-player/video-controls/buttons/MaximizeButtonWithOverlay";
import MuteButtonWithOverlay from "components/video-player/video-controls/buttons/MuteButtonWithOverlay";
import UnmuteButtonWithOverlay from "components/video-player/video-controls/buttons/UnmuteButtonWithOverlay";
import CloseButtonWithOverlay from "components/video-player/video-controls/buttons/CloseButtonWithOverlay";
import { AppContext } from "context/app-context";

import "./index.scss";

interface Props {
  isVideoLoaded: boolean;
  isPortrait: boolean;
  toggleFullScreen: () => void;
  closeBtnClick: () => void;
}

const TopRightControls = ({ isVideoLoaded, isPortrait, toggleFullScreen, closeBtnClick }: Props) => {
  const { isMuted, setIsMuted } = useContext(AppContext);
  return (
    <div className={`gander-open-mode-top-right-controls ${isPortrait ? "" : "gander-is-landscape"}`}>
      {isVideoLoaded && (
        <>
          <CloseButtonWithOverlay handleClick={closeBtnClick} />
          <MaximizeButtonWithOverlay handleClick={toggleFullScreen} />
          {isMuted ?
            <UnmuteButtonWithOverlay handleClick={() => setIsMuted(false)} />
            :
            <MuteButtonWithOverlay handleClick={() => setIsMuted(true)} />
          }
        </>
      )}
    </div>
  )
}

export default TopRightControls;