import { CSSProperties, useContext, useEffect, useRef, useState } from 'react';
import { IVideo } from '../../interfaces';
import { sendAnalyticsEvent } from '../../services/common.service';
import { getProductIdFromHtmlElement, resolveElasticVisitImpressionData, updateWidgetShownAndLastSeenProduct } from '../../utils/common-functions';
import logger from '../../utils/logger';
import { AppContext } from '../../context/app-context';
import EmbeddedModePreviewItem from './embedded-mode-preview-item';
import ScrollUpIcon from './scroll-up-icon';
import { EEmbeddedLayout, EOverlayOpenState } from 'interfaces/widget-setting-v2';
import { SHOP_NAME } from 'config';

import './index.scss';

const AUTOPLAY_SHOPS = [
    "nudeallure.myshopify.com",
  ]

const dataTestIdPrefix = "ProductPageWidget";

const ProductPageWidget = () => {
    const { ganderVideos, widgetSettings, abTestData } = useContext(AppContext);
    const previewItemRef: any = useRef(null);
    const [hoverEventSent, setHoverEventSent] = useState(false);
    const [isShowPreviewPrevScrollBtn, setIsShowPreviewPrevScrollBtn] = useState(false);
    const [isShowPreviewNextScrollBtn, setIsShowPreviewNextScrollBtn] = useState(false);
    const [selectedInFrameVideoObj, setSelectedInFrameVideoObj] = useState<IVideo | undefined>(undefined);

    useEffect(() => {
        if (ganderVideos && ganderVideos?.length > 0) {
            updateWidgetShownAndLastSeenProduct(ganderVideos[0]?.id);
        }

        // this logic is to decide either we have to show the next and prev arrows or not
        if (previewItemRef.current) {
            const offsetWidth = previewItemRef?.current?.offsetWidth;
            const scrollWidth = previewItemRef?.current?.scrollWidth;
            if (offsetWidth < scrollWidth) {
                setIsShowPreviewNextScrollBtn(true);
            }
        }
    }, [ganderVideos]);

    const getMinWidthFromPreviewPlayerSize = () => {
        const previewViewHeightNumber = parseInt(widgetSettings?.previewPlayerSize);
        const possiblePixelHeight = (window.innerHeight * previewViewHeightNumber / 100);

        if (widgetSettings?.openStateType === EOverlayOpenState.IN_FRAME && possiblePixelHeight < 340) {
            // min height for in frame is 340 so calculated width will be 192px
            return 192;
        }
        if (widgetSettings?.openStateType !== EOverlayOpenState.IN_FRAME && possiblePixelHeight < 70) {
            // min height for non in frame is 70 so calculated width will be 40px
            return 40;
        }
        // If above case doesn't match then calculate widht as per the aspect ratio and use it
        const minWidthFromPreviewHeightForGrid = possiblePixelHeight * 9 / 16;
        return minWidthFromPreviewHeightForGrid;
    }

    const scrollDiv = (operation: string) => {
        if (previewItemRef?.current) {
            const currentScrollLeft = previewItemRef?.current?.scrollLeft;
            logger('previewItemRef.current =>', previewItemRef.current);
            if (operation === 'prev') {
                document.querySelector('.gander-preview-item')?.scroll(0, 0);
            } else {
                document.querySelector('.gander-preview-item')?.scroll(currentScrollLeft + 350, 0);
            }
        }
    }

    const previewContainerScrolled = () => {
        const currentScrollLeft = previewItemRef?.current?.scrollLeft;
        if (currentScrollLeft < 10) {
            setIsShowPreviewPrevScrollBtn(false);
        } else {
            setIsShowPreviewPrevScrollBtn(true);
        }
    }

    const onPreviewContainerMouseOut = () => {
        try {
            if (!hoverEventSent) {
                const { entityName, browserPath: path } = resolveElasticVisitImpressionData();
                const analyticsPayload = {
                    event: {
                        category: "video",
                        type: "hover",
                        engagement: true,
                    },
                    data: {
                        product_id: getProductIdFromHtmlElement(),
                        product_name: entityName,
                        widget_shown: abTestData?.widgetShown,
                        path,
                    },
                };
                sendAnalyticsEvent(analyticsPayload);
                setHoverEventSent(true);
                console.log("Preview container hover event sent");
            }
        } catch (e: any) {
            console.log("Failed to impression: ", e.message);
        }
    };

    let embedTypeStyles: CSSProperties = {
        gap: "8px"
    };

    if (widgetSettings?.embedType === EEmbeddedLayout.GRID) {
        embedTypeStyles.display = "grid";
        if (!AUTOPLAY_SHOPS.includes(SHOP_NAME) || window.location.pathname !== "/") {
            embedTypeStyles.gridTemplateColumns = `repeat(auto-fill, minmax(${getMinWidthFromPreviewPlayerSize()}px, 1fr))`;
        }
        embedTypeStyles.alignItems = "self-start";
    }

    if (!(ganderVideos?.length > 0)) {
        return null;
    }

    return (
        <div className="gander-widget-container">
            <div
                className="gander-watch-review-container"
                style={{ fontSize: `${widgetSettings?.textFontSize}`, justifyContent: `${widgetSettings?.textAlignment}` }}
                data-testid={`${dataTestIdPrefix}-PreviewTextContainer`}
            >
                <div className="gander-watch-review-content-left">
                    <span data-testid={`${dataTestIdPrefix}-PreviewText`}>{widgetSettings?.previewTextContent}</span>
                </div>
            </div>

            <div className={`gander-preview-container`} onMouseLeave={onPreviewContainerMouseOut} data-testid={`${dataTestIdPrefix}-Preview-Container`}>
                <div
                    data-testid={`${dataTestIdPrefix}-PreviewPrevButton`}
                    className={isShowPreviewPrevScrollBtn ? "g-scroll-icon" : "g-scroll-icon g-hide-prev"}
                    onClick={() => scrollDiv('prev')}
                >
                    <ScrollUpIcon classType="prev" />
                </div>
                <div
                    className="gander-preview-item"
                    style={embedTypeStyles}
                    data-testid={`${dataTestIdPrefix}-Preview-Item`}
                    ref={previewItemRef}
                    onScroll={previewContainerScrolled}
                >
                    {ganderVideos?.map((video: IVideo, index: number) =>
                        <EmbeddedModePreviewItem key={index} indexId={index} video={video} selectedInFrameVideoObj={selectedInFrameVideoObj} setSelectedInFrameVideoObj={setSelectedInFrameVideoObj} />
                    )}
                </div>
                {isShowPreviewNextScrollBtn &&
                    <div
                        data-testid={`${dataTestIdPrefix}-PreviewNextButton`}
                        className="g-scroll-icon"
                        onClick={() => scrollDiv('next')}
                    >
                        <ScrollUpIcon classType="next" />
                    </div>
                }
            </div>
        </div>
    );
}

export default ProductPageWidget;