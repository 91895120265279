type Props = {
  handleClick: () => void;
};

const dataTestIdPrefix = "PauseButtonWithOverlayComponent";

const PauseButtonWithOverlay = ({ handleClick }: Props) => {
  return (
    <div className="g-pause-btn-with-overlay" onClick={handleClick} data-testid={`${dataTestIdPrefix}-Pause-Button-With-Overlay`}>
      <svg width="56" height="57" viewBox="0 0 56 57" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_b_2110_7596)">
          <rect className="gander-button-overlay" y="0.5" width="56" height="56" rx="28" fill="black" fillOpacity="0.16" />
          <path d="M35.1155 22.0312V34.9687C35.1155 35.3118 34.9792 35.6409 34.7366 35.8835C34.494 36.1262 34.1649 36.2625 33.8218 36.2625H30.9108C30.5677 36.2625 30.2386 36.1262 29.996 35.8835C29.7534 35.6409 29.6171 35.3118 29.6171 34.9687V22.0312C29.6171 21.6881 29.7534 21.359 29.996 21.1164C30.2386 20.8738 30.5677 20.7375 30.9108 20.7375H33.8218C34.1649 20.7375 34.494 20.8738 34.7366 21.1164C34.9792 21.359 35.1155 21.6881 35.1155 22.0312ZM25.089 20.7375H22.178C21.8349 20.7375 21.5058 20.8738 21.2632 21.1164C21.0206 21.359 20.8843 21.6881 20.8843 22.0312V34.9687C20.8843 35.3118 21.0206 35.6409 21.2632 35.8835C21.5058 36.1262 21.8349 36.2625 22.178 36.2625H25.089C25.4321 36.2625 25.7612 36.1262 26.0038 35.8835C26.2464 35.6409 26.3827 35.3118 26.3827 34.9687V22.0312C26.3827 21.6881 26.2464 21.359 26.0038 21.1164C25.7612 20.8738 25.4321 20.7375 25.089 20.7375Z" fill="white" />
        </g>
        <defs>
          <filter id="filter0_b_2110_7596" x="-20" y="-19.5" width="96" height="96" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feGaussianBlur in="BackgroundImageFix" stdDeviation="10" />
            <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_2110_7596" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_2110_7596" result="shape" />
          </filter>
        </defs>
      </svg>

    </div>
  );
};

export default PauseButtonWithOverlay;