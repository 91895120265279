import { CSSProperties, useContext, useState } from 'react'
import { AppContext } from '../../../context/app-context';
import { sendHoverEvent } from '../../../utils/elastic-events';
import GanderPlayer from '../../video-player/gander-player';
import { IVideo } from 'interfaces';
import { EPlayerShape } from 'interfaces/widget-setting-v2';
import { DEFAULT_BORDER_PADDING_FOR_PREVIEW_EMBED_AND_CLOSE_MODE } from 'constants/constants';
import VideoTimeAndDurationComponent from '../../video-time-and-duration-component';
import { getPreviewVideoUrlBasedOnScreenSize } from 'utils/common-functions';
import CloseButton from 'components/video-player/video-controls/buttons/CloseButton';

import './index.scss';

const dataTestIdPrefix = "OverlayPreviewState";

interface IProps {
    selectedVideoObj: IVideo;
    handlePreviewClick: () => void;
    closeBtnClick: () => void;
}

const PreviewMode = ({ selectedVideoObj, handlePreviewClick, closeBtnClick }: IProps) => {
    const { widgetSettings } = useContext(AppContext);
    const [hoverEventSent, setHoverEventSent] = useState(false);

    const handlePreviewMouseLeave = () => {
        if (!hoverEventSent) {
            sendHoverEvent();
            setHoverEventSent(true);
        }
    }

    const previewContainerStyles: CSSProperties = {
        height: `${widgetSettings?.previewPlayerSize}`,
        width: "auto",
        aspectRatio: `${widgetSettings?.previewOrientation === EPlayerShape.SQUARE_CIRCLE ? "1 / 1" : selectedVideoObj.isPortrait ? "9 / 16" : "16 / 9"}`
    };

    const previewMainContainerStyles: CSSProperties = {
        maxWidth: "100vw",
        maxHeight: "100vh",
        border: (widgetSettings?.previewIsShowBorder && widgetSettings?.previewBorderColor) ? `3px solid ${widgetSettings?.previewBorderColor}` : 'unset',
        padding: (widgetSettings?.previewIsShowBorder && widgetSettings?.previewIsBorderPadding) ? DEFAULT_BORDER_PADDING_FOR_PREVIEW_EMBED_AND_CLOSE_MODE : 0,
    }

    return (
        <div
            className='g-preview-main-container'
            style={previewMainContainerStyles}
            data-testid={`${dataTestIdPrefix}-PlayerContainer`}
        >
            <div className='gander-player-mask' />
            <CloseButton handleClick={closeBtnClick} />
            <div
                className='g-preview-container'
                style={previewContainerStyles}
                onClick={handlePreviewClick}
                data-testid={`${dataTestIdPrefix}-Player`}
                onMouseLeave={handlePreviewMouseLeave}
            >
                <GanderPlayer
                    type="preview"
                    url={getPreviewVideoUrlBasedOnScreenSize(widgetSettings, selectedVideoObj!)}
                    className="g-preview-player"
                    width='100%'
                    height='100%'
                />
                <div
                    className="gander-preview-content"
                    style={{ fontSize: `${widgetSettings?.textFontSize}`, textAlign: `${widgetSettings?.textAlignment}` as any }}
                    data-testid={`${dataTestIdPrefix}-PreviewTextContainer`}
                >
                    <div className="gander-preview-state-text">
                        {widgetSettings?.previewTextContent}
                    </div>
                </div>
                <VideoTimeAndDurationComponent
                    widgetModeClassName={"gander-preview-video-duration"}
                    selectedVideoObj={selectedVideoObj}
                    mainContainerStyle={{ bottom: "6px" }}
                    containerStyle={{ padding: "0 5px 0 5px" }}
                />
            </div>
        </div>
    )
}

export default PreviewMode