import { useCallback, useContext, useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import { IChoice, IGlobalVideoProgress, IProductResponse, IQuestion, IVideo, IVideoProgress } from "../../../interfaces";
import { onVideoThumbnailLoadError, updateWidgetShownAndLastSeenProduct } from "../../../utils/common-functions";
import { sendVideoNavigateEvent, sendVideosPauseEvent, sendVideoStartEvent, sendVideoStopEvent } from "../../../utils/elastic-events";
import { EMPTY_PROGRESS, GANDER_LOADER_VIDEO, GANDER_NON_BRANDING_LOADER_VIDEO } from "../../../config";
import { ArrowClickCategory } from "../../../constants/arrow_click_category";
import { VideoStartType } from "../../../constants/video_start_type";
import TimeBar from "../video-controls/time-bar";
import ProductsContainer from "../../products-container";
import { EQuestionResponseStatus } from "../../../interfaces/elastic-events";
import { AppContext } from "../../../context/app-context";
import GanderPlayer from "../gander-player";
import ArrowButtonWithOverlay from "../video-controls/buttons/ArrowButtonWithOverlay";
import TopRightControls from "./top-right-controls";
import Footer from "./footer";
import CenterControls from "./center-controls";

import './index.scss';

const dataTestIdPrefix = "FullScreenCarousalComponent";

type Props = {
    carouselVideos: IVideo[];
    activeVideoIndex: number;
    activeVideo: IVideo;
    globalVideoProgress: IGlobalVideoProgress;
    toggleFullScreen: () => void;
    onClickAddToCartQuestion?: (
        question: IQuestion,
        questionResponseId: string | undefined,
        handleNextQuestion: (question: IQuestion, choice?: IChoice) => Promise<void>,
        handleSendQuestionResponseEvent: (questionResponseId: string, question: IQuestion, status: EQuestionResponseStatus, choiceIds?: string[], userInput?: string) => Promise<void>,
    ) => void | undefined;
    onClickProductIcon: (product: IProductResponse) => void;
    setGlobalVideoProgress: React.Dispatch<React.SetStateAction<IGlobalVideoProgress>>;
    handleMinimize: (video: IVideo) => void;
    setActiveVideoIndex: React.Dispatch<React.SetStateAction<number>>;
    setActiveVideo: React.Dispatch<React.SetStateAction<IVideo>>;
};

const VideoCarousel = ({
    carouselVideos,
    activeVideoIndex,
    activeVideo,
    globalVideoProgress,
    toggleFullScreen,
    onClickAddToCartQuestion,
    onClickProductIcon,
    setGlobalVideoProgress,
    handleMinimize,
    setActiveVideoIndex,
    setActiveVideo,
}: Props) => {
    const { isMobile, isPreviewMode, abTestData, widgetSettings, isMuted, setIsMuted } = useContext(AppContext);
    const slideRef = useRef<any>(null);
    const videoRef = useRef<any>(null);
    const [videoProgress, setVideoProgress] = useState<IVideoProgress>(EMPTY_PROGRESS);
    const [isPlaying, setIsPlaying] = useState(true);
    const [isVideoChange, setIsVideoChange] = useState(false);
    const [isVideoLoaded, setIsVideoLoaded] = useState(false);
    const [nextVideo, setNextVideo] = useState<IVideo>();

    const isIOSDevice = navigator.userAgent.match(/(iPhone|iPod|iPad)/g);

    const sliderSettings = {
        className: "center",
        centerMode: true,
        variableWidth: isMobile ? false : true,
        waitForAnimate: true,
        lazyLoad: isIOSDevice ? "progressive" as "progressive" : undefined,
        infinite: false,
        draggable: false,
        speed: 500,
        swipe: true,
        swipeToSlide: true,
        centerPadding: '0px',
        slidesToShow: 1,
        slidesToScroll: 1,
        focusOnSelect: true,
        vertical: isMobile ? true : false,
        verticalSwiping: isMobile ? true : false,
        adaptiveHeight: true,
        arrows: false,
        afterChange: (currentSlide: number) => {
            setActiveVideoIndex(currentSlide);
            setIsVideoChange(false);
        },
        beforeChange: (currentSlide: number, nextSlide: number) => {
            if (currentSlide !== nextSlide) {
                // setActiveVideo(undefined);
                setIsVideoChange(true);
                const category = currentSlide < nextSlide ? ArrowClickCategory.NextVideo : ArrowClickCategory.PreviousVideo;
                sendVideoNavigateEvent(category, activeVideo?.id);

                if (videoProgress?.played !== 1) {
                    sendVideoStopEvent(activeVideo?.id, videoProgress?.playedSeconds, abTestData?.widgetShown, true);
                }
                videoRef.current?.seekTo(0, 'seconds');
                setIsPlaying(false);

            }
        },
    };

    useEffect(() => {
        setGlobalVideoProgress({ ...videoProgress, videoId: activeVideo.id });
    }, [videoProgress]);

    useEffect(() => {
        setActiveVideo(carouselVideos[activeVideoIndex]);

        const nextVideo = (carouselVideos.length > activeVideoIndex + 1) ? carouselVideos[activeVideoIndex + 1] : undefined;
        setNextVideo(nextVideo);

        setVideoProgress(EMPTY_PROGRESS);
        setIsPlaying(true);
    }, [activeVideoIndex]);

    const handleVideoPlay = () => {
        const duration = Math.floor(videoProgress?.playedSeconds);
        sendVideoStartEvent(activeVideo, abTestData, VideoStartType.Start, duration, true);
        updateWidgetShownAndLastSeenProduct(activeVideo?.id);
        setIsPlaying(true);
    };

    const handleVideoProgress = (progress: IVideoProgress) => {
        if (progress?.loadedSeconds > 0 || (progress?.playedSeconds > 0 && widgetSettings?.isAutoPlay)) {
            setIsVideoLoaded(true);
        }
        setVideoProgress(progress);
    };

    const handleVideoEnded = useCallback(() => {
        setIsPlaying(false);
        sendVideoStopEvent(activeVideo?.id, videoProgress?.playedSeconds, abTestData?.widgetShown, true);
    }, [videoProgress, activeVideoIndex]);

    const handleVideoPause = useCallback(() => {
        if (!isVideoChange && Math.round(videoProgress.played * 10) / 10 !== 1) {
            sendVideosPauseEvent(activeVideo?.id, videoProgress?.playedSeconds, true);
        }
        setIsPlaying(false);
    }, [videoProgress, isVideoChange, activeVideoIndex]);

    const handleVideoError = (error: any, data?: any, hlsInstance?: any, hlsGlobal?: any) => {
        console.log('error, data, hlsInstance, hlsGlobal handleVideoError => ', error, data, hlsInstance, hlsGlobal);
        if (error.name === 'NotAllowedError') {
            setIsMuted(true);
            setIsPlaying(true);
        } else if (error.name === 'AbortError') {
            setIsPlaying(false);
        }
    }

    const playBtnClick = () => {
        setIsPlaying(true);
    };

    const pauseBtnClick = () => {
        setIsPlaying(false);
    };

    const prevBtnClick = () => {
        if (carouselVideos.length === 0 || activeVideoIndex === 0) {
            return;
        }
        slideRef?.current?.slickPrev();
    }

    const nextBtnClick = useCallback(() => {
        if (carouselVideos.length === 0 || activeVideoIndex === carouselVideos.length - 1) {
            return;
        }
        slideRef?.current?.slickNext();
    }, [slideRef]);

    const videoCarouselNode = carouselVideos.map((video) => {
        const portraitClass = video.isPortrait ? 'gander-video-player-portrait' : 'gander-video-player-landscape';
        const isCurrentVideo = activeVideo?.id === video.id;
        return (
            <div key={video.id}>
                <div
                    className={
                        `gander-video-container
                        ${video.isPortrait ? 'gander-video-container-portrait' : 'gander-video-container-landscape'}
                        ${isPreviewMode ? 'gander-video-container-preview-mode' : ''}`
                    }
                    style={{ borderRadius: `${isMobile ? 0 : "10px"}` }}
                >
                    <div className='gander-player-mask' />
                    {!isIOSDevice && (
                        <div className={`gander-video-loader-overlay ${video.isPortrait ? 'gander-video-loader-portrait' : 'gander-video-loader-landscape'}`}>
                            {isCurrentVideo ?
                                <video
                                    playsInline
                                    autoPlay
                                    muted
                                    loop
                                    crossOrigin=''
                                    src={widgetSettings.displayBranding ? GANDER_LOADER_VIDEO : GANDER_NON_BRANDING_LOADER_VIDEO}
                                    data-testid={`${dataTestIdPrefix}-VideoLoader`}
                                />
                                :
                                <img
                                    src={video?.hdThumbnail}
                                    onError={(e) => onVideoThumbnailLoadError(e, video)}
                                    alt='video-thumbnail'
                                />
                            }
                        </div>
                    )}

                    <GanderPlayer
                        url={video.videoUrl}
                        isPlaying={isCurrentVideo && isPlaying}
                        isShowVideoCaption={false}
                        isIOSDevice={!!(isIOSDevice)}
                        nextVideo={nextVideo}
                        isMuted={isMuted}
                        isLoadVideo={isCurrentVideo} // to handle shared fullscreen case
                        ref={isCurrentVideo ? videoRef : null}
                        height={isMobile ? window.innerHeight + "px" : ''}
                        className={`gander-react-video-player ${portraitClass}`}
                        setIsPlaying={setIsPlaying}
                        onVideoPlay={handleVideoPlay}
                        onVideoPause={handleVideoPause}
                        onVideoError={handleVideoError}
                        onVideoProgress={isCurrentVideo ? handleVideoProgress : undefined}
                        onVideoEnded={handleVideoEnded}
                        globalVideoProgress={globalVideoProgress}
                        selectedVideoObj={video}
                        dataTestId={`${dataTestIdPrefix}-FullscreenVideo`}
                    />
                    {(!isVideoChange && isCurrentVideo) &&
                        <>
                            <div onClick={isPlaying ? pauseBtnClick : playBtnClick} className={`gander-video-progress-bar-overlay ${!video.isPortrait && 'gander-video-progress-bar-overlay-landscape'}`} >
                                <div>
                                    <TimeBar 
                                        styles={{ padding: `${isMobile ? '0' : '0 3px'}` }} 
                                        videoProgress={videoProgress} ref={videoRef} 
                                        setVideoProgress={setVideoProgress} 
                                        dataTestId={`${dataTestIdPrefix}-TimeBar`}
                                    />
                                </div>
                            </div>
                            <CenterControls
                                isPlaying={isPlaying}
                                playBtnClick={() => setIsPlaying(true)}
                                pauseBtnClick={() => setIsPlaying(false)}
                                dataTestId={`${dataTestIdPrefix}-CenterControl`}
                            />
                            {isVideoLoaded && (
                                <ProductsContainer
                                    products={video.products}
                                    isPortrait={video.isPortrait}
                                    isFullscreen={true}
                                    onClickProductIcon={onClickProductIcon}
                                    isDisplayBranding={widgetSettings?.displayBranding}
                                />
                            )}
                            <TopRightControls
                                isPortrait={video.isPortrait}
                                isVideoLoaded={isVideoLoaded}
                                toggleFullScreen={toggleFullScreen}
                                handleMinimize={() => handleMinimize(video)}
                            />
                            <Footer
                                selectedVideoObj={activeVideo}
                                fullScreen={true}
                                isVideoLoaded={isVideoLoaded}
                                displayBranding={widgetSettings?.displayBranding}
                                dataTestId={`${dataTestIdPrefix}-Preview-Video`}
                            />
                        </>
                    }
                </div>
            </div>
        );
    });
    return (
        <div className={`gander-video-carousel ${carouselVideos.length === 1 ? 'gander-single-child' : ''}`}>
            <Slider ref={slideRef} {...sliderSettings}>
                {videoCarouselNode}
            </Slider>
            {isMobile && !isVideoChange && <ArrowButtonWithOverlay handleClick={prevBtnClick} />}
            {isMobile && !isVideoChange && <ArrowButtonWithOverlay handleClick={nextBtnClick} />}
        </div>
    );
};

export default VideoCarousel;