import { useContext } from "react";
import ArrowButtonWithOverlay from "components/video-player/video-controls/buttons/ArrowButtonWithOverlay";
import { AppContext } from "context/app-context";
import { IVideo, IVideoProgress } from "interfaces";
import { sendVideoNavigateEvent, sendVideoStopEvent } from "utils/elastic-events";
import { ArrowClickCategory } from "constants/arrow_click_category";

interface Props {
  isPortrait: boolean;
  selectedVideoObj: IVideo;
  videoProgress: IVideoProgress;
  sliderNextVideoClick: () => void;
  sliderPrevVideoClick: () => void;
}

const RightControls = ({ isPortrait, selectedVideoObj, videoProgress, sliderNextVideoClick, sliderPrevVideoClick }: Props) => {
  const { abTestData } = useContext(AppContext);

  const nextVideoBtnClick = () => {
    if (videoProgress?.played !== 1) {
      sendVideoStopEvent(selectedVideoObj?.id, videoProgress?.playedSeconds, abTestData?.widgetShown, false);
    }
    sendVideoNavigateEvent(ArrowClickCategory.NextVideo, selectedVideoObj?.id);
    sliderNextVideoClick();
  }

  const prevVideoBtnClick = () => {
    if (videoProgress?.played !== 1) {
      sendVideoStopEvent(selectedVideoObj?.id, videoProgress?.playedSeconds, abTestData?.widgetShown, false);
    }
    sendVideoNavigateEvent(ArrowClickCategory.PreviousVideo, selectedVideoObj?.id);
    sliderPrevVideoClick();
  }
  return (
    <div className={`gander-open-mode-right-controls ${isPortrait ? "" : "gander-is-landscape"}`}>
      <ArrowButtonWithOverlay handleClick={nextVideoBtnClick} />
      <ArrowButtonWithOverlay handleClick={prevVideoBtnClick} />
    </div>
  )
}

export default RightControls;