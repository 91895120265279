import { useState, useEffect, SyntheticEvent, MutableRefObject } from "react";
import { ToggleMenuArrowWithCircleSvg } from "./toggle-menu-arrow-circle-svg";
import AddToCartFormComponent from "../add-to-cart-form-component";
import { IProductResponse, IQuestion, IShopifyProductResponse, IShopifyProductVariant, IWidgetSettings } from "../../../interfaces";

import './index.scss';
const dataTestIdPrefix = "FullScreenProductDetailsComponent";

interface IProps {
    formRef: MutableRefObject<{ current: HTMLFormElement; } | null | undefined>;
    selectedProduct: IShopifyProductVariant | undefined;
    productData: IShopifyProductResponse | undefined;
    question: IQuestion | undefined;
    isProductDetailsOpen: boolean;
    isSubmitting: boolean;
    isFullscreen: boolean;
    widgetSettings: IWidgetSettings;
    productObj?: IProductResponse;
    handleSubmit: (e: SyntheticEvent) => void;
    onClickCloseButton: () => void;
    handleVariantChange: () => void;
}


const FullScreenProductDetailsComponent = ({
    question,
    isProductDetailsOpen,
    isSubmitting,
    isFullscreen,
    widgetSettings,
    formRef,
    selectedProduct,
    productData,
    productObj,
    handleVariantChange,
    onClickCloseButton,
    handleSubmit
}: IProps) => {

    const [previewImg, setPreviewImg] = useState('');
    const isShopCurrencyUSD = window?.Shopify?.currency?.active === 'USD';

    useEffect(() => {
        return () => {
            setPreviewImg('');
        }
    }, [question, productData, selectedProduct]);

    return (
        <div className={`g-product-details-sidebar-container ${isProductDetailsOpen ? 'g-product-details-sidebar-container-active' : ''}`}>
            <div className="g-product-details-sidebar-drawer-toggler" onClick={onClickCloseButton}>
                <ToggleMenuArrowWithCircleSvg />
            </div>
            <div className="g-product-details-sidebar-content">
                <div className="g-product-details-sidebar-image-container">
                    <div className="g-product-details-sidebar-main-div">
                        <img className="g-product-details-sidebar-main-img" src={previewImg || selectedProduct?.featured_image?.src || productData?.featured_image} alt={productData?.title} data-testid={`${dataTestIdPrefix}-Img`}/>
                    </div>
                    <div className="g-product-details-sidebar-preview-img-slider">
                        {productData?.images?.map((img: string) =>
                            <div key={img} className="g-product-details-sidebar-preview-img-item" onClick={() => setPreviewImg(img)}>
                                <img className="g-product-details-sidebar-preview-img" src={img} alt={productData?.handle} />
                            </div>
                        )}
                    </div>
                </div>
                <div className="g-product-details-sidebar-price-title-container">
                    <span className="g-product-details-sidebar-title-span" title={productData?.title + ' (' + selectedProduct?.title + ' )'}  data-testid={`${dataTestIdPrefix}-TitleTxt`}>
                        {productData?.title}
                    </span>
                    {productData?.price && (
                        <span className="g-product-details-sidebar-price-span" data-testid={`${dataTestIdPrefix}-PriceTxt`}>
                            {isShopCurrencyUSD ? '$' : '' }
                            {(selectedProduct?.price || productData?.price) / 100}
                            {(selectedProduct?.compare_at_price || productData?.compare_at_price) && (
                                <span data-testid={`${dataTestIdPrefix}-ComparePriceTxt`} className="g-product-details-sidebar-compare-price-span">
                                    {isShopCurrencyUSD ? '$' : '' }
                                    {(selectedProduct?.compare_at_price || productData?.compare_at_price) / 100}</span>
                            )}
                        </span>
                    )}
                </div>

                {/* Common Form Component that handles submit part as well */}
                <div className="g-product-details-sidebar-form-container">
                    <AddToCartFormComponent
                        handleSubmit={handleSubmit}
                        isFullscreen={isFullscreen}
                        isSubmitting={isSubmitting}
                        formRef={formRef}
                        productData={productData}
                        selectedProduct={selectedProduct}
                        question={question}
                        handleVariantChange={handleVariantChange}
                        widgetSettings={widgetSettings}
                        productObj={productObj}
                    />
                </div>

            </div>
        </div>
    );
};

export default FullScreenProductDetailsComponent;
