import { CSSProperties, useContext } from "react";
import { AppContext } from "context/app-context";
import { EPlayerShape } from "interfaces/widget-setting-v2";
import { IVideo } from "interfaces";

import "./index.scss";

interface IProps {
    selectedVideoObj: IVideo;
    containerStyle: CSSProperties;
    mainContainerStyle: CSSProperties;
    widgetModeClassName: string;
}

const dataTestIdPrefix = "VideoTimeAndDurationComponent";

const VideoTimeAndDurationComponent = ({ selectedVideoObj, containerStyle, mainContainerStyle, widgetModeClassName }: IProps) => {
    const { widgetSettings } = useContext(AppContext);

    const getDurationInFormat = (durationSeconds: string) => {
        if (durationSeconds) {
            const date = new Date(0);
            date.setSeconds(parseInt(durationSeconds, 10));
            return date.toISOString().substring(14, 19); // mm:ss
        }
    };

    return (
        <div className={`gander-preview-video-detail ${widgetModeClassName}`} style={mainContainerStyle} >
            <div
                style={containerStyle}
                className={`gander-preview-video-detail-wrapper
                    ${(widgetSettings?.previewOrientation === EPlayerShape.SQUARE_CIRCLE || parseInt(widgetSettings?.previewCornerRadius) >= 5) && "gander-preview-square-circle-or-bordered"}
                    ${(widgetSettings?.previewOrientation === EPlayerShape.PORTRAIT_LANDSCAPE && parseInt(widgetSettings?.previewCornerRadius) <= 5) && "gander-preview-portrait-landscape"}`
                }
            >
                {(widgetSettings?.textIsShowViewCount && parseInt(widgetSettings?.previewCornerRadius) <= 5) && selectedVideoObj?.viewCount && (
                    <div className="gander-preview-video-detail-container gander-preview-video-view-count">
                        <svg
                            viewBox="0 0 9 10"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M1.08325 1.25L6.91658 5L1.08325 8.75V1.25Z"
                                fill="white"
                                stroke="white"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                        <span className="gander-preview-video-detail-text" data-testid={`${dataTestIdPrefix}-ViewCountText`}>
                            {selectedVideoObj.viewCount >= 1000 ? `${(selectedVideoObj.viewCount / 1000).toFixed(1)}k` : selectedVideoObj.viewCount}
                        </span>
                    </div>
                )}
                {widgetSettings?.textIsShowDuration && (
                    <div className="gander-preview-video-detail-container gander-preview-video-duration">
                        <span className="gander-preview-video-detail-text" data-testid={`${dataTestIdPrefix}-VideoDuration`}>
                            {getDurationInFormat(selectedVideoObj.duration)}
                        </span>
                    </div>
                )}
            </div>
        </div>
    );
};

export default VideoTimeAndDurationComponent;
