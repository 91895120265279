import { forwardRef, useMemo, useState, useEffect } from 'react';
import { getVideoUrlBasedOnScreenSize } from '../../../utils/common-functions';
import logger from '../../../utils/logger';
import { TrackProps } from 'react-player/file';
import ReactPlayer from 'react-player';
import { IGlobalVideoProgress, IVideo, IVideoProgress } from '../../../interfaces';
import PreloadAssetComponent from '../../preload-asset';

type Props = {
    type?: string;
    className: string;
    url: string;
    style?: any;
    captions?: string;
    width?: string;
    height?: string;
    isPlaying?: boolean;
    isIOSDevice?: boolean;
    nextVideo?: IVideo;
    isShowVideoCaption?: boolean;
    isLoadVideo?: boolean;
    isMuted?: boolean;
    setIsPlaying?: (value: boolean) => void;
    onVideoPlay?: () => void;
    onVideoPause?: () => void;
    onVideoError?: (error: any, data?: any, hlsInstance?: any, hlsGlobal?: any) => void;
    onVideoProgress?: (progress: IVideoProgress) => void;
    onVideoEnded?: () => void;
    globalVideoProgress?: IGlobalVideoProgress;
    selectedVideoObj?: IVideo;
    dataTestId?: string
};

const GanderPlayer = ({
    type,
    captions,
    className,
    url,
    style,
    width,
    height,
    isShowVideoCaption,
    isLoadVideo,
    isPlaying,
    isMuted,
    isIOSDevice,
    nextVideo,
    setIsPlaying,
    onVideoPlay,
    onVideoPause,
    onVideoError,
    onVideoProgress,
    onVideoEnded,
    globalVideoProgress,
    selectedVideoObj,
    dataTestId
}: Props, playerRef: any) => {
    const [isBufferEnded, setIsBufferEnded] = useState(false);
    
    const videoUrlMemo = useMemo(() => getVideoUrlBasedOnScreenSize(url), [url]);
    useEffect(() => {
        // Check if video to appear first on the page is the same as the one minimized in fullscreen, and that the video is not finished
        if (globalVideoProgress && playerRef?.current && globalVideoProgress.videoId === selectedVideoObj?.id && globalVideoProgress.played !== 1) {
            (playerRef.current as any).seekTo(Math.round(globalVideoProgress.playedSeconds));
        }
    }, []);

    const handleClick = () => {
        if (setIsPlaying) {
            setIsPlaying(!isPlaying);
        }
    }

    const onBuffer = () => {
        logger("🚀 ~ file: index.tsx ~ onBuffer ~ onBuffer");
    }

    const onBufferEnd = () => {
        setIsBufferEnded(true);
    }

    return (
        <div style={{ width, height }} onClick={handleClick} className='gander-react-player-wrapper' data-testid={dataTestId}>
            {nextVideo?.videoUrl && <PreloadAssetComponent assetLinkToLoad={isBufferEnded ? getVideoUrlBasedOnScreenSize(nextVideo?.videoUrl).replace('.m3u8', '_00001.ts') : ""} />}
            <ReactPlayer
                playsinline
                playsInline
                ref={playerRef}
                className={className}
                style={style}
                onPlay={onVideoPlay}
                onPause={onVideoPause}
                onError={onVideoError}
                onProgress={onVideoProgress}
                onEnded={onVideoEnded}
                onBuffer={onBuffer}
                onBufferEnd={onBufferEnd}
                url={videoUrlMemo}
                light={(type === 'preview' || isLoadVideo || isPlaying || isBufferEnded || isIOSDevice) ? false : true}
                playIcon={<></>}
                previewTabIndex={-1}
                controls={false}
                loop={type === 'preview'}
                muted={type === 'preview' || isMuted}
                playing={type === 'preview' ? true : isPlaying}
                pip={false}
                height={height || "100%"}
                width="100%"
                progressInterval={1000}
                config={{
                    file: {
                        attributes: { crossOrigin: "true" },
                        tracks: isShowVideoCaption ? [
                            {
                                kind: "subtitles",
                                src: captions,
                                srcLang: "en",
                                label: "English",
                                default: true,
                            } as TrackProps,
                        ] : [],
                    },
                }}
            />
        </div>
    );
};

export default forwardRef(GanderPlayer);