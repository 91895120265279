import { EQuestionType } from ".";

export interface IInteractionResponsePayload {
  interactionResponseId: string,
  interactionId: string,
  videoId: string,
  status: EInteractionResponseStatus,
}

export interface IQuestionResponsePayload {
  questionResponseId: string,
  interactionResponseId: string,
  interactionId: string,
  questionId: string,
  status: EQuestionResponseStatus,
  type: EQuestionType,
  choiceIds?: string[],
  userInput?: string,
}

export enum EQuestionResponseStatus {
  VIEWED = "viewed",
  SUBMITTED = "submitted",
}

export enum EInteractionResponseStatus {
  VIEWED = "viewed",
  STARTED = "started",
  SUBMITTED = "submitted",
}