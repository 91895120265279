interface IProps {
    assetLinkToLoad: string;
    loadAs?: 'script' | 'style' | 'font' | 'image';
}


const PreloadAssetComponent = ({ assetLinkToLoad, loadAs = 'script' }: IProps) => {

    return (
        <>
            {assetLinkToLoad ? (
                <link rel="preload" as={loadAs} href={assetLinkToLoad}></link>
            ) : null}
        </>
    )
}

export default PreloadAssetComponent;