import { EEmbeddedLayout, EEmbeddedPlayerStyle, EOverlayOpenState, EOverlayStartingState, EPlayerShape, EWidgetType } from "interfaces/widget-setting-v2";
import { IABTestResponse, ISettingsAndVideos, IVideoProgress, IWidgetSettings } from "../interfaces";
import { getItemFromLocalStorage } from "utils/get-set-local-storage";

const GO_GANDER_LINK = "https://www.gogander.io/brand-referral";
const APP_VERSION = "1.2.0";

const DEFAULT_SETTINGS: IWidgetSettings = {
  // new properties
  embedGridNumberOfVideos: 5,
  textColor: "#ff0000",
  buttonColor: "#322828",
  displayBranding: false,
  previewTextContent: "See it live",
  fullScreenIsShowAll: true,
  textFontSize: "10px",
  textAlignment: "center",
  textIsShowViewCount: false,
  textIsShowDuration: false,
  openStateType: EOverlayOpenState.OVERLAY,
  overlayWidgetSize: "480px",
  previewBorderColor: "#000000",
  previewIsShowBorder: false,
  previewIsBorderPadding: false,
  previewOrientation: EPlayerShape.PORTRAIT_LANDSCAPE,
  previewOverlayStartingState: EOverlayStartingState.OPEN_STATE,
  previewEmbedGifStyle: EEmbeddedPlayerStyle.SIMULTANEOUS,
  widgetType: EWidgetType.EMBEDDED,
  embedType: EEmbeddedLayout.HORIZONTAL_SCROLL,
  previewPlayerSize: "90px",
  previewCornerRadius: "10px",
  closeCornerRadius: "10px",
  closeIsShowBorder: true,
  closeBorderColor: "#000000",
  closeIsBorderPadding: true,
  leftPosition: '0%',
  topPosition: '100%',

  isShowVideoCaption: false, // TODO : need to remove this after discussion if we are not using it.
  isShowPreview: false, // TODO: need to remove this after discussion
  isAutoPlay: true, // this is in future we can give option in customization tab
  isShowVideoDisplayName: false, // to allow option displaying video names
};

const EMPTY_PROGRESS: IVideoProgress = {
  loaded: 0,
  loadedSeconds: 0,
  played: 0,
  playedSeconds: 0,
};

const API_ABORT_TIME_DURATION_IN_SECONDS = 6;

//CLIENT is shopify then take shopify shop name else take location host domain as shopname for elastic events
const SHOPIFY_SHOP = window?.Shopify?.shop;
const LOCATION_HOST = window?.location?.hostname;
let SHOP_NAME = SHOPIFY_SHOP ? SHOPIFY_SHOP : LOCATION_HOST;


const LOCATION_PATH_NAME = () => {
  return window?.location?.pathname || "";
};

const AB_TEST_DEFAULT_VALUES: IABTestResponse = {
  isABTestOn: false, // default false
  isOnHoldoutGroup: false, // default false
  widgetShown: false, // default false
};

const DEFAULT_SETTINGS_AND_VIDEOS: ISettingsAndVideos = {
  videos: [],
  widgetSettings: DEFAULT_SETTINGS,
  abHoldOutData: AB_TEST_DEFAULT_VALUES,
};

const GANDER_LOADER_VIDEO = "https://d1m0ae25uhy92x.cloudfront.net/loading_gif/branding-loader-black-bg-white-cr.mp4";
const GANDER_NON_BRANDING_LOADER_VIDEO = "https://d1m0ae25uhy92x.cloudfront.net/loading_gif/no-branding-loader.mp4";
const GANDER_UP_ARROW_ANIMATE_GIF = "https://d1m0ae25uhy92x.cloudfront.net/loading_gif/up-arrow-animate.gif";

// appended local/session to identify where it's stored
const LOCAL_SESSION_ID_KEY = "klara-session-id";
const LAST_SESSION_USED_TIMESTAMP = "gander-last-session-used-timestamp";
const SESSION_WIDGET_SHOWN_KEY = "klara-widget-shown";
const SESSION_LAST_SEEN_PRODUCT = "klara-last-seen-product";
const GANDER_CLIENT_ID = "gander-client-id";
const GANDER_USER_DETAILS = "gander-user-details";
const GANDER_SESSION_ID = "gander-session-id";
const GANDER_OVERRIDE_DOMAIN = "gander-override-domain";
// Session Keys
const IS_CLOSE_MODE_ACTIVE = 'isCloseModeActive';

// Feature: for merchant developers where they can set localstorage value with production domain and we will override localhost value
const ganderOverrideDomain = getItemFromLocalStorage(GANDER_OVERRIDE_DOMAIN);
if (LOCATION_HOST.includes('localhost') && ganderOverrideDomain) {
    SHOP_NAME = ganderOverrideDomain;
}

export {
  DEFAULT_SETTINGS,
  EMPTY_PROGRESS,
  API_ABORT_TIME_DURATION_IN_SECONDS,
  APP_VERSION,
  SHOP_NAME,
  LOCATION_HOST,
  LOCATION_PATH_NAME,
  AB_TEST_DEFAULT_VALUES,
  GO_GANDER_LINK,
  LOCAL_SESSION_ID_KEY,
  SESSION_WIDGET_SHOWN_KEY,
  SESSION_LAST_SEEN_PRODUCT,
  DEFAULT_SETTINGS_AND_VIDEOS,
  GANDER_CLIENT_ID,
  LAST_SESSION_USED_TIMESTAMP,
  GANDER_USER_DETAILS,
  GANDER_SESSION_ID,
  GANDER_LOADER_VIDEO,
  GANDER_NON_BRANDING_LOADER_VIDEO,
  GANDER_UP_ARROW_ANIMATE_GIF,
  GANDER_OVERRIDE_DOMAIN,
  IS_CLOSE_MODE_ACTIVE
};
