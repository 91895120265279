import { EWidgetType } from "interfaces/widget-setting-v2";

const ganderFEWidgetPreviewData = {
  widgetSettings: {
    isShowPreview: false,
    isAutoPlay: true,
    displayBranding: false,
    isShowVideoCaption: false,
    isShowVideoDisplayName: false,
    widgetType: EWidgetType.OVERLAY
  },
  videos: [
    {
      id: "a96230b5-60c6-4032-b229-0f7c9e4711e7",
      index: 0,
      previewUrl: "https://d1m0ae25uhy92x.cloudfront.net/a96230b5-60c6-4032-b229-0f7c9e4711e7/video_preview.mp4",
      hdPreviewUrl: "https://d1m0ae25uhy92x.cloudfront.net/a96230b5-60c6-4032-b229-0f7c9e4711e7/hd_video_preview.mp4",
      videoUrl: "https://d1m0ae25uhy92x.cloudfront.net/a96230b5-60c6-4032-b229-0f7c9e4711e7/a96230b5-60c6-4032-b229-0f7c9e4711e7.m3u8",
      thumbnail: "https://d1m0ae25uhy92x.cloudfront.net/a96230b5-60c6-4032-b229-0f7c9e4711e7/a96230b5-60c6-4032-b229-0f7c9e4711e7_thumbnails.0000001.jpg",
      hdThumbnail: "https://d1m0ae25uhy92x.cloudfront.net/a96230b5-60c6-4032-b229-0f7c9e4711e7/hd_img_preview.jpg",
      captions: "https://d1m0ae25uhy92x.cloudfront.net/a96230b5-60c6-4032-b229-0f7c9e4711e7/a96230b5-60c6-4032-b229-0f7c9e4711e7.vtt",
      isPortrait: true,
      duration: "18.040000",
      viewCount: 2208,
      interaction: {
        "id": "c0919f70-b8af-11ed-a090-a5c3c6a90b80",
        "questions": [
          {
            "GSI1PK": "INTERACTION#c0919f70-b8af-11ed-a090-a5c3c6a90b80",
            "dataType": "question",
            "updatedAt": 1678478300750,
            "meta": "ffee9303-ef5a-47b0-b45c-05a89e5840b1",
            "createdAt": 1677732132552,
            "text": "SHOP NOW",
            "id": "ffee9303-ef5a-47b0-b45c-05a89e5840b1",
            "GSI1SK": "QUESTION#ffee9303-ef5a-47b0-b45c-05a89e5840b1",
            "choices": [
              {
                "id": "f34ef5b6-f785-4277-bac0-e178aa042ed2",
                "buttonTextColor": "#FFFFFF",
                "redirectUrl": "https://www.gander.com",
                "buttonColor": "#3C8EF9",
                "nextQuestionMeta": ""
              }
            ],
            "type": "call-to-action"
          },
        ]
      },
    },
    {
      id: "2067f159-8237-471d-a5ce-b8f1c9918925",
      index: 1,
      previewUrl: "https://d1m0ae25uhy92x.cloudfront.net/2067f159-8237-471d-a5ce-b8f1c9918925/video_preview.mp4",
      hdPreviewUrl: "https://d1m0ae25uhy92x.cloudfront.net/2067f159-8237-471d-a5ce-b8f1c9918925/hd_video_preview.mp4",
      videoUrl: "https://d1m0ae25uhy92x.cloudfront.net/2067f159-8237-471d-a5ce-b8f1c9918925/2067f159-8237-471d-a5ce-b8f1c9918925.m3u8",
      thumbnail: "https://d1m0ae25uhy92x.cloudfront.net/2067f159-8237-471d-a5ce-b8f1c9918925/2067f159-8237-471d-a5ce-b8f1c9918925_thumbnails.0000001.jpg",
      hdThumbnail: "https://d1m0ae25uhy92x.cloudfront.net/2067f159-8237-471d-a5ce-b8f1c9918925/hd_img_preview.jpg",
      captions: "https://d1m0ae25uhy92x.cloudfront.net/2067f159-8237-471d-a5ce-b8f1c9918925/2067f159-8237-471d-a5ce-b8f1c9918925.vtt",
      isPortrait: true,
      duration: "18.040000",
      viewCount: 365,
    },
    {
      id: "438022bb-9940-46d8-bb28-4b63f5eaaa03",
      index: 2,
      previewUrl: "https://d1m0ae25uhy92x.cloudfront.net/438022bb-9940-46d8-bb28-4b63f5eaaa03/video_preview.mp4",
      hdPreviewUrl: "https://d1m0ae25uhy92x.cloudfront.net/438022bb-9940-46d8-bb28-4b63f5eaaa03/hd_video_preview.mp4",
      videoUrl: "https://d1m0ae25uhy92x.cloudfront.net/438022bb-9940-46d8-bb28-4b63f5eaaa03/438022bb-9940-46d8-bb28-4b63f5eaaa03.m3u8",
      thumbnail: "https://d1m0ae25uhy92x.cloudfront.net/438022bb-9940-46d8-bb28-4b63f5eaaa03/ea29bd9d-789d-48cd-a59f-6252a4786153_thumbnails.0000001.jpg",
      hdThumbnail: "https://d1m0ae25uhy92x.cloudfront.net/438022bb-9940-46d8-bb28-4b63f5eaaa03/hd_img_preview.jpg",
      captions: "https://d1m0ae25uhy92x.cloudfront.net/438022bb-9940-46d8-bb28-4b63f5eaaa03/438022bb-9940-46d8-bb28-4b63f5eaaa03.vtt",
      isPortrait: true,
      duration: "18.040000",
      viewCount: 1755,
    },
    {
      id: "ccefb47b-fc68-4496-9fe4-b60b41805bf1",
      index: 3,
      previewUrl: "https://d1m0ae25uhy92x.cloudfront.net/ccefb47b-fc68-4496-9fe4-b60b41805bf1/video_preview.mp4",
      hdPreviewUrl: "https://d1m0ae25uhy92x.cloudfront.net/ccefb47b-fc68-4496-9fe4-b60b41805bf1/hd_video_preview.mp4",
      videoUrl: "https://d1m0ae25uhy92x.cloudfront.net/ccefb47b-fc68-4496-9fe4-b60b41805bf1/ccefb47b-fc68-4496-9fe4-b60b41805bf1.m3u8",
      thumbnail: "https://d1m0ae25uhy92x.cloudfront.net/ccefb47b-fc68-4496-9fe4-b60b41805bf1/ccefb47b-fc68-4496-9fe4-b60b41805bf1_thumbnails.0000001.jpg",
      hdThumbnail: "https://d1m0ae25uhy92x.cloudfront.net/ccefb47b-fc68-4496-9fe4-b60b41805bf1/hd_img_preview.jpg",
      captions: "https://d1m0ae25uhy92x.cloudfront.net/ccefb47b-fc68-4496-9fe4-b60b41805bf1/ccefb47b-fc68-4496-9fe4-b60b41805bf1.vtt",
      isPortrait: true,
      duration: "18.040000",
      viewCount: 20384,
    },
    {
      id: "75f567d8-331f-4d79-8b3c-951d083821d3",
      index: 4,
      previewUrl: "https://d1m0ae25uhy92x.cloudfront.net/75f567d8-331f-4d79-8b3c-951d083821d3/video_preview.mp4",
      hdPreviewUrl: "https://d1m0ae25uhy92x.cloudfront.net/75f567d8-331f-4d79-8b3c-951d083821d3/hd_video_preview.mp4",
      videoUrl: "https://d1m0ae25uhy92x.cloudfront.net/75f567d8-331f-4d79-8b3c-951d083821d3/75f567d8-331f-4d79-8b3c-951d083821d3.m3u8",
      thumbnail: "https://d1m0ae25uhy92x.cloudfront.net/75f567d8-331f-4d79-8b3c-951d083821d3/324d8dab-a0d1-45cb-98e3-a5fc993e3b70_thumbnails.0000001.jpg",
      hdThumbnail: "https://d1m0ae25uhy92x.cloudfront.net/75f567d8-331f-4d79-8b3c-951d083821d3/hd_img_preview.jpg",
      captions: "https://d1m0ae25uhy92x.cloudfront.net/75f567d8-331f-4d79-8b3c-951d083821d3/75f567d8-331f-4d79-8b3c-951d083821d3.vtt",
      isPortrait: true,
      duration: "18.040000",
      viewCount: 302,
    },
    {
      id: "ba49b18e-a6e5-472c-9a20-e878e9e9d168",
      index: 5,
      previewUrl: "https://d1m0ae25uhy92x.cloudfront.net/ba49b18e-a6e5-472c-9a20-e878e9e9d168/video_preview.mp4",
      hdPreviewUrl: "https://d1m0ae25uhy92x.cloudfront.net/ba49b18e-a6e5-472c-9a20-e878e9e9d168/hd_video_preview.mp4",
      videoUrl: "https://d1m0ae25uhy92x.cloudfront.net/ba49b18e-a6e5-472c-9a20-e878e9e9d168/ba49b18e-a6e5-472c-9a20-e878e9e9d168.m3u8",
      thumbnail: "https://d1m0ae25uhy92x.cloudfront.net/ba49b18e-a6e5-472c-9a20-e878e9e9d168/ba49b18e-a6e5-472c-9a20-e878e9e9d168_thumbnails.0000001.jpg",
      hdThumbnail: "https://d1m0ae25uhy92x.cloudfront.net/ba49b18e-a6e5-472c-9a20-e878e9e9d168/hd_img_preview.jpg",
      captions: "https://d1m0ae25uhy92x.cloudfront.net/ba49b18e-a6e5-472c-9a20-e878e9e9d168/ba49b18e-a6e5-472c-9a20-e878e9e9d168.vtt",
      isPortrait: true,
      duration: "18.040000",
      viewCount: 54,
    },
    {
      id: "be9b1403-93d6-4d67-92fb-698fc06eaa5a",
      index: 6,
      previewUrl: "https://d1m0ae25uhy92x.cloudfront.net/be9b1403-93d6-4d67-92fb-698fc06eaa5a/video_preview.mp4",
      hdPreviewUrl: "https://d1m0ae25uhy92x.cloudfront.net/be9b1403-93d6-4d67-92fb-698fc06eaa5a/hd_video_preview.mp4",
      videoUrl: "https://d1m0ae25uhy92x.cloudfront.net/be9b1403-93d6-4d67-92fb-698fc06eaa5a/be9b1403-93d6-4d67-92fb-698fc06eaa5a.m3u8",
      thumbnail: "https://d1m0ae25uhy92x.cloudfront.net/be9b1403-93d6-4d67-92fb-698fc06eaa5a/be9b1403-93d6-4d67-92fb-698fc06eaa5a_thumbnails.0000001.jpg",
      hdThumbnail: "https://d1m0ae25uhy92x.cloudfront.net/be9b1403-93d6-4d67-92fb-698fc06eaa5a/hd_img_preview.jpg",
      captions: "https://d1m0ae25uhy92x.cloudfront.net/be9b1403-93d6-4d67-92fb-698fc06eaa5a/be9b1403-93d6-4d67-92fb-698fc06eaa5a.vtt",
      isPortrait: true,
      duration: "18.040000",
      viewCount: 6504,
    },
    {
      id: "e1e4348d-5509-45e7-bc44-0d251545a165",
      index: 7,
      previewUrl: "https://d1m0ae25uhy92x.cloudfront.net/e1e4348d-5509-45e7-bc44-0d251545a165/video_preview.mp4",
      hdPreviewUrl: "https://d1m0ae25uhy92x.cloudfront.net/e1e4348d-5509-45e7-bc44-0d251545a165/hd_video_preview.mp4",
      videoUrl: "https://d1m0ae25uhy92x.cloudfront.net/e1e4348d-5509-45e7-bc44-0d251545a165/e1e4348d-5509-45e7-bc44-0d251545a165.m3u8",
      thumbnail: "https://d1m0ae25uhy92x.cloudfront.net/e1e4348d-5509-45e7-bc44-0d251545a165/e1e4348d-5509-45e7-bc44-0d251545a165_thumbnails.0000001.jpg",
      hdThumbnail: "https://d1m0ae25uhy92x.cloudfront.net/e1e4348d-5509-45e7-bc44-0d251545a165/hd_img_preview.jpg",
      captions: "https://d1m0ae25uhy92x.cloudfront.net/e1e4348d-5509-45e7-bc44-0d251545a165/e1e4348d-5509-45e7-bc44-0d251545a165.vtt",
      isPortrait: true,
      duration: "18.040000",
      viewCount: 13,
    },
    {
      id: "aa7d1b56-de1b-44d7-a398-7b66d20a733c",
      index: 8,
      previewUrl: "https://d1m0ae25uhy92x.cloudfront.net/aa7d1b56-de1b-44d7-a398-7b66d20a733c/video_preview.mp4",
      hdPreviewUrl: "https://d1m0ae25uhy92x.cloudfront.net/aa7d1b56-de1b-44d7-a398-7b66d20a733c/hd_video_preview.mp4",
      videoUrl: "https://d1m0ae25uhy92x.cloudfront.net/aa7d1b56-de1b-44d7-a398-7b66d20a733c/aa7d1b56-de1b-44d7-a398-7b66d20a733c.m3u8",
      thumbnail: "https://d1m0ae25uhy92x.cloudfront.net/aa7d1b56-de1b-44d7-a398-7b66d20a733c/77625977-64bb-4fee-a99c-a4878a1d8bc7_thumbnails.0000001.jpg",
      hdThumbnail: "https://d1m0ae25uhy92x.cloudfront.net/aa7d1b56-de1b-44d7-a398-7b66d20a733c/hd_img_preview.jpg",
      captions: "https://d1m0ae25uhy92x.cloudfront.net/aa7d1b56-de1b-44d7-a398-7b66d20a733c/aa7d1b56-de1b-44d7-a398-7b66d20a733c.vtt",
      isPortrait: true,
      duration: "18.040000",
      viewCount: 650394,
    },
    {
      id: "a57029e6-9d4f-448b-aeda-9f329a8199cd",
      index: 9,
      previewUrl: "https://d1m0ae25uhy92x.cloudfront.net/a57029e6-9d4f-448b-aeda-9f329a8199cd/video_preview.mp4",
      hdPreviewUrl: "https://d1m0ae25uhy92x.cloudfront.net/a57029e6-9d4f-448b-aeda-9f329a8199cd/hd_video_preview.mp4",
      videoUrl: "https://d1m0ae25uhy92x.cloudfront.net/a57029e6-9d4f-448b-aeda-9f329a8199cd/a57029e6-9d4f-448b-aeda-9f329a8199cd.m3u8",
      thumbnail: "https://d1m0ae25uhy92x.cloudfront.net/a57029e6-9d4f-448b-aeda-9f329a8199cd/7fddf41b-9a6b-4286-86e1-66ff6a39d217_thumbnails.0000001.jpg",
      hdThumbnail: "https://d1m0ae25uhy92x.cloudfront.net/a57029e6-9d4f-448b-aeda-9f329a8199cd/hd_img_preview.jpg",
      captions: "https://d1m0ae25uhy92x.cloudfront.net/a57029e6-9d4f-448b-aeda-9f329a8199cd/a57029e6-9d4f-448b-aeda-9f329a8199cd.vtt",
      isPortrait: true,
      duration: "18.040000",
      viewCount: 145,
    },
    {
      id: "a625215a-f8ec-4cec-ad87-22e42092cb81",
      index: 10,
      previewUrl: "https://d1m0ae25uhy92x.cloudfront.net/a625215a-f8ec-4cec-ad87-22e42092cb81/video_preview.mp4",
      hdPreviewUrl: "https://d1m0ae25uhy92x.cloudfront.net/a625215a-f8ec-4cec-ad87-22e42092cb81/hd_video_preview.mp4",
      videoUrl: "https://d1m0ae25uhy92x.cloudfront.net/a625215a-f8ec-4cec-ad87-22e42092cb81/a625215a-f8ec-4cec-ad87-22e42092cb81.m3u8",
      thumbnail: "https://d1m0ae25uhy92x.cloudfront.net/a625215a-f8ec-4cec-ad87-22e42092cb81/638f59fb-3bdc-4a2c-96f4-5c2f38f0f511_thumbnails.0000001.jpg",
      hdThumbnail: "https://d1m0ae25uhy92x.cloudfront.net/a625215a-f8ec-4cec-ad87-22e42092cb81/hd_img_preview.jpg",
      captions: "https://d1m0ae25uhy92x.cloudfront.net/a625215a-f8ec-4cec-ad87-22e42092cb81/a625215a-f8ec-4cec-ad87-22e42092cb81.vtt",
      isPortrait: true,
      duration: "18.040000",
      viewCount: 83,
    }
  ]
};

export default ganderFEWidgetPreviewData;