
export enum EOverlayOpenState {
  OVERLAY = "overlay",
  FULLSCREEN = "fullscreen",
  IN_FRAME = "in-frame",
}

export enum EOverlayStartingState {
  MUTED_GIF = "muted-gif",
  OPEN_STATE = "open-state",
}

export enum EWidgetType {
  OVERLAY = "overlay",
  EMBEDDED = "embedded",
}

export enum EEmbeddedLayout {
  HORIZONTAL_SCROLL = "horizontal-scroll",
  GRID = "grid",
}

export enum EEmbeddedPlayerStyle {
  SIMULTANEOUS = "simultaneous",
  ON_HOVER = "on-hover",
}

export enum EPlayerShape {
  PORTRAIT_LANDSCAPE = "portrait-landscape",
  SQUARE_CIRCLE = "square-circle",
}

export enum EPreviewTextPosition {
  LEFT = "left",
  CENTER = "center",
  RIGHT = "right",
}