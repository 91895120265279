import { GO_GANDER_LINK } from "../../config";
import { ReactComponent as PoweredByGanderSvg } from '../../assets/img/powered-by-gander.svg';

interface Props {
  style?: React.CSSProperties;
  dataTestId?: string;
}

const GanderLogoV2 = ({ style, dataTestId }: Props) => {
  return (
    <a className="g-gander-logo-v2" rel="noreferrer" href={GO_GANDER_LINK} target="_blank" data-testid={dataTestId}>
      <PoweredByGanderSvg />
    </a>
  );
};

export default GanderLogoV2;
