export const getItemFromLocalStorage = (key: string): any => {
  let value = localStorage.getItem(key) || "";
  if (value && (value.startsWith("[") || value.startsWith("{"))) {
    value = JSON.parse(value);
  }
  return value;
};

export const setItemToLocalStorage = (key: string, value: any): void => {
  if (typeof value === "object") {
    value = JSON.stringify(value);
  }
  localStorage.setItem(key, value);
};
