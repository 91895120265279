
import { EEmbeddedLayout, EEmbeddedPlayerStyle, EOverlayStartingState, EWidgetType, EPlayerShape, EOverlayOpenState } from "./widget-setting-v2";

//extending window object type to contain 'Shopify' with containing string 'shop' variable
declare global {
  interface Window {
    Shopify: IShopify;
    gander: IGander;
  }
}

interface IShopify {
  shop: string;
  designMode: boolean;
  currency: { active: string, rate: string | number }
}

interface IGander {
  settings: IWidgetSettings;
  videos: IVideos;
}

export interface IWidgetSettings {
  embedGridNumberOfVideos: number;
  isShowPreview: boolean;
  isAutoPlay: boolean;
  isShowVideoCaption: boolean;
  isShowVideoDisplayName: boolean;

  // new parameters
  // TODO: we will add this parameters when integrating new feature, gradually we will remove old parameters form this object
  displayBranding: boolean;
  textColor: string;
  buttonColor: string;
  previewTextContent: string;
  fullScreenIsShowAll: boolean;
  textFontSize: string;
  textAlignment: string;
  textIsShowViewCount: boolean;
  textIsShowDuration: boolean;
  openStateType: EOverlayOpenState;
  overlayWidgetSize: string;
  previewBorderColor: string;
  previewIsShowBorder: boolean;
  previewIsBorderPadding: boolean;
  previewOrientation: EPlayerShape;
  previewOverlayStartingState: EOverlayStartingState;
  previewPlayerSize: string;
  previewCornerRadius: string;
  previewEmbedGifStyle: EEmbeddedPlayerStyle;
  widgetType: EWidgetType;
  embedType: EEmbeddedLayout;
  closeBorderColor: string;
  closeCornerRadius: string;
  closeIsBorderPadding: boolean;
  closeIsShowBorder: boolean;
  leftPosition: string;
  topPosition: string;
}

export interface IVideos {
  [x: string]: IVideo[];
}

export interface IVideo {
  id: string;
  previewUrl: string;
  hdPreviewUrl: string;
  videoUrl: string;
  thumbnail: string;
  hdThumbnail: string;
  captions: string;
  isPortrait: boolean;
  index?: number;
  displayName?: string;
  interaction: IInteraction;
  products: IProductResponse[];
  duration: string;
  viewCount: number;
}

export interface IProductResponse {
  id: string;
  name: string;
  thumbnail: string;
  redirectUrl: string;
  variantId?: string;
}

export interface IVideoProgress {
  loaded: number;
  loadedSeconds: number;
  played: number;
  playedSeconds: number;
}

export interface IGlobalVideoProgress extends IVideoProgress {
  videoId: string;
}

export interface IABTestResponse {
  isABTestOn: boolean;
  isOnHoldoutGroup: boolean;
  widgetShown: boolean;
}

export interface ISettingsAndVideos {
  videos: IVideo[];
  message?: string;
  abHoldOutData: IABTestResponse;
  widgetSettings: IWidgetSettings;
}

export interface IInteraction {
  id: string;
  questions: IQuestion[];
}

export interface IQuestion {
  id: string;
  meta: string;
  choices: IChoice[];
  dataType: string;
  selectionType: EQuestionSelectionType;
  text: string;
  type: EQuestionType;
  GSI1PK: string;
  GSI1SK: string;
  createdAt: number;
  updatedAt: number;
}

export interface IChoice {
  id: string;
  text?: string;
  pictureUrl?: string;
  buttonColor?: string;
  buttonTextColor?: string;
  nextQuestionMeta?: string | typeof EChoiceNextQuestionMetaRedirect;
  redirectUrl: string;
  productUrl?: string;
}

export enum EQuestionType {
  SHORT_ANSWER = "short-answer",
  MULTIPLE_CHOICE = "multiple-choice",
  PICTURE_CHOICE = "picture-choice",
  CALL_TO_ACTION = "call-to-action",
  ADD_TO_CART = "add-to-cart",
}

export enum EQuestionSelectionType {
  SINGLE_SELECT = "single-select",
  MULTIPLE_SELECT = "multiple-select",
}

export enum EOverridePreviewState {
  FULLSCREEN = "fullscreen",
  OVERLAY = "overlay",
  PREVIEW_OVERLAY = "preview-overlay",
  CLOSE_STATE = "close-state",
}

export const EChoiceNextQuestionMetaRedirect = "redirect";

export interface IShopifyProductVariantOptions {
  name: string;
  position: number;
  values: string[];
}

export interface IShopifyProductResponse {
  id: string | number;
  title: string;
  handle: string;
  vendor: string;
  price: number;
  available: boolean;
  price_varies: boolean;
  compare_at_price: number;
  variants: IShopifyProductVariant[];
  featured_image: string;
  options: IShopifyProductVariantOptions[];
  url: string;
  images: string[];
}

export interface IShopifyProductVariant {
  id: number;
  title: string;
  option1: string;
  available: boolean;
  name: string;
  options: string[];
  price: number;
  compare_at_price: null;
  featured_image: { src: string } | null;
}

export interface IShopifyGIDdecode {
  type: string;
  id: string;
  params?: string;
  raw?: string;
}