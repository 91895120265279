import { useCallback, useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import CallToActionQuestion from "./call-to-action-question";
import MultipleChoiceQuestion from "./multiple-choice-question";
import PictureChoiceQuestion from "./picture-choice-question";
import ShortAnswerQuestion from "./short-answer-question";
import {
  sendInteractionResponseEvent,
  sendQuestionResponseEvent,
} from "../../utils/elastic-events";
import {
  EChoiceNextQuestionMetaRedirect,
  EQuestionType,
  IChoice,
  IInteraction,
  IQuestion,
  IVideo
} from "../../interfaces";
import {
  EInteractionResponseStatus,
  EQuestionResponseStatus,
} from "../../interfaces/elastic-events";
import AddToCartQuestion from "./add-to-cart-question";
import { getValidUrl } from "../../utils/common-functions";

import "./index.scss";

const isLastQuestion = (question: IQuestion, interaction: IInteraction) => {
  const lastQuestion = interaction.questions.at(-1);
  return question.id === lastQuestion?.id;
};

interface IProps {
  interaction: IInteraction;
  video: IVideo;
  fullscreen: boolean;
  setCurrentQuestionType?: React.Dispatch<React.SetStateAction<EQuestionType | undefined>>;
  onClickAddToCartQuestion?: (
    question: IQuestion,
    questionResponseId: string | undefined,
    handleNextQuestion: (question: IQuestion, choice?: IChoice) => Promise<void>,
    handleSendQuestionResponseEvent: (questionResponseId: string, question: IQuestion, status: EQuestionResponseStatus, choiceIds?: string[], userInput?: string) => Promise<void>,
  ) => void | undefined;
  dataTestId?: string;
}

const InteractionContainer = ({ interaction, video, fullscreen, setCurrentQuestionType, onClickAddToCartQuestion, dataTestId }: IProps) => {
  const [activeQuestion, setActiveQuestion] = useState<IQuestion>();
  const [interactionResponseId, setInteractionResponseId] = useState<string>();
  const [isStarted, setIsStarted] = useState<boolean>(false);

  useEffect(() => {
    if (interaction?.questions?.length > 0) {
      const uuid = uuidv4();
      setInteractionResponseId(uuid);
      setActiveQuestion(interaction.questions[0]);
      setCurrentQuestionType && setCurrentQuestionType(interaction.questions[0].type);
      handleSendInteractionResponseEvent(EInteractionResponseStatus.VIEWED);
    }
    const uuid = uuidv4();
    setInteractionResponseId(uuid);
  }, []);

  const handleSendInteractionResponseEvent = async (status: EInteractionResponseStatus) => {
    if (interactionResponseId) {
      await sendInteractionResponseEvent({
        interactionResponseId,
        interactionId: interaction.id,
        videoId: video.id,
        status,
      });
    }
  };

  const handleSendQuestionResponseEvent = useCallback(
    async (
      questionResponseId: string,
      question: IQuestion,
      status: EQuestionResponseStatus,
      choiceIds?: string[],
      userInput?: string
    ) => {
      if (interactionResponseId) {
        await sendQuestionResponseEvent({
          questionResponseId,
          interactionResponseId,
          questionId: question.id,
          interactionId: interaction.id,
          status,
          type: question?.type,
          choiceIds,
          userInput,
        });
      }
    },
    [interactionResponseId]
  );

  const handleNextQuestion = useCallback(
    async (question: IQuestion, choice?: IChoice) => {
      if (!isStarted) {
        await handleSendInteractionResponseEvent(EInteractionResponseStatus.STARTED);
        setIsStarted(true);
      }

      const questionIds = interaction.questions.map(({ id }) => id);

      if (question.type === EQuestionType.ADD_TO_CART) {
        await handleSendInteractionResponseEvent(EInteractionResponseStatus.SUBMITTED);
        const url = window.location.origin + '/cart';
        window.open(getValidUrl(url), "_self");

      } else if (choice?.nextQuestionMeta === EChoiceNextQuestionMetaRedirect) {
        await handleSendInteractionResponseEvent(
          EInteractionResponseStatus.SUBMITTED
        );
      } else if (
        choice?.nextQuestionMeta &&
        questionIds.includes(choice.nextQuestionMeta)
      ) {
        const nextQuestion = interaction.questions.find(
          ({ id }) => id === choice.nextQuestionMeta
        );
        setActiveQuestion(nextQuestion);
        setCurrentQuestionType && setCurrentQuestionType(nextQuestion?.type);
      } else {
        if (isLastQuestion(question, interaction)) {
          handleSendInteractionResponseEvent(
            EInteractionResponseStatus.SUBMITTED
          );
          setActiveQuestion(undefined);
          setCurrentQuestionType && setCurrentQuestionType(undefined);
        } else {
          const activeQuestionIndex = interaction.questions.findIndex(
            ({ id }) => id === question.id
          );
          setActiveQuestion(interaction.questions[activeQuestionIndex + 1]);
          setCurrentQuestionType && setCurrentQuestionType(interaction.questions[activeQuestionIndex + 1].type);
        }
      }
    },
    [
      interaction,
      isStarted,
      handleSendInteractionResponseEvent,
      setActiveQuestion,
    ]
  );

  const renderQuestion = useCallback(() => {
    switch (activeQuestion?.type) {
      case EQuestionType.CALL_TO_ACTION:
        return (
          <CallToActionQuestion
            interactionQuestion={activeQuestion}
            isFullscreen={fullscreen}
            handleSendQuestionResponseEvent={handleSendQuestionResponseEvent}
            handleNextQuestion={handleNextQuestion}
            dataTestId={`${dataTestId}-CallToActionQuestion`}
          />
        );
      case EQuestionType.ADD_TO_CART:
        return (
          <AddToCartQuestion
            interactionQuestion={activeQuestion}
            isFullscreen={fullscreen}
            handleSendQuestionResponseEvent={handleSendQuestionResponseEvent}
            handleNextQuestion={handleNextQuestion}
            onClickAddToCartQuestion={onClickAddToCartQuestion}
            dataTestId={`${dataTestId}-AddToCartQuestion`}
          />
        );
      case EQuestionType.SHORT_ANSWER:
        return (
          <ShortAnswerQuestion
            interactionQuestion={activeQuestion}
            isFullscreen={fullscreen}
            handleSendQuestionResponseEvent={handleSendQuestionResponseEvent}
            handleNextQuestion={handleNextQuestion}
            dataTestId={`${dataTestId}-ShortAnswerQuestion`}
          />
        );
      case EQuestionType.MULTIPLE_CHOICE:
        return (
          <MultipleChoiceQuestion
            interactionQuestion={activeQuestion}
            isFullscreen={fullscreen}
            handleSendQuestionResponseEvent={handleSendQuestionResponseEvent}
            handleNextQuestion={handleNextQuestion}
            dataTestId={`${dataTestId}-MultipleChoiceQuestion`}
          />
        );
      case EQuestionType.PICTURE_CHOICE:
        return (
          <PictureChoiceQuestion
            interactionQuestion={activeQuestion}
            isFullscreen={fullscreen}
            handleSendQuestionResponseEvent={handleSendQuestionResponseEvent}
            handleNextQuestion={handleNextQuestion}
            dataTestId={`${dataTestId}-PictureChoiceQuestion`}
          />
        );
      default:
        return null;
    }
  }, [activeQuestion]);

  return <div className="g-interaction-container" data-testid={dataTestId}>{renderQuestion()}</div>;
};

export default InteractionContainer;
