import { Suspense, useContext, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { IChoice, IGlobalVideoProgress, IProductResponse, IQuestion, IVideo } from '../../interfaces';
import AddToCartContainer from "../add-to-cart-container";
import { EQuestionResponseStatus } from "../../interfaces/elastic-events";
import { AppContext } from "../../context/app-context";
import VideoCarousel from "components/video-player/carousel";
import CloseButtonWithOverlay from "components/video-player/video-controls/buttons/CloseButtonWithOverlay";
import { EOverlayOpenState, EOverlayStartingState, EWidgetType } from "interfaces/widget-setting-v2";
import { apiGetFullScreenVideos, getAvailableVideosOnS3 } from "services/common.service";
import { LOCATION_PATH_NAME, SHOP_NAME } from "config";

import './index.scss';

const dataTestIdPrefix = "FullScreenModeComponent";

interface IAddToCartFuncProps {
    question: IQuestion,
    questionResponseId: string | undefined,
    handleNextQuestion: (question: IQuestion, choice?: IChoice) => Promise<void>,
    handleSendQuestionResponseEvent: (questionResponseId: string, question: IQuestion, status: EQuestionResponseStatus, choiceIds?: string[], userInput?: string) => Promise<void>,
}

type Props = {
    ganderVideos: IVideo[];
    currentVideo: IVideo;
    toggleFullScreen: () => void;
    resetVideo: () => void;
    setGlobalVideoProgress: React.Dispatch<React.SetStateAction<IGlobalVideoProgress>>;
    globalVideoProgress: IGlobalVideoProgress;
};

const bodyDOM = document.getElementsByTagName('body')[0];
const htmlTag = document.getElementsByTagName('html')[0];

const FullscreenModeComponent = ({
    ganderVideos,
    toggleFullScreen,
    resetVideo,
    setGlobalVideoProgress,
    globalVideoProgress,
}: Props) => {
    const { isMobile, isPreviewMode, widgetSettings, setSelectedVideoObj } = useContext(AppContext);
    const [carouselVideos, setCarouselVideos] = useState<IVideo[]>(ganderVideos);
    const [activeVideoIndex, setActiveVideoIndex] = useState(0);
    const [activeVideo, setActiveVideo] = useState<IVideo>(carouselVideos[activeVideoIndex]);
    const [selectedProduct, setSelectedProduct] = useState<IProductResponse>();
    const [addToCartProps, setAddToCartProps] = useState<IAddToCartFuncProps>();

    useEffect(() => {
        bodyDOM.classList.add('gander-full-screen-on');
        // this is to show fullscreen on customization page on preview mode

        if (isPreviewMode) {
            if (isMobile) {
                (htmlTag.style as any).zoom = 1;
            } else {
                (htmlTag.style as any).zoom = 0.8;
            }
        }
        return () => {
            bodyDOM.classList.remove('gander-full-screen-on');
            if (isPreviewMode) {
                (htmlTag.style as any).zoom = 0.8;
            }
        };
    }, []);

    useEffect(() => {
        if (!isPreviewMode && widgetSettings?.fullScreenIsShowAll) {
            const currentPageVideoMetas = ganderVideos.map(({ id }) => id);
            fetchOtherVideos(currentPageVideoMetas);
        }
    }, []);

    const fetchOtherVideos = async (currentPageVideoMetas: string[]) => {
        let otherVideos = await apiGetFullScreenVideos(SHOP_NAME, LOCATION_PATH_NAME(), currentPageVideoMetas);
        if (otherVideos) {
            otherVideos = await getAvailableVideosOnS3(otherVideos);
            setCarouselVideos([...ganderVideos, ...otherVideos]);
        }
    };

    const onClickProductIcon = (product: IProductResponse) => {
        setAddToCartProps(undefined);
        setSelectedProduct(product);
    }

    const onCloseAddToCart = () => {
        setSelectedProduct(undefined);
        setAddToCartProps(undefined);
    }

    const onClickAddToCartQuestion = (
        question: IQuestion,
        questionResponseId: string | undefined,
        handleNextQuestion: (question: IQuestion, choice?: IChoice) => Promise<void>,
        handleSendQuestionResponseEvent: (questionResponseId: string, question: IQuestion, status: EQuestionResponseStatus, choiceIds?: string[], userInput?: string) => Promise<void>,
    ) => {
        setSelectedProduct(undefined);
        setAddToCartProps({ question, questionResponseId, handleNextQuestion, handleSendQuestionResponseEvent });
    }

    const handleMinimize = (video: IVideo) => {
        const isCurrentPageVideo = Boolean(ganderVideos.find(ganderVideo => ganderVideo.id === video.id));

        const startingStateIsPreview = widgetSettings?.previewOverlayStartingState === EOverlayStartingState.MUTED_GIF;
        const openStateIsFullscreen = widgetSettings?.openStateType === EOverlayOpenState.FULLSCREEN;

        if (widgetSettings?.openStateType === EOverlayOpenState.IN_FRAME) {
            setSelectedVideoObj(undefined);
        } else if (isCurrentPageVideo && (widgetSettings?.widgetType === EWidgetType.EMBEDDED || !(startingStateIsPreview && openStateIsFullscreen))) {
            setSelectedVideoObj(video);
        } else {
            resetVideo();
        }
    };

    const handleCloseButtonWithOverlayClick = () => {
        handleMinimize(activeVideo);
        toggleFullScreen();
    }

    return ReactDOM.createPortal((
        <div className="gander-full-screen-container" data-testid={`${dataTestIdPrefix}-Fullscreen-Main-Container`}>
            {/* This is to open product details bar from interaction */}
            <div className="gander-mask" data-testid={`${dataTestIdPrefix}-Fullscreen-Mask-Container`} />
            <div className="gander-content">
                {!isMobile && (
                    <div className="gander-close-btn-with-overlay-container">
                        <CloseButtonWithOverlay handleClick={handleCloseButtonWithOverlayClick}  />
                    </div>
                )}
                <div className="gander-overlay-content-container">
                    <div className="gander-video-carousel-container" data-testid={`${dataTestIdPrefix}-Fullscreen-Mode`}>
                        <Suspense fallback={<div></div>}>
                            <VideoCarousel
                                carouselVideos={carouselVideos}
                                activeVideoIndex={activeVideoIndex}
                                globalVideoProgress={globalVideoProgress}
                                activeVideo={activeVideo}
                                setActiveVideo={setActiveVideo}
                                setActiveVideoIndex={setActiveVideoIndex}
                                toggleFullScreen={toggleFullScreen}
                                onClickProductIcon={onClickProductIcon}
                                onClickAddToCartQuestion={onClickAddToCartQuestion}
                                setGlobalVideoProgress={setGlobalVideoProgress}
                                handleMinimize={handleMinimize}
                            />
                        </Suspense>
                    </div>
                </div>
                {(selectedProduct || addToCartProps?.question) &&
                    <div className="gander-product-sidebar">
                        <AddToCartContainer
                            isFullscreen={true}
                            productObj={selectedProduct}
                            interactionQuestion={addToCartProps?.question}
                            type="standalone"
                            onCloseAddToCart={onCloseAddToCart}
                            propResponseId={addToCartProps?.questionResponseId}
                            callBackNextQuestion={addToCartProps?.handleNextQuestion}
                            callBackSendQuestionResponse={addToCartProps?.handleSendQuestionResponseEvent}
                        />
                    </div>
                }
            </div>
        </div>
    ), bodyDOM);
};

export default FullscreenModeComponent;