import { lazy, useContext } from 'react'
import OverlayModeWidgetComponent from '../overlay-mode-widget-component';
import { AppContext } from '../../context/app-context';
import { EWidgetType } from 'interfaces/widget-setting-v2';

const ProductPageWidget = lazy(() => import('../product-page-widget'));
const GlobalPagesWidget = lazy(() => import('../global-pages-widget'));

const WidgetCustomizationPreviewScreen = () => {
    const { previewModeSettings } = useContext(AppContext);

    return (
        <>
            {previewModeSettings?.widgetType === EWidgetType.EMBEDDED ? (
                <div className="gander-global-embedded-container">
                    <div className="gander-global-embedded-wrapper">
                        <div className="gander-global-embedded-left-container">
                            &nbsp;
                        </div>
                        <div className="gander-global-embedded-right-container">
                            <div className="gander-global-embedded-title-skeleton">
                                &nbsp;
                            </div>
                            <div className="gander-global-embedded-price-skeleton">
                                &nbsp;
                            </div>
                            <div className="gander-global-embedded-widget-skeleton">
                                <ProductPageWidget />
                                <OverlayModeWidgetComponent />
                            </div>
                            <div className="gander-global-embedded-details-skeleton-container">
                                <div className="gander-global-embedded-details-skeleton">
                                    &nbsp;
                                </div>
                            </div>
                            <div className="gander-global-embedded-extra-details-mobile-skeleton">
                                <div className="gander-global-embedded-extra-details-mobile-dots-title"></div>
                                <div className="gander-global-embedded-extra-details-mobile-dots-container">
                                    <div className="gander-global-embedded-extra-details-mobile-dots">
                                        &nbsp;
                                    </div>
                                    <div className="gander-global-embedded-extra-details-mobile-dots">
                                        &nbsp;
                                    </div>
                                    <div className="gander-global-embedded-extra-details-mobile-dots">
                                        &nbsp;
                                    </div>
                                    <div className="gander-global-embedded-extra-details-mobile-dots">
                                        &nbsp;
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="gander-global-embedded-extra-details-container">
                        &nbsp;
                    </div>
                </div>
            ) : (
                <div className="gander-global-preview-container-skeleton">
                    <GlobalPagesWidget />
                </div>
            )}
        </>
    )
}

export default WidgetCustomizationPreviewScreen;