type Props = {
  handleClick: () => void;
};

const dataTestIdPrefix = "CloseButtonWithOverlayComponent";

const CloseButtonWithOverlay = ({ handleClick }: Props) => {
  return (
    <div 
      className="g-close-btn-with-overlay" 
      onClick={handleClick} 
      data-testid={`${dataTestIdPrefix}-Close-Button-With-Overlay`}
    >
      <svg width="56" height="57" viewBox="0 0 56 57" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_b_2110_7523)">
          <rect className="gander-button-overlay" y="0.5" width="56" height="56" rx="28" fill="black" fillOpacity="0.16" />
        </g>
        <path d="M23.7925 22.8076C23.3825 22.3975 22.7176 22.3975 22.3076 22.8076C21.8975 23.2176 21.8975 23.8825 22.3076 24.2925L26.515 28.5L22.3076 32.7075C21.8975 33.1175 21.8975 33.7824 22.3076 34.1924C22.7176 34.6025 23.3825 34.6025 23.7925 34.1924L28 29.985L32.2075 34.1924C32.6175 34.6025 33.2824 34.6025 33.6924 34.1924C34.1025 33.7824 34.1025 33.1175 33.6924 32.7075L29.485 28.5L33.6924 24.2925C34.1025 23.8825 34.1025 23.2176 33.6924 22.8076C33.2824 22.3975 32.6175 22.3975 32.2075 22.8076L28 27.015L23.7925 22.8076Z" fill="white" />
        <defs>
          <filter id="filter0_b_2110_7523" x="-36.6298" y="-36.1298" width="129.26" height="129.26" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feGaussianBlur in="BackgroundImageFix" stdDeviation="18.3149" />
            <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_2110_7523" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_2110_7523" result="shape" />
          </filter>
        </defs>
      </svg>

    </div>
  );
};

export default CloseButtonWithOverlay;