import React, { createContext, useEffect, useState } from "react";
import {
  applyPreviewSettingsToInteraction,
  getInstanceElement,
  getGanderFEWidgetPreviewData,
  getQueryStringParams
} from "../utils/common-functions";
import { EOverridePreviewState, IABTestResponse, ISettingsAndVideos, IVideo, IWidgetSettings } from "../interfaces";
import { AB_TEST_DEFAULT_VALUES, APP_VERSION, DEFAULT_SETTINGS, DEFAULT_SETTINGS_AND_VIDEOS, LOCATION_PATH_NAME, SHOP_NAME } from '../config';
import { apiGetVideoData, apiGetWidgetData, getAvailableVideosOnS3 } from "../services/common.service";
import logger from "../utils/logger";
import { sendImpressionEventWithAbTestData, sendProductCategoryImpressionEvent, sendVisitCategoryImpressionEvent } from "../utils/elastic-events";
import { EOverlayStartingState, EWidgetType } from "../interfaces/widget-setting-v2";
import useLdFlags from "utils/useLdFlags";

export interface IAppContext {
  isMobile: boolean;
  isEmbedProductPage: boolean;
  isPreviewMode: boolean;
  previewModeSettings: IWidgetSettings;
  isSharedFullScreen: boolean;
  widgetSettings: IWidgetSettings;
  ganderVideos: IVideo[];
  abTestData: IABTestResponse;
  isShowDisplayNames: boolean;
  selectedVideoObj: IVideo | undefined;
  setSelectedVideoObj: React.Dispatch<React.SetStateAction<IVideo | undefined>>;
  initialFullScreenValue: boolean;
  setInitialFullScreenValue: React.Dispatch<React.SetStateAction<boolean>>;
  currentPlayingVideoIndexForPlayOnHover: number;
  setCurrentPlayingVideoIndexForPlayOnHover: React.Dispatch<React.SetStateAction<number>>;
  overridePreviewState: EOverridePreviewState | undefined;
  isMuted: boolean;
  setIsMuted: React.Dispatch<React.SetStateAction<boolean | undefined>>;
}

const defaultContext = {
  isMobile: false,
  isEmbedProductPage: false,
  isPreviewMode: false,
  previewModeSettings: { ...DEFAULT_SETTINGS },
  isSharedFullScreen: false,
  widgetSettings: { ...DEFAULT_SETTINGS },
  ganderVideos: [],
  abTestData: AB_TEST_DEFAULT_VALUES,
  isShowDisplayNames: true,
  selectedVideoObj: undefined,
  setSelectedVideoObj: () => { },
  initialFullScreenValue: false,
  setInitialFullScreenValue: () => { },
  currentPlayingVideoIndexForPlayOnHover: 0,
  setCurrentPlayingVideoIndexForPlayOnHover: () => { },
  overridePreviewState: undefined,
  isMuted: false,
  setIsMuted: () => { },
};

export const AppContext = createContext<IAppContext>(defaultContext);

export const AppContextProvider: React.FC<{ initialValues?: Partial<IAppContext>, children: React.ReactNode }> = ({ initialValues, children }) => {
  const { featureInitialVideoMuted, disableOverlayFallback, dataFetchV2 } = useLdFlags(['featureInitialVideoMuted', 'disableOverlayFallback', 'dataFetchV2']);
  logger("🚀 ~ file: app-context.tsx ~ featureInitialVideoMuted", featureInitialVideoMuted);
  const [isMobile, setIsMobile] = useState(initialValues?.isMobile ?? !window.matchMedia("screen and (min-width: 576px)").matches);
  const [isEmbedProductPage, setIsEmbedProductPage] = useState(initialValues?.isEmbedProductPage ?? false);
  const [isPreviewMode, setIsPreviewMode] = useState(initialValues?.isPreviewMode ?? false);
  const [isSharedFullScreen, setIsSharedFullScreen] = useState(initialValues?.isSharedFullScreen ?? false);
  const [ganderVideos, setGanderVideos] = useState<Array<IVideo>>(initialValues?.ganderVideos ?? []);
  const [isShowDisplayNames, setIsShowDisplayNames] = useState(initialValues?.isShowDisplayNames ?? true);
  const [selectedVideoObj, setSelectedVideoObj] = useState<IVideo | undefined>(initialValues?.selectedVideoObj ?? undefined);
  const [initialFullScreenValue, setInitialFullScreenValue] = useState(initialValues?.initialFullScreenValue ?? false);
  const [abTestData, setAbTestData] = useState<IABTestResponse>(initialValues?.abTestData ?? AB_TEST_DEFAULT_VALUES)
  const [previewModeSettings, setPreviewModeSettings] = useState<IWidgetSettings>(initialValues?.previewModeSettings ?? DEFAULT_SETTINGS);
  const [widgetSettings, setWidgetSettings] = useState<IWidgetSettings>(initialValues?.widgetSettings ?? DEFAULT_SETTINGS);
  const [overridePreviewState, setOverridePreviewState] = useState(initialValues?.overridePreviewState ?? undefined);
  const [currentPlayingVideoIndexForPlayOnHover, setCurrentPlayingVideoIndexForPlayOnHover] = useState(initialValues?.currentPlayingVideoIndexForPlayOnHover ?? 0);
  const [isMuted, setIsMuted] = useState<boolean | undefined>(initialValues?.isMuted ?? undefined);
  const [settingsAndVideos, setSettingsAndVideos] = useState<ISettingsAndVideos | undefined>(undefined);


  // Holding app init until we have all required flags values, to avoid flickering
  useEffect(() => {
    setIsMuted(featureInitialVideoMuted);
  }, [featureInitialVideoMuted]);

  useEffect(() => {
    if (dataFetchV2 === undefined) {
      return;
    }
    // will trigger when window size changes
    window.onresize = () => {
      setIsMobile(!window.matchMedia("screen and (min-width: 576px)").matches);
    };

    const queryParams = getQueryStringParams();
    const queryParamParsedSettings = queryParams?.settings && JSON.parse(queryParams?.settings);
    const previewModeSettingsFromQueryParams = queryParamParsedSettings;
    const isPrevMode = queryParams?.isPreviewMode === 'true';

    setIsPreviewMode(isPrevMode);
    if (isPrevMode && queryParams.overridePreviewState) {
      setOverridePreviewState(queryParams.overridePreviewState);
    }
    setPreviewModeSettings(previewModeSettingsFromQueryParams);
    setIsSharedFullScreen(queryParams?.isSharedFullScreen === "true" || queryParams.overridePreviewState === EOverridePreviewState.FULLSCREEN);

    if (previewModeSettingsFromQueryParams && isPrevMode) {
      setSettingsAndVideos({ ...DEFAULT_SETTINGS_AND_VIDEOS });
    } else {
      fetchSettingsAndVideos(SHOP_NAME, LOCATION_PATH_NAME(), queryParams?.isSharedFullScreen === "true");
    }
  }, [dataFetchV2]);

  useEffect(() => {
    const singleInstance = getInstanceElement();

    if (isPreviewMode && previewModeSettings) {
      const isProductWidget = !!(singleInstance && previewModeSettings?.widgetType === EWidgetType.EMBEDDED);  // True - Product Widget, False - Global Widget
      initGanderFEWidgetPreview(isProductWidget);
      setIsEmbedProductPage(isProductWidget);
      console.log(`%cPREVIEW MODE : GoGander ${isProductWidget ? 'Product' : 'Global'} Widget v${APP_VERSION}`, "background: green; color: lightyellow;font-size: 15px", previewModeSettings);

    } else if (settingsAndVideos) {
      if (disableOverlayFallback && settingsAndVideos?.widgetSettings?.widgetType === EWidgetType.EMBEDDED && !singleInstance) {
        return;
      }
      const isLoadEmbedWidget = !!(singleInstance && settingsAndVideos?.widgetSettings?.widgetType === EWidgetType.EMBEDDED);
      setIsEmbedProductPage(isLoadEmbedWidget);
      isLoadEmbedWidget ? initWidget(false) : initWidget(true);
      console.log(`%c GoGander ${isLoadEmbedWidget ? 'Product' : 'Global'}  Widget v ${APP_VERSION}`, "background: green; color: lightyellow;font-size: 15px");
    }
  }, [settingsAndVideos, isPreviewMode]);

  const initGanderFEWidgetPreview = (isProduct: boolean) => {
    const ganderFEWidgetPreviewData = getGanderFEWidgetPreviewData();    
    const videos = ganderFEWidgetPreviewData?.videos as IVideo[];
    applyPreviewSettingsToInteraction(videos?.[0], previewModeSettings);

    setWidgetSettings(previewModeSettings);

    setGanderVideos(videos);

    if (isSharedFullScreen && videos?.length > 0) {
      setInitialFullScreenValue(true);
      setSelectedVideoObj(videos[0]);
    }
    if (overridePreviewState === EOverridePreviewState.OVERLAY && videos?.length > 0) {
      setSelectedVideoObj(videos[0]);
    }

    if (!isProduct) {
      setSelectedVideoObj(videos.length === 0 ? undefined : videos[0]);
    }
  }

  const initWidget = async (isGlobalPageWidget: boolean) => {
    const allVideos = await setVideosAndSettingValues(isGlobalPageWidget);
    const videoLength = settingsAndVideos?.videos?.length || 0;

    const abTestRes = settingsAndVideos?.abHoldOutData!;
    // adding condition on widget shown if video are linked
    if (videoLength === 0 || !(videoLength)) {
      abTestRes.widgetShown = false;
      logger('Videos not found so setting widget shown false', abTestRes);
    }
    setAbTestData(abTestRes);
    sendVisitCategoryImpressionEvent(abTestRes.widgetShown);
    // init event (send when not showing widget)
    sendImpressionEventWithAbTestData(videoLength > 0 ? "true" : "false", abTestRes);
    logger("🚀 ~ file: app-context.tsx ~ initWidget klara_product", videoLength > 0 ? "true" : "false");
    if (isSharedFullScreen && allVideos?.length > 0) {
      setInitialFullScreenValue(true);
      setSelectedVideoObj(allVideos[0]);
    }

    if (!isGlobalPageWidget) {
      sendProductCategoryImpressionEvent(abTestRes.widgetShown);
    }
  }

  const setVideosAndSettingValues = async (isGlobalPageWidget: boolean) => {
    let allVideos = [];
    logger('using data from api', settingsAndVideos);

    allVideos = await getAvailableVideosOnS3(settingsAndVideos?.videos!);
    let isDisplayName = false;
    allVideos = allVideos?.map((video: IVideo, index: number) => {
      if (video?.displayName && video?.displayName !== "") {
        isDisplayName = true;
      }
      video.index = index;
      return video;
    });
    setIsShowDisplayNames(isShowDisplayNames && isDisplayName);
    setGanderVideos(allVideos);

    const settings = {
      ...DEFAULT_SETTINGS,
      ...settingsAndVideos?.widgetSettings
    };

    setSelectedVideoObj(isGlobalPageWidget ? allVideos[0] : undefined);
    setWidgetSettings(settings);
    logger('widgetSettings from GET api =>', settings);
    logger('setting videos from get api : ', allVideos);
    return allVideos;
  }

  const fetchSettingsAndVideos = async (hostName: string, pathName: string, isSharedFullScreen: boolean) => {
    const resSettingsAndVideos = await apiGetWidgetData(hostName, pathName, isMobile);
    if (dataFetchV2) {
      const singleInstance = getInstanceElement();
      const isEmbeddedPlayer = !!(singleInstance && previewModeSettings?.widgetType === EWidgetType.EMBEDDED);
      const isOpenStateStartingState = !isEmbeddedPlayer && resSettingsAndVideos?.widgetSettings?.previewOverlayStartingState === EOverlayStartingState.OPEN_STATE;
      
      if (!isSharedFullScreen && !isOpenStateStartingState) {
        setSettingsAndVideos(resSettingsAndVideos);
      }
      
      if (resSettingsAndVideos?.videos && resSettingsAndVideos.videos.length > 0) {
        const videoMetas = resSettingsAndVideos.videos.map(({id}) => id);
        const videos = await apiGetVideoData(hostName, pathName, videoMetas);
        setSettingsAndVideos({
          ...resSettingsAndVideos,
          videos: videos!,
        });
      }
    } else {
      setSettingsAndVideos(resSettingsAndVideos);
    }
  };

  if (!settingsAndVideos) return null;

  return (
    <>
      {isMuted !== undefined &&
        <AppContext.Provider
          value={{
            isMobile,
            isEmbedProductPage,
            isPreviewMode,
            previewModeSettings,
            isSharedFullScreen,
            widgetSettings,
            ganderVideos,
            abTestData,
            isShowDisplayNames,
            selectedVideoObj,
            setSelectedVideoObj,
            initialFullScreenValue,
            setInitialFullScreenValue,
            currentPlayingVideoIndexForPlayOnHover,
            setCurrentPlayingVideoIndexForPlayOnHover,
            overridePreviewState,
            isMuted,
            setIsMuted,
          }}
        >
          {children}
        </AppContext.Provider>
      }
    </>
  );
};
