import VideoTimeAndDurationComponent from 'components/video-time-and-duration-component';
import { GANDER_LOADER_VIDEO, GANDER_NON_BRANDING_LOADER_VIDEO } from 'config';
import { AppContext } from 'context/app-context';
import { IVideo } from 'interfaces';
import { EEmbeddedPlayerStyle } from 'interfaces/widget-setting-v2';
import { CSSProperties, useContext } from 'react';
import { getPreviewVideoUrlBasedOnScreenSize } from 'utils/common-functions';

interface IProps {
    onPreviewClick: any;
    previewVideoTag: any;
    video: IVideo;
    indexId: number;
    videoStyle: CSSProperties;
}

const dataTestIdPrefix = "ProductPageWidgetPreviewComponent";

const EmbeddedModePreviewStateComponent = ({ onPreviewClick, previewVideoTag, video, indexId, videoStyle }: IProps) => {
    const { widgetSettings, isShowDisplayNames, setCurrentPlayingVideoIndexForPlayOnHover } = useContext(AppContext);
    const isAllowSimultaneousPlay = widgetSettings?.previewEmbedGifStyle === EEmbeddedPlayerStyle.SIMULTANEOUS;

    const previewVideoClick = (video: IVideo) => {
        previewVideoTag.current?.pause();
        onPreviewClick(video);
    };

    return (
        <>
            <div className="gander-preview-item-video-gif-container">
                <video className="gander-preview-item-video-gif" playsInline autoPlay muted loop crossOrigin=""
                    src={
                        widgetSettings.displayBranding
                            ? GANDER_LOADER_VIDEO
                            : GANDER_NON_BRANDING_LOADER_VIDEO
                    }
                />
            </div>

            <video
                style={videoStyle}
                data-testid={`${dataTestIdPrefix}-Preview-Video`}
                src={getPreviewVideoUrlBasedOnScreenSize(widgetSettings, video)}
                ref={previewVideoTag}
                className={`gander-preview-item-video`}
                onClick={() => previewVideoClick(video)}
                autoPlay={isAllowSimultaneousPlay}
                onMouseEnter={() => setCurrentPlayingVideoIndexForPlayOnHover(indexId)}
                crossOrigin={""}
                playsInline
                muted
                loop
            />

            <VideoTimeAndDurationComponent
                widgetModeClassName={"gander-embedded-video-duration"}
                selectedVideoObj={video}
                mainContainerStyle={{ bottom: "2px" }}
                containerStyle={{ padding: "0 7px 0 7px" }}
            />

            {widgetSettings?.isShowVideoDisplayName && isShowDisplayNames && (
                <span className="gander-preview-item-display-name" title={video?.displayName}>
                    {video?.displayName ? video?.displayName : <>&nbsp;</>}
                </span>
            )}
        </>
    )
}

export default EmbeddedModePreviewStateComponent;