import ReactDOM from 'react-dom/client';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import App from './App';
import { getInstanceElement, getQueryStringParams } from './utils/common-functions';
import logger from './utils/logger';
import { SHOP_NAME } from './config';
import { registerAddToCartListener } from './services/add-to-cart.service';
import { AppContextProvider } from "./context/app-context";

import './index.scss';

declare var window: any;
let ganderWidgetReactRoot: any = '';

async function initGanderWidgetReact() {
    if (ganderWidgetReactRoot) {
        ganderWidgetReactRoot.unmount();
    }
    const queryParams = getQueryStringParams();
    const previewShopName = queryParams?.shopUrl;

    const LDProvider = await asyncWithLDProvider({
        clientSideID: process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_SIDE_ID || '',
        user: {
            key: "shop",
            email: queryParams?.isPreviewMode ? previewShopName : SHOP_NAME
        },
        options: {},
    });

    const singleInstance = getInstanceElement();

    if (singleInstance) {
        ganderWidgetReactRoot = ReactDOM.createRoot(singleInstance as HTMLElement);
    } else {
        const absoluteElement = document.createElement('div');
        absoluteElement.setAttribute('class', 'gander-absolute-widget');
        document.body.appendChild(absoluteElement);

        const absoluteElementFromDom = getInstanceElement("gander-absolute-widget");
        ganderWidgetReactRoot = ReactDOM.createRoot(absoluteElementFromDom as HTMLElement);
    }

    ganderWidgetReactRoot.render(
        // NOTE: Do not use strict mode for now as react-player will stop giving onProgress events
        <LDProvider>
            <AppContextProvider>
                <App />
            </AppContextProvider>
        </LDProvider>
    );
}

let oldPathName = '';
(function (history: any) {
    var pushState = history.pushState;
    history.pushState = function (state: any) {
        if (typeof history.onpushstate == "function") {
            history.onpushstate({ state: state });
        }
        setTimeout(() => {
            if (oldPathName !== window.location.pathname) {
                if (ganderWidgetReactRoot) {
                    ganderWidgetReactRoot.unmount();
                    //clean up for global absolute widget div in body
                    const absoluteElementFromDom = getInstanceElement("gander-absolute-widget");
                    if (absoluteElementFromDom) {
                        absoluteElementFromDom?.remove();
                    }
                }
                initGanderWidgetReact();
                logger('window.location 2 => ', window.location.pathname);
                oldPathName = window.location.pathname;
            }
        }, 100);
        // logger("🚀 ~ file: index.tsx ~ line 36 ~ arguments", arguments[0], window.location.pathname);
        return pushState.apply(history, arguments);
    };
})(window.history);

if (window) {
    window.initGanderWidgetReact = initGanderWidgetReact;
}

initGanderWidgetReact();

registerAddToCartListener();


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
