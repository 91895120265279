import { CSSProperties, useContext, useRef, useState } from 'react'
import { EMPTY_PROGRESS, GANDER_LOADER_VIDEO, GANDER_NON_BRANDING_LOADER_VIDEO } from 'config';
import { VideoStartType } from 'constants/video_start_type';
import { AppContext } from 'context/app-context';
import { IProductResponse, IVideo, IVideoProgress } from 'interfaces';
import { updateWidgetShownAndLastSeenProduct } from 'utils/common-functions';
import { sendVideoStartEvent, sendVideoStopEvent, sendVideosPauseEvent } from 'utils/elastic-events';
import GanderPlayer from 'components/video-player/gander-player';
import TimeBar from 'components/video-player/video-controls/time-bar';
import ProductsContainer from 'components/products-container';
import TopRightControls from './top-right-controls';
import Footer from './footer';
import CenterControls from './center-controls';

interface IProps {
    selectedInFrameVideoObj: IVideo;
    isPlaying: boolean;
    setIsPlaying: React.Dispatch<React.SetStateAction<boolean>>;
    setSelectedProduct: React.Dispatch<React.SetStateAction<IProductResponse | undefined>>;
    videoStyle: CSSProperties;
}

const dataTestIdPrefix = "ProductPageWidgetInFrameComponent";

const EmbeddedModeInFrameStateComponent = ({ selectedInFrameVideoObj, isPlaying, setIsPlaying, setSelectedProduct, videoStyle }: IProps) => {
    const { abTestData, widgetSettings, setSelectedVideoObj, setInitialFullScreenValue, isMuted, setIsMuted } = useContext(AppContext);
    const playerRef = useRef(null);
    const [videoProgress, setVideoProgress] = useState<IVideoProgress>(EMPTY_PROGRESS);
    const [isVideoLoaded, setIsVideoLoaded] = useState(false);

    const onVideoProgress = (progress: IVideoProgress) => {
        if (progress?.loadedSeconds > 0 || (progress?.playedSeconds > 0 && widgetSettings?.isAutoPlay)) {
            setIsVideoLoaded(true);
        }
        setVideoProgress(progress);
    };

    const onVideoPlay = () => {
        const duration = Math.floor(videoProgress?.playedSeconds);
        sendVideoStartEvent(selectedInFrameVideoObj!, abTestData, VideoStartType.Start, duration, false);
        setIsPlaying(true);
        updateWidgetShownAndLastSeenProduct(selectedInFrameVideoObj?.id!);
    }

    const onVideoPause = () => {
        if (Math.round(videoProgress.played * 10) / 10 !== 1) {
            sendVideosPauseEvent(selectedInFrameVideoObj?.id!, videoProgress?.playedSeconds, false);
        }
        setIsPlaying(false);
    }

    const onVideoError = (error: any, data?: any, hlsInstance?: any, hlsGlobal?: any) => {
        console.log('error, data, hlsInstance, hlsGlobal =>', error, data, hlsInstance, hlsGlobal);
        if (error.name === 'AbortError' || error.name === 'NotAllowedError') {
            setIsMuted(true);
            setIsPlaying(true);
        }
    }

    const onVideoEnded = () => {
        sendVideoStopEvent(selectedInFrameVideoObj?.id!, videoProgress?.playedSeconds, abTestData?.widgetShown, false);
    }

    const toggleFullScreen = () => {
        if (Math.round(videoProgress.played * 10) / 10 !== 1) {
            sendVideoStopEvent(selectedInFrameVideoObj?.id!, videoProgress?.playedSeconds, abTestData?.widgetShown, false);
        }

        setSelectedVideoObj(selectedInFrameVideoObj);
        setInitialFullScreenValue(true);
        setIsPlaying(false);
    }

    return (
        <>
            <div className="gander-preview-item-video-gif-container">
                <video className="gander-preview-item-video-gif" playsInline autoPlay muted loop crossOrigin=""
                    src={
                        widgetSettings.displayBranding
                            ? GANDER_LOADER_VIDEO
                            : GANDER_NON_BRANDING_LOADER_VIDEO
                    }
                />
            </div>

            {isVideoLoaded && (
                <>
                    <TopRightControls toggleFullScreen={toggleFullScreen} />
                    <CenterControls
                        isPlaying={isPlaying}
                        playBtnClick={() => setIsPlaying(true)}
                        pauseBtnClick={() => setIsPlaying(false)}
                    />
                </>
            )}

            <GanderPlayer
                style={videoStyle}
                dataTestId={`${dataTestIdPrefix}-Video-Player`}
                width='100%'
                height='100%'
                className="gander-react-video-player"
                ref={playerRef}
                url={selectedInFrameVideoObj.videoUrl}
                isPlaying={isPlaying}
                isLoadVideo={true}
                setIsPlaying={setIsPlaying}
                onVideoPlay={onVideoPlay}
                onVideoPause={onVideoPause}
                onVideoError={onVideoError}
                onVideoProgress={onVideoProgress}
                onVideoEnded={onVideoEnded}
                selectedVideoObj={selectedInFrameVideoObj}
                isMuted={isMuted}
            />

            {isVideoLoaded && <>
                <TimeBar videoProgress={videoProgress} setVideoProgress={setVideoProgress} ref={playerRef} />

                {selectedInFrameVideoObj?.products && (
                    <ProductsContainer
                        products={selectedInFrameVideoObj.products}
                        isPortrait={true}
                        isFullscreen={false}
                        onClickProductIcon={setSelectedProduct}
                        isDisplayBranding={widgetSettings?.displayBranding}
                    />
                )}
                {selectedInFrameVideoObj && (
                    <Footer selectedVideoObj={selectedInFrameVideoObj} fullScreen={false} isVideoLoaded={isVideoLoaded} displayBranding={widgetSettings?.displayBranding} />
                )}
            </>}

        </>
    )
}

export default EmbeddedModeInFrameStateComponent