export const getItemFromSessionStorage = (key: string): any => {
  let value = sessionStorage.getItem(key) || "";
  if (value && (value.startsWith("[") || value.startsWith("{"))) {
    value = JSON.parse(value);
  }
  return value;
};

export const setItemToSessionStorage = (key: string, value: any): void => {
  if (typeof value === "object") {
    value = JSON.stringify(value);
  }
  sessionStorage.setItem(key, value);
};

export const removeItemToSessionStorage = (key: string): void => {
    sessionStorage.removeItem(key);
};
