import { useContext, useEffect } from "react";
import { updateWidgetShownAndLastSeenProduct } from "../../utils/common-functions";
import logger from "../../utils/logger";
import { AppContext } from "../../context/app-context";
import OverlayModeWidgetComponent from "../overlay-mode-widget-component";

const GlobalPagesWidget = () => {
	const { ganderVideos, widgetSettings } = useContext(AppContext);

	useEffect(() => {
		logger(ganderVideos);
		if (ganderVideos && ganderVideos?.length > 0) {
			updateWidgetShownAndLastSeenProduct(ganderVideos[0]?.id);
		}
	}, [ganderVideos]);

	return (
		<OverlayModeWidgetComponent previewOverlayStartingState={widgetSettings?.previewOverlayStartingState} />
	);
};

export default GlobalPagesWidget;
