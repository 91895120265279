import { useContext } from "react";
import { AppContext } from "context/app-context";

type Props = {
  handleClick: () => void;
};

const dataTestIdPrefix = "PlayButtonWithOverlayComponent";

const PlayButtonWithOverlay = ({ handleClick }: Props) => {
  const { isMobile } = useContext(AppContext);
  return (
    <div 
      className={`g-play-btn-with-overlay ${isMobile ? "gander-is-mobile" : ""}`} 
      onClick={handleClick} 
      data-testid={`${dataTestIdPrefix}-Play-Button-With-Overlay`}
    >
      <svg width="56" height="57" viewBox="0 0 56 57" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_b_2125_620)">
          <rect className="gander-button-overlay" y="0.5" width="56" height="56" rx="28" fill="black" fillOpacity="0.16" />
          <path d="M22.1667 21L33.8334 28.5L22.1667 36V21Z" fill="white" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </g>
        <defs>
          <filter id="filter0_b_2125_620" x="-20" y="-19.5" width="96" height="96" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feGaussianBlur in="BackgroundImageFix" stdDeviation="10" />
            <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_2125_620" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_2125_620" result="shape" />
          </filter>
        </defs>
      </svg>

    </div>
  );
};

export default PlayButtonWithOverlay;