import { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { EChoiceNextQuestionMetaRedirect, IChoice, IQuestion } from "../../../interfaces";
import { EQuestionResponseStatus } from "../../../interfaces/elastic-events";
import { getValidUrl } from "../../../utils/common-functions";

import "./index.scss";

interface IProps {
  isFullscreen: boolean;
  interactionQuestion: IQuestion;
  handleSendQuestionResponseEvent: (
    questionResponseId: string,
    question: IQuestion,
    status: EQuestionResponseStatus,
    choiceIds?: string[],
    userInput?: string
  ) => void;
  handleNextQuestion: (question: IQuestion, choice?: IChoice) => void;
  dataTestId?: string;
}

const CallToActionQuestion = ({
  interactionQuestion,
  isFullscreen,
  handleSendQuestionResponseEvent,
  handleNextQuestion,
  dataTestId
}: IProps) => {
  const [questionResponseId, setQuestionResponseId] = useState<string>();

  useEffect(() => {
    setQuestionResponseId(undefined);

    const uuid = uuidv4();
    handleSendQuestionResponseEvent(
      uuid,
      interactionQuestion,
      EQuestionResponseStatus.VIEWED
    );
    setQuestionResponseId(uuid);

    return () => {
      setQuestionResponseId("");
    }
  }, [interactionQuestion]);

  const handleSubmitAnswer = () => {
    if (interactionQuestion.choices[0]?.nextQuestionMeta === EChoiceNextQuestionMetaRedirect) {
      window.open(getValidUrl(interactionQuestion.choices[0]?.redirectUrl), "_blank");
    }
  
    if (questionResponseId) {
      handleSendQuestionResponseEvent(
        questionResponseId,
        interactionQuestion,
        EQuestionResponseStatus.SUBMITTED,
        [interactionQuestion.choices[0].id]
      );

      handleNextQuestion(interactionQuestion, interactionQuestion.choices[0]);
    }
  };

  const fullscreenOverlayClass = isFullscreen
    ? "g-call-to-action-interaction-component-fullscreen"
    : "g-call-to-action-interaction-component-overlay";

  return (
    <div
      className={`g-call-to-action-interaction-component ${fullscreenOverlayClass}`}
      data-testid={`${dataTestId}-Cotnainer`}
    >
      <button
        className="g-call-to-action-interaction-button"
        style={{
          background: interactionQuestion.choices[0].buttonColor,
          color: interactionQuestion.choices[0].buttonTextColor,
        }}
        onClick={handleSubmitAnswer}
        data-testid={`${dataTestId}-CallToActionButtonWithText`}
      >
        {interactionQuestion?.text}
      </button>
      <a id="gander-link" href="about:blank" target="_blank" rel="noopener noreferrer" style={{ display: "none" }}>Link</a>
    </div>
  );
};

export default CallToActionQuestion;
