import { CSSProperties, useContext, useRef } from "react";
import { AppContext } from "../../../context/app-context";
import GanderPlayer from "../../video-player/gander-player";
import { GANDER_LOADER_VIDEO, GANDER_NON_BRANDING_LOADER_VIDEO } from "../../../config";
import ProductsContainer from "../../products-container";
import TimeBar from "../../video-player/video-controls/time-bar";
import { sendVideoStartEvent, sendVideoStopEvent, sendVideosPauseEvent } from "../../../utils/elastic-events";
import { VideoStartType } from "../../../constants/video_start_type";
import { updateWidgetShownAndLastSeenProduct } from "../../../utils/common-functions";
import { IGlobalVideoProgress, IProductResponse, IVideo, IVideoProgress } from "../../../interfaces";
import TopRightControls from "./top-right-controls";
import RightControls from "./right-controls";
import Footer from "./footer";
import CenterControls from "./center-controls";

import './index.scss';

const dataTestIdPrefix = "OverlayOpenState";

interface IProps {
    isVideoLoaded: boolean;
    selectedVideoObj: IVideo;
    fullScreen: boolean;
    videoProgress: IVideoProgress;
    isPlaying: boolean;
    isMuted: boolean;
    nextVideo: IVideo | undefined;
    globalVideoProgress: IGlobalVideoProgress;
    setSelectedProduct: React.Dispatch<React.SetStateAction<IProductResponse | undefined>>;
    setVideoProgress: React.Dispatch<React.SetStateAction<IVideoProgress>>;
    sliderNextVideoClick: () => void;
    sliderPrevVideoClick: () => void;
    setIsPlaying: React.Dispatch<React.SetStateAction<boolean>>;
    setIsVideoLoaded: React.Dispatch<React.SetStateAction<boolean>>;
    setIsMuted: React.Dispatch<React.SetStateAction<boolean | undefined>>;
    closeBtnClick: () => void;
    toggleFullScreen: () => void;
}

const OpenMode = ({
    isVideoLoaded,
    selectedVideoObj,
    fullScreen,
    videoProgress,
    isPlaying,
    isMuted,
    nextVideo,
    globalVideoProgress,
    setSelectedProduct,
    setVideoProgress,
    sliderNextVideoClick,
    sliderPrevVideoClick,
    setIsPlaying,
    setIsVideoLoaded,
    setIsMuted,
    closeBtnClick,
    toggleFullScreen,
}: IProps) => {
    const { widgetSettings, ganderVideos, abTestData } = useContext(AppContext);
    const playerRef = useRef(null);
    const { id, videoUrl, captions, isPortrait } = selectedVideoObj;

    const onVideoPlay = () => {
        const duration = Math.floor(videoProgress?.playedSeconds);
        sendVideoStartEvent(selectedVideoObj, abTestData, VideoStartType.Start, duration, false);
        setIsPlaying(true);
        updateWidgetShownAndLastSeenProduct(id);
    }

    const onVideoPause = () => {
        if (Math.round(videoProgress.played * 10) / 10 !== 1) {
            sendVideosPauseEvent(id, videoProgress?.playedSeconds, false);
        }
        setIsPlaying(false);
    }

    const onVideoError = (error: any, data?: any, hlsInstance?: any, hlsGlobal?: any) => {
        console.log('error, data, hlsInstance, hlsGlobal =>', error, data, hlsInstance, hlsGlobal);
        if (error.name === 'AbortError' || error.name === 'NotAllowedError') {
            setIsMuted(true);
            setIsPlaying(true);
        }
    }

    const onVideoProgress = (progress: IVideoProgress) => {
        if (progress?.loadedSeconds > 0 || (progress?.playedSeconds > 0 && widgetSettings?.isAutoPlay)) {
            setIsVideoLoaded(true);
        }
        setVideoProgress(progress);
    };

    const onVideoEnded = () => {
        sendVideoStopEvent(id, videoProgress?.playedSeconds, abTestData?.widgetShown, false);
    }

    const openModePlayerContainerStyles: CSSProperties = {
        height: `${widgetSettings?.overlayWidgetSize}`,
        width: "auto",
        aspectRatio: `${isPortrait ? "9 / 16" : "16 / 9"}`,
        minHeight: "380px",
    }

    // TODO: this is for backward compatibility we will discuss to remove it
    if (widgetSettings?.overlayWidgetSize.includes('px')) {
        openModePlayerContainerStyles.height = `${isPortrait ? widgetSettings?.overlayWidgetSize : "auto"}`;
        openModePlayerContainerStyles.width = `${isPortrait ? "auto" : widgetSettings?.overlayWidgetSize}`;
        openModePlayerContainerStyles.transition = `none`;
    }

    return (
        <>
            <div className='gander-player-mask' />
            <div className='gander-video-loader-overlay'>
                <video
                    playsInline
                    autoPlay
                    muted
                    loop
                    crossOrigin=''
                    className='gander-video-loader-image'
                    src={widgetSettings.displayBranding ? GANDER_LOADER_VIDEO : GANDER_NON_BRANDING_LOADER_VIDEO}
                    data-testid={`${dataTestIdPrefix}-LoaderVideo`}
                />
            </div>
            <div
                className={`gander-video-player-container`}
                style={openModePlayerContainerStyles}
                data-testid={`${dataTestIdPrefix}-Player-Container`}
            >
                <TopRightControls
                    isVideoLoaded={isVideoLoaded}
                    isPortrait={isPortrait}
                    toggleFullScreen={toggleFullScreen}
                    closeBtnClick={closeBtnClick}
                />
                <GanderPlayer
                    className="gander-react-video-player"
                    ref={playerRef}
                    url={videoUrl}
                    isPlaying={isPlaying}
                    captions={captions}
                    isLoadVideo={true}
                    nextVideo={nextVideo}
                    isShowVideoCaption={widgetSettings?.isShowVideoCaption}
                    setIsPlaying={setIsPlaying}
                    onVideoPlay={onVideoPlay}
                    onVideoPause={onVideoPause}
                    onVideoError={onVideoError}
                    onVideoProgress={onVideoProgress}
                    onVideoEnded={onVideoEnded}
                    width='100%'
                    globalVideoProgress={globalVideoProgress}
                    selectedVideoObj={selectedVideoObj}
                    isMuted={isMuted}
                    dataTestId={`${dataTestIdPrefix}-Video`}
                />

                {isVideoLoaded && (
                    <>
                        <TimeBar
                            ref={playerRef}
                            videoProgress={videoProgress}
                            dataTestId={`${dataTestIdPrefix}-TimeBar`}
                            setVideoProgress={setVideoProgress}
                        />
                        {selectedVideoObj?.products && (
                            <ProductsContainer
                                products={selectedVideoObj.products}
                                isPortrait={isPortrait}
                                isFullscreen={fullScreen}
                                onClickProductIcon={setSelectedProduct}
                                isDisplayBranding={widgetSettings?.displayBranding}
                            />
                        )}
                        <CenterControls
                            isPlaying={isPlaying}
                            playBtnClick={() => setIsPlaying(true)}
                            pauseBtnClick={() => setIsPlaying(false)}
                        />
                        <Footer
                            selectedVideoObj={selectedVideoObj}
                            fullScreen={fullScreen}
                            isVideoLoaded={isVideoLoaded}
                            displayBranding={widgetSettings?.displayBranding}
                            dataTestId={`${dataTestIdPrefix}-Footer`}
                        />
                        {ganderVideos.length > 1 && (
                            <RightControls
                                isPortrait={isPortrait}
                                selectedVideoObj={selectedVideoObj}
                                videoProgress={videoProgress}
                                sliderNextVideoClick={sliderNextVideoClick}
                                sliderPrevVideoClick={sliderPrevVideoClick}
                            />
                        )}
                    </>
                )}
            </div>
        </>
    )
}

export default OpenMode