const getUserInformationSchema = (): Record<string, any> => {
  return {
    "@timestamp": new Date().toISOString(),
    device: {
      screen: {
        height: 0,
        width: 0,
      },
      browser: {
        name: "",
        version: "",
      },
      os: {
        name: "",
        version: "",
      },
      brand: "",
      isTouchScreen: false,
      platform: "",
      type: "",
    },
    language: "",
    geo: getGeoInfoSchema(),
    ipAddress: "",
    clientID: "",
    klaraID: "",
    loginID: "",
    mobileNumber: "",
    useragent: "",
    userInformationFlag: true,
    data: {
      widget_shown: false,
    },
  };
};

const getGeoInfoSchema = (): Record<string, string | number> => {
  return {
    countryCode: "",
    country: "",
    regionCode: "",
    region: "",
    city: "",
    lat: 0,
    long: 0,
  };
};

export { getUserInformationSchema, getGeoInfoSchema };
