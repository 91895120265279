import { useContext } from 'react'
import { AppContext } from '../../context/app-context';
import VideoPlayerComponent from '../video-player';
import { EOverlayStartingState } from 'interfaces/widget-setting-v2';

interface IProps {
    previewOverlayStartingState?: EOverlayStartingState;
}

const OverlayModeWidgetComponent = ({ previewOverlayStartingState }: IProps) => {
    const { ganderVideos, selectedVideoObj, setSelectedVideoObj } = useContext(AppContext);

    const sliderPrevVideoClick = () => {
        if (selectedVideoObj?.index !== undefined) {
            const indexToSelect = selectedVideoObj?.index === 0 ? ganderVideos.length - 1 : selectedVideoObj?.index - 1;
            setSelectedVideoObj(ganderVideos[indexToSelect]);
        }
    }

    const sliderNextVideoClick = () => {
        if (selectedVideoObj?.index !== undefined) {
            const indexToSelect = selectedVideoObj?.index === ganderVideos.length - 1 ? 0 : selectedVideoObj?.index + 1;
            setSelectedVideoObj(ganderVideos[indexToSelect]);
        }
    }
    if (!selectedVideoObj) {
        return null;
    }

    return (
        <VideoPlayerComponent
            selectedVideoObj={selectedVideoObj}
            previewOverlayStartingState={previewOverlayStartingState}
            sliderPrevVideoClick={sliderPrevVideoClick}
            sliderNextVideoClick={sliderNextVideoClick}
        />
    );
}

export default OverlayModeWidgetComponent;