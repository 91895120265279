import { ReactComponent as MaximizeWithOverlay } from '../../../../assets/img/maximize-with-overlay.svg';

type Props = {
  handleClick: () => void;
};

const dataTestIdPrefix = "MaximizeButtonWithOverlayComponent";

const MaximizeButtonWithOverlay = ({ handleClick }: Props) => {
  return (
    <div 
      className="g-maximize-btn-with-overlay" 
      onClick={handleClick} 
      data-testid={`${dataTestIdPrefix}-Maximize-Button-With-Overlay`}
    >
      <MaximizeWithOverlay />
    </div>
  );
};

export default MaximizeButtonWithOverlay;