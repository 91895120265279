import { useContext } from "react";
import PlayButtonWithOverlay from "components/video-player/video-controls/buttons/PlayButtonWithOverlay";
import PauseButtonWithOverlay from "components/video-player/video-controls/buttons/PauseButtonWithOverlay";
import { AppContext } from "context/app-context";

import "./index.scss";

interface Props {
  isPlaying: boolean;
  playBtnClick: () => void;
  pauseBtnClick: () => void;
  dataTestId?: string;
}

const CenterControls = ({ isPlaying, playBtnClick, pauseBtnClick, dataTestId }: Props) => {
  const { isMobile } = useContext(AppContext);
  return (
    <div
      className="gander-fullscreen-mode-center-controls"
      onClick={isPlaying ? pauseBtnClick : playBtnClick}
      data-testid={`${dataTestId}`}
    >
      {!isPlaying && <PlayButtonWithOverlay handleClick={playBtnClick} />}
      {!isMobile && isPlaying && <PauseButtonWithOverlay handleClick={pauseBtnClick} />}
    </div>
  )
}

export default CenterControls;