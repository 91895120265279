import { useContext, useState } from "react";
import MaximizeButtonWithOverlay from "components/video-player/video-controls/buttons/MaximizeButtonWithOverlay";
import MuteButtonWithOverlay from "components/video-player/video-controls/buttons/MuteButtonWithOverlay";
import UnmuteButtonWithOverlay from "components/video-player/video-controls/buttons/UnmuteButtonWithOverlay";
import { AppContext } from "context/app-context";
import { SHOP_NAME } from "config";

const AUTOPLAY_SHOPS = [
  "nudeallure.myshopify.com",
]

interface Props {
  toggleFullScreen: () => void;
}

const TopRightControls = ({ toggleFullScreen }: Props) => {
  const { isMuted, setIsMuted } = useContext(AppContext);
  const [isHideControls] = useState(AUTOPLAY_SHOPS.includes(SHOP_NAME) && window.location.pathname === "/");

  return (
    <div className={`gander-inframe-top-right-controls ${isHideControls ? "gander-remove-width" : ""}`}>
      {!isHideControls && <MaximizeButtonWithOverlay handleClick={toggleFullScreen} />}
      {isMuted ? <UnmuteButtonWithOverlay handleClick={() => setIsMuted(false)} /> : <MuteButtonWithOverlay handleClick={() => setIsMuted(true)} />}
    </div>
  )
}

export default TopRightControls;