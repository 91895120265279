type Props = {
  handleClick: () => void;
};

const dataTestIdPrefix = "MuteButtonWithOverlayComponent";

const MuteButtonWithOverlay = ({ handleClick }: Props) => {
  return (
    <div
      className="g-mute-unmute-btn-with-overlay"
      onClick={handleClick}
      data-testid={`${dataTestIdPrefix}-Mute-Button-With-Overlay`}
    >
      <svg width="56" height="57" viewBox="0 0 56 57" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_b_2168_676)">
          <rect className="gander-button-overlay" y="0.5" width="56" height="56" rx="28" fill="black" fillOpacity="0.16" />
          <path opacity="0.16" d="M28.8333 21L23.8333 25.1667H22.1667C21.2462 25.1667 20.5 25.9129 20.5 26.8333V30.1667C20.5 31.0871 21.2462 31.8333 22.1667 31.8333H23.8333L28.8333 36V21Z" fill="white" />
          <path d="M28.8333 21L23.8333 25.1667H22.1667C21.2462 25.1667 20.5 25.9129 20.5 26.8333V30.1667C20.5 31.0871 21.2462 31.8333 22.1667 31.8333H23.8333L28.8333 36V21Z" fill="white" />
          <path d="M31.3333 26C31.75 26.4167 32.1667 27.25 32.1667 28.5C32.1667 29.75 31.75 30.5833 31.3333 31M33.8333 23.5C35.0833 24.75 35.5 26.8333 35.5 28.5C35.5 30.1666 35.0833 32.25 33.8333 33.5M28.8333 21L23.8333 25.1667H22.1667C21.2462 25.1667 20.5 25.9129 20.5 26.8333V30.1667C20.5 31.0871 21.2462 31.8333 22.1667 31.8333H23.8333L28.8333 36V21Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </g>
        <defs>
          <filter id="filter0_b_2168_676" x="-20" y="-19.5" width="96" height="96" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feGaussianBlur in="BackgroundImageFix" stdDeviation="10" />
            <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_2168_676" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_2168_676" result="shape" />
          </filter>
        </defs>
      </svg>

    </div>
  );
};

export default MuteButtonWithOverlay;