import { useEffect, useState } from "react";
import { IChoice, IQuestion } from "../../../interfaces";
import { EQuestionResponseStatus } from "../../../interfaces/elastic-events";
import { v4 as uuidv4 } from "uuid";

import "./index.scss";

interface IProps {
  isFullscreen: boolean;
  interactionQuestion: IQuestion;
  handleSendQuestionResponseEvent: (
    questionResponseId: string,
    question: IQuestion,
    status: EQuestionResponseStatus,
    choiceIds?: string[],
    userInput?: string
  ) => void;
  handleNextQuestion: (question: IQuestion, choice?: IChoice) => void;
  dataTestId?: string;
}

const ShortAnswerQuestion = ({
  interactionQuestion,
  isFullscreen,
  handleSendQuestionResponseEvent,
  handleNextQuestion,
  dataTestId
}: IProps) => {
  const [questionResponseId, setQuestionResponseId] = useState<string>();
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    setQuestionResponseId(undefined);
    setInputValue("");

    const uuid = uuidv4();
    handleSendQuestionResponseEvent(
      uuid,
      interactionQuestion,
      EQuestionResponseStatus.VIEWED
    );
    setQuestionResponseId(uuid);

    return () => {
      setQuestionResponseId(undefined);
      setInputValue("");
    }
  }, [interactionQuestion]);

  const onChange = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setInputValue(e.target.value);
  };

  const onClick = (e: any) => {
    // Since `focusOnSelect:true` we have to stop propagation
    e.stopPropagation();
  };

  const handleSubmitAnswer = () => {
    if (questionResponseId) {
      handleSendQuestionResponseEvent(
        questionResponseId,
        interactionQuestion,
        EQuestionResponseStatus.SUBMITTED,
        [interactionQuestion.choices[0].id],
        inputValue
      );

      handleNextQuestion(
        interactionQuestion,
        interactionQuestion?.choices?.[0]
      );
    }
  };

  const handleSkipQuestion = () => {
    handleNextQuestion(interactionQuestion, interactionQuestion?.choices?.[0]);
  };

  return (
    <div
      className={`g-short-answer-interaction-component ${isFullscreen
        ? "g-short-answer-interaction-component-fullscreen"
        : "g-short-answer-interaction-component-overlay"
        }`}
      data-testid={`${dataTestId}-Container`}
    >
      <div className="g-short-answer-interaction-question">
        <div className="g-short-answer-interaction-question-text" data-testid={`${dataTestId}-QuestionText`}>
          {interactionQuestion?.text}
        </div>
      </div>
      <div className="g-short-answer-interaction-input-form">
        <input
          className="g-short-answer-interaction-input-box"
          type="text"
          value={inputValue}
          name="short-answer-question-input"
          placeholder={interactionQuestion?.choices?.[0]?.text}
          onChange={(e) => onChange(e)}
          onClick={(e) => onClick(e)}
          data-testid={`${dataTestId}-AnswerInputBox`}
        />
        <div>
          <button
            className="g-short-answer-interaction-submit-button"
            onClick={handleSubmitAnswer}
            disabled={!inputValue}
            data-testid={`${dataTestId}-NextQuestionButton`}
          >
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15.7071 8.70711C16.0976 8.31658 16.0976 7.68342 15.7071 7.29289L9.34315 0.928932C8.95262 0.538408 8.31946 0.538408 7.92893 0.928932C7.53841 1.31946 7.53841 1.95262 7.92893 2.34315L13.5858 8L7.92893 13.6569C7.53841 14.0474 7.53841 14.6805 7.92893 15.0711C8.31946 15.4616 8.95262 15.4616 9.34315 15.0711L15.7071 8.70711ZM0 9H15V7H0V9Z"
                fill="white"
              />
            </svg>
          </button>
        </div>
      </div>
      <div className="g-short-answer-interaction-skip-button-container">
        <div
          className="g-short-answer-interaction-skip-button"
          onClick={handleSkipQuestion}
        >
          SKIP
        </div>
      </div>
    </div>
  );
};

export default ShortAnswerQuestion;
