import { DEFAULT_SETTINGS_AND_VIDEOS, SHOP_NAME, LOCATION_HOST } from "../config";
import { ISettingsAndVideos, IVideo } from "../interfaces";
import logger from "../utils/logger";
import { getUserInfoWithClientID, getDevice, getQueryStringParams, getSessionId, generateGanderSessionID } from "../utils/common-functions";
// import { mockGetWidgetData } from "./mock";

const { REACT_APP_GANDER_BE_API_URL } = process.env;

// All values should lowercase
const bots = ['bot', 'spider', 'facebook', 'baidu', 'duckduck', 'bing', 'sogou', 'octoparse', 'apache', 'scrapy', 'crawler', 'crawl', 'bytespider', 'bytedance'];
const userAgent = window?.navigator?.userAgent?.toLowerCase();
const isBotCrawlerUserAgent = bots.some(v => userAgent.includes(v));

const apiGetWidgetData = async (shopName: string, pathName: string, isMobile: boolean): Promise<ISettingsAndVideos | undefined> => {
  const sessionID = await getSessionId();
  await generateGanderSessionID();

  const url = `${REACT_APP_GANDER_BE_API_URL}public/get-widget-data`;

  if (isBotCrawlerUserAgent || !sessionID || !shopName || !pathName) {
    logger("apiGetWidgetData: bot / crawler not allowed || Required parameter not found,shopName,pathName,sessionID", shopName, pathName, sessionID, !url.includes('sessionID'));
    return;
  }

  const params = {
    domain: shopName,
    path: decodeURI(pathName),
    sessionID: sessionID,
    isMobile: isMobile,
    appVersion: '2.3'
  };

  const response = await fetch(url, { method: "POST", body: JSON.stringify(params) });

  const { data: payload } = await response.json();
  if (!response.ok || response.status !== 200) {
    return { ...DEFAULT_SETTINGS_AND_VIDEOS };
  }
  return { ...payload };
  // For development purposes
  //   return { ...mockGetWidgetData.data } as any;
};

const apiGetFullScreenVideos = async (shopName: string, pathName: string, currentPageVideoMetas: string[]): Promise<IVideo[] | undefined> => {
  const sessionID = await getSessionId();
  await generateGanderSessionID();
  const url = `${REACT_APP_GANDER_BE_API_URL}public/get-widget-data`;
  if (isBotCrawlerUserAgent || !sessionID || !shopName || !pathName) {
    logger("apiGetWidgetData: bot / crawler not allowed || Required parameter not found,shopName,pathName,sessionID", shopName, pathName, sessionID, !url.includes('sessionID'));
    return;
  }

  const params = {
    domain: shopName,
    path: decodeURI(pathName),
    sessionID: sessionID,
    isFullScreen: "true",
    currentPageVideoMetas: JSON.stringify(currentPageVideoMetas),
    appVersion: '2.3'
  };

  const response = await fetch(url, { method: "POST", body: JSON.stringify(params) });

  if (!response.ok) {
    return [];
  }

  const { data: payload } = await response.json();
  if (response.status !== 200) {
    return [];
  }
  return payload.videos;
};

const apiGetVideoData = async (shopName: string, pathName: string, videoMetas: string[]): Promise<IVideo[] | undefined> => {
  const sessionID = await getSessionId();
  await generateGanderSessionID();
  const url = `${REACT_APP_GANDER_BE_API_URL}public/get-widget-data?domain=${shopName}&path=${pathName}&sessionID=${sessionID}&isSecondFetch=true&videoMetas=${JSON.stringify(
    videoMetas
  )}&appVersion=2.2`;

  if (!url.includes('sessionID') || isBotCrawlerUserAgent || !sessionID || !shopName || !pathName) {
    logger("apiGetWidgetData: bot / crawler not allowed || Required parameter not found,shopName,pathName,sessionID", shopName, pathName, sessionID, !url.includes('sessionID'));
    return;
  }
  const response = await fetch(url, { method: "GET" });
  if (!response.ok) {
    return [];
  }

  const { data: payload } = await response.json();
  if (response.status !== 200) {
    return [];
  }
  console.log('done');
  return payload.videos;
};

const sendAnalyticsEvent = async (eventData: any, isAllowEventInCypress: boolean = false) => {
  const queryParams = getQueryStringParams();
  if ((LOCATION_HOST.includes("localhost") || window?.Shopify?.designMode || !!queryParams?.isPreviewMode) && !(isAllowEventInCypress && window?.Cypress)) {
    logger("🚀 ~ file: common.service.ts ~ NOT SENDING EVENTS AS ITS EITHER ON LOCALHOST OR ON DESIGN MODE");
    return;
  }
  try {
    const sessionId = await getSessionId();
    const { ganderSessionID, sessionUsedTimeStamp } = await generateGanderSessionID();
    const { userDetails, clientID, wasClientIDPresent } = await getUserInfoWithClientID(sessionId);

    if (!sessionId) {
      // To Avoid Bot users to make call to our backend
      logger("sendAnalyticsEvent:  Required parameter not found, sessionId ", sessionId);
      return;
    }

    let defaultEventObj = {
      kind: "event",
      is_react_widget: true,
      shop: SHOP_NAME,
      visitor: sessionId,
      device: getDevice(),
      ganderSessionID: ganderSessionID,
      ganderSessionUsedTimeStamp: sessionUsedTimeStamp,
    };

    if (userDetails && userDetails?.ipAddress && clientID) {
      if (wasClientIDPresent === false) {
        eventData.userInformation = userDetails;
      }
      defaultEventObj = {
        ...defaultEventObj,
        ...{
          clientID: clientID,
          wasClientIDPresent: wasClientIDPresent,
        },
      };
    }

    eventData.event = { ...eventData.event, ...defaultEventObj };
    eventData.app_version = 2;

    eventData["@timestamp"] = new Date().toISOString();
    return await fetch(`${REACT_APP_GANDER_BE_API_URL}statistics-events`, {
      method: "POST",
      headers: { "Content-Type": "application/json", "x-shop": SHOP_NAME },
      body: JSON.stringify(eventData),
    });
  } catch (e: any) {
    console.log("Failed to Send Analytics: ", e?.message);
    return;
  }
};

const getAvailableVideosOnS3 = async (videosToValidate: IVideo[]): Promise<IVideo[]> => {
  if (videosToValidate) {
    const validatedSources = await Promise.all(
      videosToValidate.map(async (obj: IVideo) => {
        const url = obj.videoUrl;
        const res = await fetch(url, { method: "HEAD" });
        if (res.status === 200) {
          return obj;
        }
        logger("Oops!! Video Not Found on s3 Bucket: ", obj);
        return null;
      })
    );
    return validatedSources.filter((x) => x) as IVideo[] | [];
  }
  return [];
};

export { apiGetWidgetData, apiGetFullScreenVideos, sendAnalyticsEvent, getAvailableVideosOnS3, apiGetVideoData };
