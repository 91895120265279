import { CSSProperties, useContext, useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { EOverridePreviewState, IGlobalVideoProgress, IProductResponse, IVideo, IVideoProgress } from '../../interfaces';
import { sendFullScreenClickEvent, sendVideoStartEvent, sendVideoStopEvent } from '../../utils/elastic-events';
import FullscreenModeComponent from '../fullscreen-mode-component';
import { VideoStartType } from '../../constants/video_start_type';
import AddToCartContainer from '../add-to-cart-container';
import { EMPTY_PROGRESS, IS_CLOSE_MODE_ACTIVE } from '../../config';
import { AppContext } from '../../context/app-context';
import ClosedMode from '../overlay-mode-widget-component/closed-mode';
import PreviewMode from '../overlay-mode-widget-component/preview-mode';
import OpenMode from '../overlay-mode-widget-component/open-mode';
import { EOverlayOpenState, EOverlayStartingState, EWidgetType } from 'interfaces/widget-setting-v2';
import { getItemFromSessionStorage } from 'utils/get-set-local-session';

import './index.scss';

const bodyDOM = document.getElementsByTagName('body')[0];

interface Props {
    selectedVideoObj: IVideo;
    sliderPrevVideoClick: () => void;
    sliderNextVideoClick: () => void;
    previewOverlayStartingState?: EOverlayStartingState;
}

enum ECurrentPlayerType {
    OPEN = "OPEN",
    PREVIEW = "PREVIEW",
    CLOSE = "CLOSE"
}

const dataTestIdPrefix = "GanderVideoPlayer";

const VideoPlayerComponent = ({
    selectedVideoObj,
    sliderPrevVideoClick,
    sliderNextVideoClick,
    previewOverlayStartingState = EOverlayStartingState.OPEN_STATE,
}: Props) => {
    const { isEmbedProductPage, initialFullScreenValue, abTestData, setSelectedVideoObj, widgetSettings, ganderVideos, overridePreviewState, isMuted, setIsMuted } = useContext(AppContext);
    const { id, isPortrait } = selectedVideoObj;
    const [videoProgress, setVideoProgress] = useState<IVideoProgress>(EMPTY_PROGRESS);
    const [globalVideoProgress, setGlobalVideoProgress] = useState<IGlobalVideoProgress>({ ...EMPTY_PROGRESS, videoId: selectedVideoObj.id }); // Persists video progress between fullscreen & overlay modes
    const [isShowVideoPlayer, setIsShowVideoPlayer] = useState(previewOverlayStartingState === EOverlayStartingState.OPEN_STATE);
    const [isPlaying, setIsPlaying] = useState(widgetSettings?.widgetType === EWidgetType.EMBEDDED || previewOverlayStartingState === EOverlayStartingState.OPEN_STATE);
    const [fullScreen, setFullScreen] = useState(false);
    const [isVideoLoaded, setIsVideoLoaded] = useState(widgetSettings?.isShowPreview ? true : false);
    const [previewVideoStartEventSent, setPreviewVideoStartEventSent] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState<IProductResponse>();
    const [nextVideo, setNextVideo] = useState<IVideo>();
    const ganderContainerRef = useRef<any>(null);
    const isCloseModeActive = getItemFromSessionStorage(IS_CLOSE_MODE_ACTIVE) && widgetSettings.widgetType !== EWidgetType.EMBEDDED;
    const [isShowClosedMode, setIsShowClosedMode] = useState(!!isCloseModeActive);
    const [currentPlayerState, setCurrentPlayerState] = useState<ECurrentPlayerType | undefined>(undefined);

    useEffect(() => {
        // "OPEN", "PREVIEW", "CLOSE"
        if (isShowClosedMode) {
            setCurrentPlayerState(ECurrentPlayerType.CLOSE);
        } else if (!isShowVideoPlayer) {
            setCurrentPlayerState(ECurrentPlayerType.PREVIEW);
        } else if (isShowVideoPlayer) {
            setCurrentPlayerState(ECurrentPlayerType.OPEN);
        } else {
            setCurrentPlayerState(undefined);
        }
    }, [isShowClosedMode, isShowVideoPlayer])

    useEffect(() => {
        if (previewOverlayStartingState === EOverlayStartingState.OPEN_STATE || initialFullScreenValue) {
            setFullScreen(widgetSettings?.openStateType === EOverlayOpenState.FULLSCREEN || initialFullScreenValue);
            setIsShowVideoPlayer(widgetSettings?.openStateType === EOverlayOpenState.OVERLAY);
        }
    }, [previewOverlayStartingState, initialFullScreenValue]);

    useEffect(() => {
        switch (overridePreviewState) {
            case EOverridePreviewState.CLOSE_STATE:
                setIsShowClosedMode(true);
                break;
            case EOverridePreviewState.OVERLAY:
                setIsShowVideoPlayer(true);
                break;
            case EOverridePreviewState.PREVIEW_OVERLAY:
                setIsShowVideoPlayer(false);
                break;
            default:
                setIsShowVideoPlayer(previewOverlayStartingState === EOverlayStartingState.OPEN_STATE);
                break;
        }
    }, [previewOverlayStartingState, overridePreviewState]);

    useEffect(() => {
        // NOTE : Added this if-else so when we need to set some states based on isShowVideoPlayer's value
        if (isShowVideoPlayer) {
            setIsVideoLoaded(widgetSettings?.isShowPreview ? true : false);
        }

        const handleClickOutside = (event: any) => {
            if (ganderContainerRef.current && !ganderContainerRef.current.contains(event.target)) {
                if (isShowVideoPlayer && previewOverlayStartingState === EOverlayStartingState.MUTED_GIF) {
                    setIsShowVideoPlayer(false);
                    setSelectedProduct(undefined);
                    setIsPlaying(false);
                }
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [isShowVideoPlayer]);

    useEffect(() => {
        const currentVideoIndex = ganderVideos.findIndex((vid) => vid.id === selectedVideoObj.id);

        if (currentVideoIndex >= 0 && ganderVideos.length > currentVideoIndex + 1) {
            setNextVideo(ganderVideos[currentVideoIndex + 1]);
        }

        return () => {
            setIsVideoLoaded(false);
            setIsPlaying(true);
            setVideoProgress(EMPTY_PROGRESS);
        }
    }, [selectedVideoObj]);

    useEffect(() => {
        setGlobalVideoProgress({ ...videoProgress, videoId: selectedVideoObj.id });
    }, [videoProgress]);

    const closeBtnClick = () => {
        if (videoProgress?.played !== 1) {
            sendVideoStopEvent(id, videoProgress?.playedSeconds, abTestData?.widgetShown, false);
        }

        if (!isShowVideoPlayer || previewOverlayStartingState !== EOverlayStartingState.MUTED_GIF) {
            (!isEmbedProductPage) ? setIsShowClosedMode(true) : setSelectedVideoObj(undefined);
            return;
        }

        setIsShowVideoPlayer(false);
        setIsPlaying(false);
    }

    const handlePreviewClick = () => {
        if (!previewVideoStartEventSent) {
            sendVideoStartEvent(selectedVideoObj, abTestData, VideoStartType.Open, 0, false);
            setPreviewVideoStartEventSent(true);
        }

        if (widgetSettings?.openStateType === EOverlayOpenState.FULLSCREEN) {
            setFullScreen(true);
        }

        setIsPlaying(true);
        setIsShowVideoPlayer(true);
    };

    const handleFullScreenResetVideoMinimize = () => {
        setSelectedVideoObj(ganderVideos[0]);
    }

    const toggleFullScreen = () => {
        if (Math.round(videoProgress.played * 10) / 10 !== 1) {
            sendVideoStopEvent(id, videoProgress?.playedSeconds, abTestData?.widgetShown, fullScreen);
        }

        if (!fullScreen) {
            sendFullScreenClickEvent(selectedVideoObj?.id);
        }

        setFullScreen(!fullScreen);
        setIsPlaying(true);
    }

    const handleCloseModePlayClick = () => {
        setIsShowClosedMode(false);
        handlePreviewClick();
    }

    if (fullScreen) {
        let videos = [...ganderVideos];
        if (videos.length > 1) {
            const index = videos.findIndex((vid: IVideo) => vid?.id === selectedVideoObj?.id);
            const vidObj = videos?.splice(index, 1);
            videos = [...vidObj, ...videos];
        }
        return (
            <FullscreenModeComponent
                ganderVideos={videos}
                currentVideo={selectedVideoObj}
                toggleFullScreen={toggleFullScreen}
                resetVideo={handleFullScreenResetVideoMinimize}
                setGlobalVideoProgress={setGlobalVideoProgress}
                globalVideoProgress={globalVideoProgress}
            />
        );
    }

    const portraitClass = isPortrait ? 'portrait' : 'landscape';

    const containerStyles: CSSProperties = {
        left: widgetSettings.leftPosition,
        top: widgetSettings.topPosition,
        transform: `translate(-${widgetSettings.leftPosition}, -${widgetSettings.topPosition})`,
    }

    if (currentPlayerState === ECurrentPlayerType.OPEN) {
        containerStyles.borderRadius = "10px";
    } else if (currentPlayerState === ECurrentPlayerType.PREVIEW) {
        containerStyles.borderRadius = widgetSettings?.previewCornerRadius;
    }

    return ReactDOM.createPortal((
        <div
            ref={ganderContainerRef}
            className={`gander-container gander-${portraitClass}`}
            style={containerStyles}
            data-testid={`${dataTestIdPrefix}-Container`}
        >
            {currentPlayerState === ECurrentPlayerType.CLOSE && (
                <ClosedMode
                    closeBorderColor={widgetSettings.closeBorderColor}
                    closeCornerRadius={widgetSettings.closeCornerRadius}
                    closeIsBorderPadding={widgetSettings.closeIsBorderPadding}
                    closeIsShowBorder={widgetSettings.closeIsShowBorder}
                    selectedVideoObj={selectedVideoObj}
                    playBtnClick={handleCloseModePlayClick}
                />
            )}

            {currentPlayerState === ECurrentPlayerType.PREVIEW && (
                <PreviewMode
                    selectedVideoObj={selectedVideoObj}
                    handlePreviewClick={handlePreviewClick}
                    closeBtnClick={closeBtnClick}
                />
            )}

            {currentPlayerState === ECurrentPlayerType.OPEN &&
                <OpenMode
                    isVideoLoaded={isVideoLoaded}
                    videoProgress={videoProgress}
                    isPlaying={isPlaying}
                    isMuted={isMuted}
                    selectedVideoObj={selectedVideoObj}
                    nextVideo={nextVideo}
                    fullScreen={fullScreen}
                    globalVideoProgress={globalVideoProgress}
                    setIsVideoLoaded={setIsVideoLoaded}
                    setVideoProgress={setVideoProgress}
                    setIsPlaying={setIsPlaying}
                    setIsMuted={setIsMuted}
                    setSelectedProduct={setSelectedProduct}
                    sliderNextVideoClick={sliderNextVideoClick}
                    sliderPrevVideoClick={sliderPrevVideoClick}
                    closeBtnClick={closeBtnClick}
                    toggleFullScreen={toggleFullScreen}
                />
            }

            {selectedProduct && (
                <AddToCartContainer
                    type="standalone"
                    productObj={selectedProduct}
                    isFullscreen={fullScreen}
                    onCloseAddToCart={() => setSelectedProduct(undefined)}
                />
            )}
        </div>
    ), bodyDOM);
}

export default VideoPlayerComponent;