import { SyntheticEvent, useContext, useEffect, useRef, useState } from 'react';
import { IChoice, IProductResponse, IQuestion, IShopifyProductResponse, IShopifyProductVariant } from '../../interfaces';
import { EQuestionResponseStatus } from '../../interfaces/elastic-events';
import { apiAddToShopifyCart } from '../../services/add-to-cart.service';
import logger from '../../utils/logger';
import FullScreenProductDetailsComponent from './full-screen-product-details-component';
import MiniProductDetailsComponent from './mini-product-details-component';
import { v4 as uuidv4 } from "uuid";
import { getURLObj, getURLQueryParamsAsObject, getValidUrl, shopifyGIDdecode } from '../../utils/common-functions';
import { AppContext } from '../../context/app-context';
// import { mockProductJsResponse } from './mini-product-details-component/mock';

import './index.scss';
const dataTestIdPrefix = "AddToCartContainerComponent";

interface IProps {
    type: 'question' | 'standalone';
    isFullscreen: boolean;
    interactionQuestion?: IQuestion;
    productObj?: IProductResponse;
    propResponseId?: string;
    handleSendQuestionResponseEvent?: (questionResponseId: string, question: IQuestion, status: EQuestionResponseStatus, choiceIds?: string[], userInput?: string) => Promise<void>;
    handleNextQuestion?: (question: IQuestion, choice?: IChoice) => Promise<void>;
    onCloseAddToCart?: () => void;
    callBackNextQuestion?: (question: IQuestion, choice?: IChoice) => Promise<void>;
    callBackSendQuestionResponse?: (questionResponseId: string, question: IQuestion, status: EQuestionResponseStatus, choiceIds?: string[], userInput?: string) => Promise<void>;
}

const AddToCartContainer = ({
    interactionQuestion: question, isFullscreen, productObj, type, propResponseId,
    onCloseAddToCart,
    handleSendQuestionResponseEvent,
    handleNextQuestion,
    callBackNextQuestion,
    callBackSendQuestionResponse
}: IProps) => {
    const { isMobile, widgetSettings } = useContext(AppContext);
    const [productData, setProductData] = useState<IShopifyProductResponse>();
    const [isProductDetailsOpen, setIsProductDetailsOpen] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState<IShopifyProductVariant>();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [questionResponseId, setQuestionResponseId] = useState<string>();
    const formRef = useRef<null | undefined | { current: HTMLFormElement }>();

    useEffect(() => {
        const url = productObj?.redirectUrl || question?.choices[0]?.productUrl;
        if (url) {
            getProductVariantsAndOptions(url);
        }
    }, [question, productObj]);

    useEffect(() => {
        if (question) {
            const uuid = propResponseId || uuidv4();
            setQuestionResponseId(undefined);
            // If we wanted to show event when fullscreen widget loads as well
            // if (callBackSendQuestionResponse) {
            //     console.log("🚀 ~ file: index.tsx ~ line 55 ~ useEffect ~ callBackSendQuestionResponse", callBackSendQuestionResponse);
            //     callBackSendQuestionResponse(uuid, question, EQuestionResponseStatus.VIEWED);
            // } else
            if (handleSendQuestionResponseEvent) {
                handleSendQuestionResponseEvent(uuid, question, EQuestionResponseStatus.VIEWED);
            }

            setQuestionResponseId(uuid);
        }
    }, [question]);

    const onClickCloseButton = () => {
        setIsProductDetailsOpen(false);
        if (onCloseAddToCart) {
            onCloseAddToCart();
        }
    }

    const handleAddToCartClick = () => {
        setIsProductDetailsOpen(true);
    }

    const handleSubmit = async (e: SyntheticEvent) => {
        // Prevent the browser from reloading the page
        e.preventDefault();
        const productObj = getAndSetSelectedProductObj();
        if (productObj) {
            setIsSubmitting(true);
            await apiAddToShopifyCart(productObj.id);

            // if type is question then only send this events
            logger("🚀 ~ file: index.tsx  handleSubmit ~ responseId", questionResponseId);
            if (questionResponseId && question) {
                logger("🚀 ~ file: index.tsx  handleSubmit ~ questionResponseId", questionResponseId, question);
                if (handleSendQuestionResponseEvent) {
                    logger("🚀 ~ file: index.tsx  handleSubmit ~ handleSendQuestionResponseEvent", handleSendQuestionResponseEvent);
                    await handleSendQuestionResponseEvent(questionResponseId, question, EQuestionResponseStatus.SUBMITTED, [question.choices[0].id]);
                } else if (callBackSendQuestionResponse) {
                    logger("🚀 ~ file: index.tsx  handleSubmit ~ callBackSendQuestionResponse", callBackSendQuestionResponse);
                    await callBackSendQuestionResponse(questionResponseId, question, EQuestionResponseStatus.SUBMITTED, [question.choices[0].id]);
                }

                // handling next question either with callback or with props
                if (handleNextQuestion) {
                    logger("🚀 ~ file: index.tsx  handleSubmit ~ handleNextQuestion", handleNextQuestion);
                    await handleNextQuestion(question, question.choices[0]);
                } else if (callBackNextQuestion) {
                    logger("🚀 ~ file: index.tsx ~ handleSubmit ~ callBackNextQuestion", callBackNextQuestion);
                    await callBackNextQuestion(question, question.choices[0]);
                }
                logger("🚀 ~ file: index.tsx  handleSubmit ~ responseId", questionResponseId);

            } else {
                const url = window.location.origin + '/cart';
                window.open(getValidUrl(url), "_self");
            }
            setIsSubmitting(false);
        }
    };


    const handleVariantChange = () => {
        getAndSetSelectedProductObj();
    }

    const getAndSetSelectedProductObj = () => {
        if (!formRef.current) { return; }
        let data: { [key: string]: string } = {};

        const formData: any = new FormData(formRef?.current as any);
        for (let entry of formData?.entries()) {
            data[entry[0]] = entry[1];
        }
        function customSearch(this: any, item: any) {
            return Object.keys(this).every((key) => item[key] === this[key]);
        }

        const results = productData?.variants?.filter(customSearch, data);
        logger("🚀 ~ file: index.tsx ~ getAndSetSelectedProductObj ~ results", results, results?.length);

        setSelectedProduct(undefined);
        // avoid setting value until all variants selected
        if (results && results?.length === 1) {
            setSelectedProduct(results[0]);
            logger("🚀 ~ file: index.tsx ~ getAndSetSelectedProductObj ~ results[0]", results[0]);
            return results[0];
        }
        return null;
    }

    const getProductVariantsAndOptions = async (productURL: string) => {
        setProductData(undefined);
        const { url, isValid } = getURLObj(productURL);
        if (!productURL || productURL.includes('Enter a URL') || (!isValid || !url)) {
            return;
        }

        try {
            const searchParams = url.search.substring(1);
            const searchObj = getURLQueryParamsAsObject(searchParams);
            const path = url.origin + url.pathname;
            const response = await fetch(`${path}.js`);
            const data = await response.json();
            setProductData(data);

            // const data = mockProductJsResponse as any;
            let pData = data.variants.length === 1 ? data.variants[0] : null;
            const vId = productObj?.variantId ? shopifyGIDdecode(productObj?.variantId)?.id : searchObj?.variant;
            if (vId) {
                logger("🚀 ~ file: index.tsx ~ getProductVariantsAndOptions ~ vId =>", vId);
                // eslint-disable-next-line eqeqeq
                const selectedProduct = data.variants.find((variant: any) => variant.id == vId);
                pData = selectedProduct || data?.variants[0];
            }
            setSelectedProduct(pData);

            if (type === 'standalone') {
                setIsProductDetailsOpen(true);
            }

        } catch (e: any) {
            setProductData(undefined);
            console.log("🚀 ~ file: index.tsx ~ getProductVariantsAndOptions ~ e", e);
            // if error setting default value
        }
    }

    const onClick = (e: any) => {
        // Since `focusOnSelect:true` we have to stop propagation
        e.stopPropagation();
    };

    return (
        <div onClick={(e) => onClick(e)} data-testid={`${dataTestIdPrefix}-ContainerDiv`}>
            {(isFullscreen && !isMobile) ? (

                <FullScreenProductDetailsComponent
                    isFullscreen={isFullscreen}
                    question={question}
                    isProductDetailsOpen={isProductDetailsOpen}
                    onClickCloseButton={onClickCloseButton}
                    handleSubmit={handleSubmit}
                    isSubmitting={isSubmitting}
                    formRef={formRef}
                    selectedProduct={selectedProduct}
                    productData={productData}
                    handleVariantChange={handleVariantChange}
                    widgetSettings={widgetSettings}
                    productObj={productObj}
                />
            ) : (
                <MiniProductDetailsComponent
                    isFullscreen={isFullscreen}
                    question={question}
                    isProductDetailsOpen={isProductDetailsOpen}
                    onClickCloseButton={onClickCloseButton}
                    handleSubmit={handleSubmit}
                    isSubmitting={isSubmitting}
                    formRef={formRef}
                    selectedProduct={selectedProduct}
                    productData={productData}
                    handleVariantChange={handleVariantChange}
                    widgetSettings={widgetSettings}
                    productObj={productObj}
                />
            )}

            {type === "question" && !isProductDetailsOpen && (
                <button
                    className={`g-product-details-form-btn ${isFullscreen ? 'g-product-details-form-btn-fullscreen-mode' : ''}`}
                    style={{ background: question?.choices[0]?.buttonColor }}
                    onClick={handleAddToCartClick}
                >
                    <div className="g-interaction-edit-preview-panel-add-to-cart-interaction-div" >
                        <div className="g-product-details-btn-text" style={{ color: question?.choices[0]?.buttonTextColor }}>
                            {question?.text ? question?.text : 'Add To Cart'}
                        </div>
                    </div>
                </button>
            )}
        </div>
    )
}

export default AddToCartContainer;