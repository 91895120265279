import { CSSProperties, memo, useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../../../context/app-context";
import { IProductResponse, IVideo } from "../../../interfaces";
import logger from "../../../utils/logger";
import { EEmbeddedLayout, EEmbeddedPlayerStyle, EOverlayOpenState, EPlayerShape } from "interfaces/widget-setting-v2";
import { DEFAULT_BORDER_PADDING_FOR_PREVIEW_EMBED_AND_CLOSE_MODE } from "constants/constants";
import AddToCartContainer from "components/add-to-cart-container";
import EmbeddedModeInFrameStateComponent from "./embedded-mode-in-frame-state-component";
import EmbeddedModePreviewStateComponent from "./embedded-mode-preview-state-component";
import { SHOP_NAME } from "config";

const AUTOPLAY_SHOPS = [
  "nudeallure.myshopify.com",
]

interface IProps {
  indexId: number;
  video: IVideo;
  selectedInFrameVideoObj: IVideo | undefined;
  setSelectedInFrameVideoObj: React.Dispatch<React.SetStateAction<IVideo | undefined>>;
}

const dataTestIdPrefix = "EbeddedModePreviewItemComponent";

const EmbeddedModePreviewItem = ({ indexId, video, selectedInFrameVideoObj, setSelectedInFrameVideoObj }: IProps) => {
  const { ganderVideos, widgetSettings, setSelectedVideoObj, setInitialFullScreenValue, currentPlayingVideoIndexForPlayOnHover } = useContext(AppContext);
  const previewVideoTag = useRef<HTMLVideoElement>(null);
  const isAllowSimultaneousPlay = widgetSettings?.previewEmbedGifStyle === EEmbeddedPlayerStyle.SIMULTANEOUS;
  const [isPlaying, setIsPlaying] = useState(true);
  const [isLoadVideoInFrame, setIsLoadVideoInFrame] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState<IProductResponse>();

  useEffect(() => {
    if (AUTOPLAY_SHOPS.includes(SHOP_NAME) && window.location.pathname === "/") {
        setIsPlaying(true);
        setIsLoadVideoInFrame(true);
        setSelectedInFrameVideoObj(video);
    }
  }, [ganderVideos]);

  useEffect(() => {
    if (currentPlayingVideoIndexForPlayOnHover === indexId) {
      startPreviewVideo();
    } else {
      stopPreviewVideo();
    }
  }, [currentPlayingVideoIndexForPlayOnHover, indexId]);

  useEffect(() => {
    setSelectedProduct(undefined);
  }, [selectedInFrameVideoObj])


  const onPreviewClick = (obj: IVideo) => {
    logger("🚀 ~ file: EmbeddedModePreviewItem ~ line 28 ~ onPreviewClick ~ obj", obj);

    if (widgetSettings?.openStateType === EOverlayOpenState.FULLSCREEN) {
      setSelectedVideoObj(obj);
      setInitialFullScreenValue(true);
    } else if (widgetSettings?.openStateType === EOverlayOpenState.OVERLAY) {
      setSelectedVideoObj(obj);
    } else if (widgetSettings?.openStateType === EOverlayOpenState.IN_FRAME) {
      setIsPlaying(true);
      setIsLoadVideoInFrame(true);
      setSelectedInFrameVideoObj(obj);
    }
  };

  const startPreviewVideo = () => {
    if (isAllowSimultaneousPlay || selectedInFrameVideoObj) {
      return;
    }
    previewVideoTag.current?.play();
  }

  const stopPreviewVideo = () => {
    if (isAllowSimultaneousPlay) {
      return;
    }
    previewVideoTag.current?.pause();
  }

  const getEmbeddedPreviewAspectRatio = () => {
    if (widgetSettings?.embedType !== EEmbeddedLayout.GRID && widgetSettings?.previewOrientation === EPlayerShape.PORTRAIT_LANDSCAPE) {
      return video?.isPortrait ? "9 / 16" : "16 / 9";
    } else if (widgetSettings?.embedType !== EEmbeddedLayout.GRID && widgetSettings?.previewOrientation === EPlayerShape.SQUARE_CIRCLE) {
      return "1 / 1";
    }
  }

  const embedPreviewItemMainWrapperStyles: CSSProperties = {
    borderRadius: `${widgetSettings?.previewCornerRadius}`,
    border: (widgetSettings?.previewIsShowBorder && widgetSettings?.previewBorderColor) ? `3px solid ${widgetSettings?.previewBorderColor}` : 'unset',
    padding: (widgetSettings?.previewIsShowBorder && widgetSettings?.previewIsBorderPadding) ? DEFAULT_BORDER_PADDING_FOR_PREVIEW_EMBED_AND_CLOSE_MODE : 0,
    height: `${widgetSettings?.previewPlayerSize}`,
    width: `${widgetSettings?.embedType === EEmbeddedLayout.GRID && "100%"}`,
    minHeight: `${widgetSettings?.embedType === EEmbeddedLayout.GRID && widgetSettings?.openStateType === EOverlayOpenState?.IN_FRAME ? "340px" : "70px"}`,
    aspectRatio: getEmbeddedPreviewAspectRatio()
  }

  const videoStyle: CSSProperties = {
    width: `${widgetSettings?.embedType === EEmbeddedLayout.GRID && '100%'}`,
    objectFit: `${widgetSettings?.embedType === EEmbeddedLayout.GRID && 'objectFit'}` as any,
    objectPosition: `${widgetSettings?.embedType === EEmbeddedLayout.GRID && 'top center'}`,
  }

  return (
    <div style={embedPreviewItemMainWrapperStyles} data-testid={`${dataTestIdPrefix}-PreviewPlayerWrapper`}>
      <div className={`gander-preview-item-wrapper ${isLoadVideoInFrame && selectedInFrameVideoObj?.index === indexId ? "gander-preview-item-wrapper-inframe" : "gander-preview-item-wrapper-preview"}`}>
        {isLoadVideoInFrame && selectedInFrameVideoObj?.index === indexId ?
          <EmbeddedModeInFrameStateComponent
            videoStyle={videoStyle}
            selectedInFrameVideoObj={selectedInFrameVideoObj}
            isPlaying={isPlaying}
            setIsPlaying={setIsPlaying}
            setSelectedProduct={setSelectedProduct}
          />
          :
          <EmbeddedModePreviewStateComponent
            videoStyle={videoStyle}
            previewVideoTag={previewVideoTag}
            onPreviewClick={onPreviewClick}
            video={video}
            indexId={indexId}
          />
        }
      </div>
      {selectedProduct && (
        <AddToCartContainer
          type="standalone"
          productObj={selectedProduct}
          isFullscreen={false}
          onCloseAddToCart={() => setSelectedProduct(undefined)}
        />
      )}
    </div>
  );
};

export default memo(EmbeddedModePreviewItem);
