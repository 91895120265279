import { useContext, useEffect, useState } from "react";
import { IChoice, IQuestion } from "../../../interfaces";
import { EQuestionResponseStatus } from "../../../interfaces/elastic-events";
import AddToCartContainer from "../../add-to-cart-container";
import { v4 as uuidv4 } from "uuid";
import { AppContext } from "../../../context/app-context";

interface IProps {
    interactionQuestion: IQuestion,
    isFullscreen: boolean,
    onClickAddToCartQuestion?: (
        question: IQuestion,
        questionResponseId: string | undefined,
        handleNextQuestion: (question: IQuestion, choice?: IChoice) => Promise<void>,
        handleSendQuestionResponseEvent: (questionResponseId: string, question: IQuestion, status: EQuestionResponseStatus, choiceIds?: string[], userInput?: string) => Promise<void>,
    ) => void | undefined;
    handleSendQuestionResponseEvent: (questionResponseId: string, question: IQuestion, status: EQuestionResponseStatus, choiceIds?: string[], userInput?: string) => Promise<void>;
    handleNextQuestion: (question: IQuestion, choice?: IChoice) => Promise<void>;
    dataTestId?: string;
}

const AddToCartQuestion = ({
    interactionQuestion: question,
    isFullscreen,
    handleSendQuestionResponseEvent,
    handleNextQuestion,
    onClickAddToCartQuestion,
    dataTestId
}: IProps) => {
    const { isMobile } = useContext(AppContext);
    const [questionResponseId, setQuestionResponseId] = useState<string | undefined>();

    useEffect(() => {
        // sending event when button appears
        if (isFullscreen && !isMobile) {
            setQuestionResponseId(undefined);
            const uuid = uuidv4();
            handleSendQuestionResponseEvent(uuid, question, EQuestionResponseStatus.VIEWED);
            setQuestionResponseId(uuid);
            return () => {
                setQuestionResponseId("");
            }
        }
    }, [question]);

    return (
        <>
            {(isFullscreen && !isMobile) ? (
                <div data-testid={`${dataTestId}-ContainerWithFullscreen`}>
                    <button
                        className={`g-product-details-form-btn ${isFullscreen ? 'g-product-details-form-btn-fullscreen-mode' : ''}`}
                        style={{ background: question?.choices[0]?.buttonColor }}
                        onClick={() => onClickAddToCartQuestion ? onClickAddToCartQuestion(question, questionResponseId, handleNextQuestion, handleSendQuestionResponseEvent) : ({})}
                        data-testid={`${dataTestId}-AddToCartButton`}
                    >
                        <div className="g-interaction-edit-preview-panel-add-to-cart-interaction-div" >
                            <div
                                className="g-product-details-btn-text"
                                style={{ color: question?.choices[0]?.buttonTextColor }}
                                data-testid={`${dataTestId}-AddToCartButtonText`}
                            >
                                {question?.text ? question?.text : 'Add To Cart'}
                            </div>
                        </div>
                    </button>
                </div>
            ) : (
                <AddToCartContainer
                    type="question"
                    interactionQuestion={question}
                    isFullscreen={isFullscreen}
                    handleSendQuestionResponseEvent={handleSendQuestionResponseEvent}
                    handleNextQuestion={handleNextQuestion}
                />
            )}
        </>
    )
}

export default AddToCartQuestion;