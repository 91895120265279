import logger from "utils/logger";
import {
  LOCAL_SESSION_ID_KEY,
  SESSION_LAST_SEEN_PRODUCT,
  SESSION_WIDGET_SHOWN_KEY,
  SHOP_NAME,
  GANDER_CLIENT_ID,
  GANDER_SESSION_ID,
  LAST_SESSION_USED_TIMESTAMP,
  LOCATION_HOST,
} from "../config";
import {
  getDevice,
  getQueryStringParams,
  resolveElasticVisitImpressionData,
} from "../utils/common-functions";
import { getItemFromLocalStorage } from "../utils/get-set-local-storage";
const { REACT_APP_GANDER_BE_API_URL } = process.env;

export const apiAddToShopifyCart = async (id: string | number) => {
    const settings = {
        method: 'POST',
        headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
        body: JSON.stringify({ items: [{ id: id, quantity: 1, }] }),
    };

    try {
        const fetchResponse = await fetch(`${window.location.origin}/cart/add.js`, settings);
        const data = await fetchResponse.json();
        return data;
    } catch (e) {
        console.log('e', e);
        return e;
    }
};

function sendAddToCartEvent() {
  const lastSeenProduct = sessionStorage.getItem(SESSION_LAST_SEEN_PRODUCT) || "";
  const sessionWidgetShown = sessionStorage.getItem(SESSION_WIDGET_SHOWN_KEY) || "";
  const isWidgetShown = sessionWidgetShown?.includes(lastSeenProduct);
  const vidMeta = JSON.parse(sessionWidgetShown || "{}");
  const { entityName } = resolveElasticVisitImpressionData();

  const queryParams = getQueryStringParams();
  if (LOCATION_HOST.includes("localhost") || window?.Shopify?.designMode || !!queryParams?.isPreviewMode) {
    logger("🚀 ~ file: add-to-cart.service.ts ~ NOT SENDING EVENTS AS ITS EITHER ON LOCALHOST OR ON DESIGN MODE");
    return;
  }

  const data = {
    "@timestamp": new Date().toISOString(),
    app_version: 2,
    event: {
      kind: "event",
      category: "product",
      type: "add_to_cart",
      shop: SHOP_NAME,
      visitor: getItemFromLocalStorage(LOCAL_SESSION_ID_KEY),
      device: getDevice(),
      is_react_widget: true,
    },
    data: {
      product_id: window?.shopifyProductId || lastSeenProduct,
      product_name: entityName,
      widget_shown: isWidgetShown,
      last_watched_video: lastSeenProduct,
      video_watched_prior_purchase: vidMeta?.videoShown,
      script_called_from_url: window.location.href,
    },
  };
  apiAddToCartEvent(data);
}

const apiAddToCartEvent = async (data?: any): Promise<any> => {
  try {
    const ganderSessionID = getItemFromLocalStorage(GANDER_SESSION_ID);
    const sessionUsedTimeStamp = getItemFromLocalStorage(LAST_SESSION_USED_TIMESTAMP);
    const clientID = getItemFromLocalStorage(GANDER_CLIENT_ID);
    if (data?.event) {
      if (ganderSessionID) {
        data.event.ganderSessionID = ganderSessionID;
      }
      if (sessionUsedTimeStamp) {
        data.event.ganderSessionUsedTimeStamp = sessionUsedTimeStamp;
      }
      if (clientID) {
        data.event.clientID = clientID;
        data.event.wasClientIDPresent = Boolean(clientID);
      }
    }
    const payload = data;
    return await fetch(`${REACT_APP_GANDER_BE_API_URL}statistics-events`, {
      method: "POST",
      headers: { "Content-Type": "application/json", "x-shop": SHOP_NAME },
      body: JSON.stringify(payload),
    });
  } catch (e: any) {
    console.log("Something went wrong: ", e?.message);
    return;
  }
};

export const registerAddToCartListener = () => {
  // adding event listener for add to cart button
  const buttonsToListenClickEvent = document.querySelectorAll(".g-add-to-cart-btn");
  // we might have multiple button on same page so using class name and looping to add click event listener
  buttonsToListenClickEvent.forEach((el) =>
    el.addEventListener("click", (event) => {
      console.log(event.target);
      console.log("gander script to track add to cart loaded");
      sendAddToCartEvent();
    })
  );
  //   WIX: keeping this wix snippet for testing and improving in future
  // document.body.addEventListener("click", function (e: any) {
  //   // console.log("🚀 ~ file: add-to-cart.service.ts ~ line 65 ~ e", e);
  //   if (["add-to-cart", "product-item-add-to-cart-button"].includes(e?.target?.parentNode?.getAttribute("data-hook"))) {
  //     sendAddToCartEvent();
  //   }
  // });
};

// exposing add-to-cart send event function to window object for direct use
(window as any).sendAddToCartEvent = sendAddToCartEvent;
