import { useState } from "react";
import InteractionContainer from "components/interaction-container";
import GanderLogoV2 from "components/gander-logo-v2";
import { EQuestionType, IVideo } from "interfaces";

import "./index.scss";

const QUESTIONS_WITH_OVERLAY = [
  EQuestionType.MULTIPLE_CHOICE,
  EQuestionType.PICTURE_CHOICE,
  EQuestionType.SHORT_ANSWER,
]

interface Props {
  selectedVideoObj: IVideo;
  fullScreen: boolean;
  isVideoLoaded: boolean;
  displayBranding: boolean;
  dataTestId?: string;
}

const Footer = ({ selectedVideoObj, fullScreen, isVideoLoaded, displayBranding, dataTestId }: Props) => {
  const [currentQuestionType, setCurrentQuestionType] = useState<EQuestionType | undefined>(undefined);

  return (
    <div
      className={`
        gander-open-mode-footer
        ${currentQuestionType && QUESTIONS_WITH_OVERLAY.includes(currentQuestionType) ? "gander-footer-with-overlay" : ""}
        ${displayBranding ? "" : "gander-no-branding"}
      `}
    >
      {isVideoLoaded && selectedVideoObj?.interaction && (
        <InteractionContainer
          dataTestId={`${dataTestId}-InteractionContainer`}
          interaction={selectedVideoObj.interaction}
          video={selectedVideoObj}
          fullscreen={fullScreen}
          setCurrentQuestionType={setCurrentQuestionType}
        />
      )}
      {displayBranding && <GanderLogoV2 dataTestId={`${dataTestId}-GanderLogoV2`} />}
    </div>
  )
}

export default Footer;