import { Mutex } from 'async-mutex';
import logger from './logger';

const mutex = new Mutex();

export const mutexLock = async (callback : any) => {
  let response: any;
  try {
    await mutex.runExclusive(async () => {
      logger("Acquiring lock...");
      response = await callback;
      logger("Releasing lock...");
    });
  } finally {
    logger("...Released lock");
  }
  return response;
}
