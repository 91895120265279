import { useContext, useState } from "react";
import { IProductResponse } from "../../interfaces";
import { AppContext } from "../../context/app-context";

import "./index.scss";

interface IProps {
    products: IProductResponse[];
    isPortrait: boolean;
    isFullscreen: boolean;
    onClickProductIcon: (product: IProductResponse) => void;
    isDisplayBranding: boolean;
}

const ProductsContainer = ({ products, isPortrait, isFullscreen, onClickProductIcon, isDisplayBranding }: IProps) => {
    const { isMobile } = useContext(AppContext);
    const [productList] = useState<IProductResponse[]>(products);

    return (
        <div
            className={`g-products-container
            ${isPortrait ? "" : "gander-is-landscape"}
            ${isFullscreen ? "g-products-container-fullscreen" : "g-products-container-overlay"} 
            ${isMobile ? "g-is-mobile" : "g-is-not-mobile"}
        `}
        >
            {productList && productList.map((product: IProductResponse, index: number) => (
                <button onClick={() => onClickProductIcon(product)} key={`${index}-product-button`}>
                    <img src={product.thumbnail} alt={product.name} />
                </button>
            ))
            }
        </div>
    );
};
export default ProductsContainer;
