import { useCallback, useEffect, useState } from "react";
import {
  EQuestionSelectionType,
  IChoice,
  IQuestion,
} from "../../../interfaces";
import { EQuestionResponseStatus } from "../../../interfaces/elastic-events";
import { v4 as uuidv4 } from "uuid";

import "./index.scss";

interface IProps {
  isFullscreen: boolean;
  interactionQuestion: IQuestion;
  handleSendQuestionResponseEvent: (
    questionResponseId: string,
    question: IQuestion,
    status: EQuestionResponseStatus,
    choiceIds?: string[],
    userInput?: string
  ) => void;
  handleNextQuestion: (question: IQuestion, choice?: IChoice) => void;
  dataTestId?: string;
}

const PictureChoiceQuestion = ({
  isFullscreen,
  interactionQuestion,
  handleSendQuestionResponseEvent,
  handleNextQuestion,
  dataTestId
}: IProps) => {
  const [answerChoices, setAnswerChoices] = useState<IChoice[]>([]);
  const [questionResponseId, setQuestionResponseId] = useState<string>();

  useEffect(() => {
    setAnswerChoices([]);
    setQuestionResponseId(undefined);

    const uuid = uuidv4();
    handleSendQuestionResponseEvent(
      uuid,
      interactionQuestion,
      EQuestionResponseStatus.VIEWED
    );
    setQuestionResponseId(uuid);

    return () => {
      setAnswerChoices([]);
      setQuestionResponseId(undefined);
    };
  }, [interactionQuestion]);

  const handleSubmitAnswer = () => {
    if (questionResponseId) {
      handleSendQuestionResponseEvent(
        questionResponseId,
        interactionQuestion,
        EQuestionResponseStatus.SUBMITTED,
        answerChoices.map(({ id }) => id)
      );

      if (
        interactionQuestion.selectionType ===
        EQuestionSelectionType.SINGLE_SELECT
      ) {
        handleNextQuestion(interactionQuestion, answerChoices[0]);
      } else {
        handleNextQuestion(interactionQuestion);
      }
    }
  };

  useEffect(() => {
    if (
      interactionQuestion?.selectionType ===
      EQuestionSelectionType.SINGLE_SELECT &&
      answerChoices?.length > 0
    ) {
      handleSubmitAnswer();
    }
  }, [answerChoices]);

  const handleOptionChange = (selectedChoice: IChoice) => {
    if (
      interactionQuestion?.selectionType ===
      EQuestionSelectionType.SINGLE_SELECT
    ) {
      setAnswerChoices([selectedChoice]);
    } else if (
      interactionQuestion?.selectionType ===
      EQuestionSelectionType.MULTIPLE_SELECT
    ) {
      // removing if already selected
      if (isSelected(selectedChoice)) {
        const updatedAnswerChoices = answerChoices?.filter(
          (choice: IChoice) => choice?.id !== selectedChoice?.id
        );
        setAnswerChoices(updatedAnswerChoices);
        return;
      }
      setAnswerChoices([...answerChoices, selectedChoice]);
    }
  };

  const isSelected = useCallback(
    (currentChoice: IChoice) => {
      return answerChoices?.some(
        (choice: IChoice) => choice?.id === currentChoice?.id
      );
    },
    [answerChoices]
  );

  return (
    <div
      className={`g-picture-choice-interaction-component ${isFullscreen
        ? "g-picture-choice-interaction-component-fullscreen"
        : "g-picture-choice-interaction-component-overlay"
        }`}
    >
      <div className="g-picture-choice-interaction-question">
        <div className="g-picture-choice-interaction-question-text" data-testid={`${dataTestId}-QuestionText`}>
          {interactionQuestion?.text}
        </div>
      </div>
      <div className="g-picture-choice-interaction-input-container">
        <div className="g-picture-choice-interaction-choices-container">
          {interactionQuestion?.choices.map((choice) => (
            <button
              key={choice?.id}
              className={`g-picture-choice-interaction-choice ${isSelected(choice) &&
                "g-picture-choice-interaction-choice-active"
                }`}
              onClick={() => handleOptionChange(choice)}
              data-testid={`${dataTestId}-Choice`}
            >
              <img
                className="g-picture-choice-interaction-choice-img"
                src={choice?.pictureUrl}
                alt={`choice-${choice.id}`}
              />
            </button>
          ))}
        </div>
        {interactionQuestion?.selectionType ===
          EQuestionSelectionType.MULTIPLE_SELECT && (
            <div className="g-picture-choice-interaction-submit-button-container">
              <button
                className="g-picture-choice-interaction-submit-button"
                onClick={handleSubmitAnswer}
                disabled={answerChoices?.length === 0}
                data-testid={`${dataTestId}-NextQuestionButton`}
              >
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M15.7071 8.70711C16.0976 8.31658 16.0976 7.68342 15.7071 7.29289L9.34315 0.928932C8.95262 0.538408 8.31946 0.538408 7.92893 0.928932C7.53841 1.31946 7.53841 1.95262 7.92893 2.34315L13.5858 8L7.92893 13.6569C7.53841 14.0474 7.53841 14.6805 7.92893 15.0711C8.31946 15.4616 8.95262 15.4616 9.34315 15.0711L15.7071 8.70711ZM0 9H15V7H0V9Z"
                    fill="white"
                  />
                </svg>
              </button>
            </div>
          )}
      </div>
    </div>
  );
};

export default PictureChoiceQuestion;
