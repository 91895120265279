import { MutableRefObject, SyntheticEvent } from "react";
import { IProductResponse, IQuestion, IShopifyProductResponse, IShopifyProductVariant, IWidgetSettings } from "../../../interfaces";
import CloseButton from "../../video-player/video-controls/buttons/CloseButton";
import AddToCartFormComponent from "../add-to-cart-form-component";

import "./index.scss";
const dataTestIdPrefix = "MiniProductDetailsComponent";

interface IProps {
    formRef: MutableRefObject<{ current: HTMLFormElement; } | null | undefined>;
    selectedProduct: IShopifyProductVariant | undefined;
    productData: IShopifyProductResponse | undefined;
    question: IQuestion | undefined;
    isFullscreen: boolean;
    isProductDetailsOpen: boolean;
    isSubmitting: boolean;
    widgetSettings: IWidgetSettings;
    productObj?: IProductResponse;
    handleSubmit: (e: SyntheticEvent) => void;
    onClickCloseButton: () => void;
    handleVariantChange: () => void;
}

// Stateless component for handling html/css batter
const MiniProductDetailsComponent = ({
    question,
    isProductDetailsOpen,
    isFullscreen,
    isSubmitting,
    widgetSettings,
    formRef,
    selectedProduct,
    productData,
    productObj,
    handleVariantChange,
    onClickCloseButton,
    handleSubmit
}: IProps) => {
    const isShopCurrencyUSD = window?.Shopify?.currency?.active === 'USD';
    const addToCartMaskStyles = {
        display: isProductDetailsOpen ? 'block' : 'none',
        height: document.querySelector('.gander-container')?.clientHeight || document.querySelector('.gander-full-screen-container')?.clientHeight,
        borderRadius: (document.querySelector('.gander-container') as HTMLElement)?.style?.borderRadius,
    };

    const addToCartProductDetailsContainer = {
        display: isProductDetailsOpen ? 'block' : 'none',
        borderBottomLeftRadius: (document.querySelector('.gander-container') as HTMLElement)?.style?.borderRadius,
        borderBottomRightRadius: (document.querySelector('.gander-container') as HTMLElement)?.style?.borderRadius,
    }

    return (
        <div className={`g-mini-product-details-container ${isFullscreen ? "g-product-details-fullscreen-mode" : ""}`}>
            <div className="g-add-to-cart-mask" style={addToCartMaskStyles} ></div>
            <div className={`g-interaction-product-details ${isProductDetailsOpen ? ' active' : ''}`} style={addToCartProductDetailsContainer}>
                <div className="g-interaction-product-details-close">
                    <CloseButton handleClick={onClickCloseButton} />
                </div>
                    <div className="g-interaction-product-details-price-img">
                        <img src={selectedProduct?.featured_image?.src || productData?.featured_image} alt={productData?.title} data-testid={`${dataTestIdPrefix}-Img`}/>
                        <div className="g-interaction-product-price-div">
                            <span className="g-interaction-product-details-title-span" title={productData?.title + ' (' + selectedProduct?.title + ' )'} data-testid={`${dataTestIdPrefix}-TitleTxt`}>
                                {productData?.title}
                            </span>
                            {productData?.price && (
                                <span className="g-interaction-product-details-price-span" data-testid={`${dataTestIdPrefix}-PriceTxt`}>
                                    {isShopCurrencyUSD ? '$' : '' }
                                    {(selectedProduct?.price || productData?.price) / 100}
                                    {(selectedProduct?.compare_at_price || productData?.compare_at_price) && (
                                        <span className="g-interaction-product-details-compare-price-span" data-testid={`${dataTestIdPrefix}-ComparePriceTxt`}>
                                            {isShopCurrencyUSD ? '$' : '' }
                                            {(selectedProduct?.compare_at_price || productData?.compare_at_price) / 100}
                                        </span>
                                    )}
                                </span>
                            )}
                        </div>
                    </div>

                    {/* Common Form Component that handles submit part as well */}
                    <AddToCartFormComponent
                        isFullscreen={isFullscreen}
                        handleSubmit={handleSubmit}
                        formRef={formRef}
                        productData={productData}
                        selectedProduct={selectedProduct}
                        question={question}
                        handleVariantChange={handleVariantChange}
                        isSubmitting={isSubmitting}
                        widgetSettings={widgetSettings}
                        productObj={productObj}
                    />
                </div>
            </div>
    );
};

export default MiniProductDetailsComponent;
