import { useContext } from "react";
import MuteButtonWithOverlay from "components/video-player/video-controls/buttons/MuteButtonWithOverlay";
import UnmuteButtonWithOverlay from "components/video-player/video-controls/buttons/UnmuteButtonWithOverlay";
import CloseButtonWithOverlay from "components/video-player/video-controls/buttons/CloseButtonWithOverlay";
import { AppContext } from "context/app-context";

import "./index.scss";

interface Props {
  isPortrait: boolean;
  isVideoLoaded: boolean;
  toggleFullScreen: () => void;
  handleMinimize: () => void;
}

const TopRightControls = ({ isPortrait, isVideoLoaded, toggleFullScreen, handleMinimize }: Props) => {
  const { isMobile, isMuted, setIsMuted } = useContext(AppContext);

  const closeBtnClick = () => {
    handleMinimize();
    toggleFullScreen();
  };

  return (
    <div className={`gander-fullscreen-mode-top-right-controls ${isPortrait ? "" : "gander-is-landscape"}`}>
      {isVideoLoaded && (
        <>
          {isMobile && <CloseButtonWithOverlay handleClick={closeBtnClick} />}
          {isMuted ? <UnmuteButtonWithOverlay handleClick={() => setIsMuted(false)} /> : <MuteButtonWithOverlay handleClick={() => setIsMuted(true)} />}
        </>
      )}
    </div>
  )
}

export default TopRightControls;