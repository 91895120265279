import PlayButtonWithOverlay from "components/video-player/video-controls/buttons/PlayButtonWithOverlay";
import PauseButtonWithOverlay from "components/video-player/video-controls/buttons/PauseButtonWithOverlay";

import "./index.scss";

interface Props {
  isPlaying: boolean;
  playBtnClick: () => void;
  pauseBtnClick: () => void;
}

const CenterControls = ({ isPlaying, playBtnClick, pauseBtnClick }: Props) => {
  return (
    <div className="gander-open-mode-center-controls" onClick={isPlaying ? pauseBtnClick : playBtnClick}>
      {!isPlaying && <PlayButtonWithOverlay handleClick={playBtnClick} />}
      {isPlaying && <PauseButtonWithOverlay handleClick={pauseBtnClick} />}
    </div>
  )
}

export default CenterControls;