import { VideoStartType } from "../constants/video_start_type";
import { EQuestionType, IABTestResponse, IVideo } from "../interfaces";
import { EQuestionResponseStatus, IInteractionResponsePayload, IQuestionResponsePayload } from "../interfaces/elastic-events";
import { sendAnalyticsEvent } from "../services/common.service";
import { resolveElasticEventData, getProductIdFromHtmlElement, resolveElasticVisitImpressionData } from "./common-functions";

export const sendVideoStopEvent = (
  videoId: string,
  videoPlayedSeconds: number,
  widgetShown: boolean,
  isFullScreen: boolean,
  engagement?: boolean,
) => {
  try {
    const { entityName, browserPath: path } = resolveElasticVisitImpressionData();
    const { collection_name } = resolveElasticEventData();
    const analyticsPayload: any = {
      event: {
        type: "stop",
        category: "video",
      },
      data: {
        product_id: getProductIdFromHtmlElement(),
        duration: Math.floor(videoPlayedSeconds),
        product_name: entityName,
        video_id: videoId,
        widget_shown: widgetShown,
        is_carousel: isFullScreen,
        path,
      },
    };
    if (engagement !== undefined) {
      analyticsPayload.event.engagement = engagement;
    }
    if (collection_name !== undefined) {
      analyticsPayload.data.collection_name = collection_name;
    }
    sendAnalyticsEvent(analyticsPayload);
  } catch (e: any) {
    console.log("Failed to impression: ", e.message);
  }
};

export const sendVideosPauseEvent = (
  videoId: string,
  videoPlayedSeconds: number,
  isFullScreen: boolean,
  engagement?: boolean,
) => {
  try {
    const { entityName, browserPath: path } = resolveElasticVisitImpressionData();
    const { collection_name } = resolveElasticEventData();
    const analyticsPayload: any = {
      event: {
        type: "pause",
        category: "video",
      },
      data: {
        product_id: getProductIdFromHtmlElement(),
        duration: Math.floor(videoPlayedSeconds),
        product_name: entityName,
        video_id: videoId,
        widget_shown: true,
        is_carousel: isFullScreen,
        path,
      },
    };
    if (engagement !== undefined) {
      analyticsPayload.event.engagement = engagement;
    }
    if (collection_name !== undefined) {
      analyticsPayload.data.collection_name = collection_name;
    }
    sendAnalyticsEvent(analyticsPayload);
  } catch (e: any) {
    console.log("Failed to impression: ", e.message);
  }
};

export const sendVideoStartEvent = (
  video: IVideo,
  abTestData: IABTestResponse,
  type: VideoStartType,
  duration: number,
  isFullScreen: boolean,
) => {
  const { browserPath, entityName } = resolveElasticVisitImpressionData();
  const { collection_name } = resolveElasticEventData();
  try {
    const analyticsPayload: any = {
      event: {
        category: "video",
        type: type,
        engagement: true,
      },
      data: {
        duration,
        product_id: getProductIdFromHtmlElement(),
        product_name: entityName,
        path: browserPath,
        video_id: video?.id,
        widget_shown: abTestData?.widgetShown,
        is_carousel: isFullScreen,
      },
    };
    if (collection_name !== undefined) {
      analyticsPayload.data.collection_name = collection_name;
    }
    sendAnalyticsEvent(analyticsPayload);
  } catch (e: any) {
    console.log("Failed to impression: ", e.message);
  }
};

export const sendVisitCategoryImpressionEvent = (widget_shown: boolean) => {
  try {
    const { browserPath, browserCategory, entityName } = resolveElasticVisitImpressionData();
    const analyticsPayload: any = {
      event: {
        category: "visit",
        type: "impression",
      },
      data: {
        category: browserCategory,
        path: browserPath,
        widget_shown: widget_shown,
        entity_name: entityName,
      },
    };
    sendAnalyticsEvent(analyticsPayload);
  } catch (e: any) {
    console.log("Failed to impression: ", e.message);
  }
};

export const sendProductCategoryImpressionEvent = (widget_shown: boolean) => {
  try {
    const { browserCategory, entityName } = resolveElasticVisitImpressionData();
    const analyticsPayload: any = {
      event: {
        category: browserCategory,
        type: "impression",
      },
      data: {
        widget_shown: widget_shown,
        product_id: getProductIdFromHtmlElement(),
        product_name: entityName,
      },
    };
    sendAnalyticsEvent(analyticsPayload);
  } catch (e: any) {
    console.log("Failed to impression: ", e.message);
  }
};

export const sendImpressionEventWithAbTestData = (
  klaraProduct: string,
  abTestData: IABTestResponse
) => {
  try {
    const { browserCategory, browserPath: path, entityName } = resolveElasticVisitImpressionData();
    const { collection_name } = resolveElasticEventData();
    let product_name = undefined;
    let product_id = undefined;

    if (!collection_name) {
      product_name = entityName;
      product_id = entityName && getProductIdFromHtmlElement();
    }

    const analyticsPayload = {
      event: {
        category: browserCategory,
        type: "impression",
        klara_product: klaraProduct,
      },
      data: {
        product_id,
        product_name,
        collection_name,
        path,
        widget_shown: abTestData?.widgetShown,
        is_on_ab_test: abTestData?.isABTestOn,
        is_hold_out_group: abTestData?.isOnHoldoutGroup,
      },
    };
    sendAnalyticsEvent(analyticsPayload, true); // init event (send when not showing widget)
  } catch (e: any) {
    console.log("Failed to impression: ", e.message);
  }
};

export const sendHoverEvent = () => {
  try {
    const { entityName, browserPath: path } = resolveElasticVisitImpressionData();
    const analyticsPayload = {
      event: {
        category: "video",
        type: "hover",
        engagement: true,
      },
      data: {
        product_id: getProductIdFromHtmlElement(),
        product_name: entityName,
        widget_shown: true,
        path,
      }
    };
    sendAnalyticsEvent(analyticsPayload);
  } catch (e: any) {
    console.log("Failed to send hover event: ", e.message);
  }
}

export const sendVideoNavigateEvent = (category: string, videoId: string) => {
  const { entityName, browserPath: path } = resolveElasticVisitImpressionData();
  try {
    const analyticsPayload = {
      event: {
        category,
        type: "click",
        engagement: true,
      },
      data: {
        product_id: getProductIdFromHtmlElement(),
        product_name: entityName,
        video_id: videoId,
        widget_shown: true,
        path,
      }
    }
    sendAnalyticsEvent(analyticsPayload);
  } catch (e: any) {
    console.log("Failed to send arrow click event: ", e.message);
  }
}

export const sendFullScreenClickEvent = (videoId: string) => {
  try {
    const { entityName } = resolveElasticVisitImpressionData();
    const analyticsPayload = {
      event: {
        category: "new_video_shopping_mode",
        type: "click",
        engagement: true,
      },
      data: {
        product_id: getProductIdFromHtmlElement(),
        product_name: entityName,
        video_id: videoId,
        widget_shown: true,
      }
    }
    sendAnalyticsEvent(analyticsPayload);
  } catch (e: any) {
    console.log("Failed to send click event: ", e.message);
  }
}

export const sendInteractionResponseEvent = async ({
  interactionResponseId,
  interactionId,
  videoId,
  status,
}: IInteractionResponsePayload) => {
  try {
    const analyticsPayload = {
      event: {
        category: "interactions",
        type: "interaction-response",
      },
      data: {
        interaction_response_id: interactionResponseId,
        interaction_id: interactionId,
        video_id: videoId,
        status,
        widget_shown: true,
      }
    }
    await sendAnalyticsEvent(analyticsPayload, true);
  } catch (e: any) {
    console.log("Failed to send interaction response event: ", e.message);
  }
}

export const sendQuestionResponseEvent = async ({
  questionResponseId,
  interactionResponseId,
  questionId,
  interactionId,
  status,
  type,
  choiceIds,
  userInput,
}: IQuestionResponsePayload) => {
  try {
    const analyticsPayload: any = {
      event: {
        category: "interactions",
        type: "question-response",
      },
      data: {
        question_response_id: questionResponseId,
        interaction_response_id: interactionResponseId,
        interaction_id: interactionId,
        question_id: questionId,
        status,
        widget_shown: true,
      }
    }

    if (status === EQuestionResponseStatus.SUBMITTED && choiceIds) {
      analyticsPayload.data.choice_ids = choiceIds;
    }
    if (status === EQuestionResponseStatus.SUBMITTED && type === EQuestionType.SHORT_ANSWER) {
      analyticsPayload.data.user_input = userInput;
    }

    await sendAnalyticsEvent(analyticsPayload, true);
  } catch (e: any) {
    console.log("Failed to send question response event: ", e.message);
  }
}