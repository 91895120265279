type Props = {
    handleClick: () => void;
    dataTestId?: string
};

const PlayButton = ({ handleClick, dataTestId }: Props) => {
    return (
        <div className={`g-playBtn`} onClick={handleClick} data-testid={dataTestId}>
            <svg width="48" height="48" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.16675 1.5L12.8334 9L1.16675 16.5V1.5Z" fill="white" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        </div>
    );
};

export default PlayButton;