import { MutableRefObject, SyntheticEvent } from "react";
import { IProductResponse, IQuestion, IShopifyProductResponse, IShopifyProductVariant, IShopifyProductVariantOptions, IWidgetSettings } from "../../../interfaces";

interface IProps {
    formRef: MutableRefObject<{ current: HTMLFormElement; } | null | undefined | any>;
    selectedProduct: IShopifyProductVariant | undefined;
    productData: IShopifyProductResponse | undefined;
    question: IQuestion | undefined;
    isSubmitting: boolean;
    isFullscreen: boolean;
    widgetSettings: IWidgetSettings;
    productObj?: IProductResponse;
    handleVariantChange: () => void;
    handleSubmit: (e: SyntheticEvent) => void;
}
const dataTestIdPrefix = "AddToCartFormComponent";

const AddToCartFormComponent = ({
    formRef,
    productData,
    selectedProduct,
    question,
    isSubmitting,
    isFullscreen,
    widgetSettings,
    productObj,
    handleSubmit,
    handleVariantChange
}: IProps) => {

    const isSetDefaultValue = productObj?.variantId || question?.choices[0]?.productUrl?.includes('variant=');

    return (
        <form onSubmit={handleSubmit} ref={formRef}>
            {productData && productData?.variants?.length > 1 && (
                <div className="g-product-details-variant-dropdowns" data-testid={`${dataTestIdPrefix}-DropdownsDiv`}>
                    {productData?.options?.map((option: IShopifyProductVariantOptions, index: number) => (
                        <div key={option.position}>
                            <select
                                name={`option${index + 1}`}
                                defaultValue={isSetDefaultValue ? (selectedProduct as any)?.[`option${index + 1}`] : ""}
                                onChange={handleVariantChange}
                            >
                                <option disabled key={'0'} value={""}>Select {option.name}</option>
                                {option?.values?.map((variant: string) => (
                                    <option key={`option-${variant}`} value={variant}>
                                        {variant}
                                    </option>
                                ))}
                            </select>
                        </div>
                    ))}
                </div>
            )}
            <button
                className={`g-product-details-form-btn ${isFullscreen ? 'g-product-details-form-btn-fullscreen-mode' : ''}`}
                type="submit"
                disabled={!selectedProduct || !selectedProduct.available || isSubmitting}
                style={{ background: question?.choices[0].buttonColor || widgetSettings?.buttonColor, opacity: (!selectedProduct || !selectedProduct.available || isSubmitting) ? 0.7 : 1 }}
                data-testid={`${dataTestIdPrefix}-SubmitBtn`}
            >
                <div className="g-interaction-edit-preview-panel-add-to-cart-interaction-div">
                    <div className="g-product-details-btn-text" style={{ color: question?.choices[0]?.buttonTextColor || widgetSettings?.textColor }} data-testid={`${dataTestIdPrefix}-SubmitBtnDiv`}>
                        {(selectedProduct && !selectedProduct?.available) ? 'Sold out' : (question?.text ? question?.text : 'Add To Cart')}
                    </div>
                </div>
            </button>
        </form>
    );
}

export default AddToCartFormComponent;