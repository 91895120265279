import { useFlags } from 'launchdarkly-react-client-sdk';

const useLdFlags = (flagNames: string[]) => {
  const flagValues = useFlags();

  const returnObjRes: any = {};
  flagNames.forEach((flagName) => {
    let flagLocalStorageValue: unknown = localStorage.getItem(flagName);
    flagLocalStorageValue = flagLocalStorageValue === "true" ? true : flagLocalStorageValue;
    flagLocalStorageValue = flagLocalStorageValue === "false" ? false : flagLocalStorageValue;

    if (window?.Cypress && flagLocalStorageValue !== undefined) {
      returnObjRes[flagName] = flagLocalStorageValue;
    } else {
      returnObjRes[flagName] = flagValues?.[flagName]
    }
  });

  return returnObjRes;
}

export default useLdFlags